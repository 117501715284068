import React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "../../buttons/MyButon";

export default function MyProjects({ project, path, name }) {
  return (
    <div className="my-projects-container">
      <div className="img">
        <img src={project} alt="name" />
      </div>

      <div className="heading">
        <Link to={path}>
          <h3>{name}</h3>
          <div className="my-btn">
            <IconButton btn={"Check Out"} />
          </div>
        </Link>
      </div>
    </div>
  );
}

export function NewProjects({ project, name, type, setActive }) {
  return (
    <div className="projects-wrapper">
      <div
        className="my-items"
        onClick={() => {
          setActive(true);
        }}
      >
        <img src={project} alt={name} />
        <div className="content-box">
          <h3>{name}</h3>
          <p>{type}</p>
        </div>
      </div>
    </div>
  );
}
