import React from "react";
import { ReactComponent as ErrorImg } from "../../assets/error-404.svg";

function PageNotFound() {
  return (
    <div className="error-404">
      <div className="inner-er">
        {/* <img src={ErrorImg} alt="page not found 404" /> */}
        <ErrorImg />
      </div>
    </div>
  );
}

export default PageNotFound;
