import React from "react";
import { Link } from "react-router-dom";
import ArrowRight from "../assets/learn-btn.png";

import { AiOutlineCaretRight } from "react-icons/ai";

export function SimpleButon({ btn, path, colorz }) {
  return (
    <Link
      to={path}
      className="simple-btn"
      style={{ backgroundColor: `{$colorz}` }}
    >
      {btn}
    </Link>
  );
}
export function IconButton({ btn }) {
  return (
    <button className="icon-btn">
      <AiOutlineCaretRight /> <span>{btn}</span>
    </button>
  );
}

export function LearnMore({ path }) {
  return (
    <div className="learn-more">
      <Link to={path}>
        <h3>Learn More</h3>
        <img src={ArrowRight} alt="hit" />
      </Link>
    </div>
  );
}

export function SimpleButonId({ btn, path, colorz }) {
  return (
    <a
      href={path}
      className="simple-btn"
      style={{ backgroundColor: `{$colorz}` }}
    >
      {btn}
    </a>
  );
}
