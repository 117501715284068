import React from "react";
import ProcessFooter from "../ProcessFooter";

import { useEffect } from "react";

function QualityManagementPage() {
  const objectivesList = [
    "The adoption and application standards and best practices for software development.",
    "Complete adherence to established procedures and standards throughout the product lifecycle.",
    "Validation and verification of product quality regularly to guarantee.",
    "Product delivery by client specifications.",
    "Communication and information sharing across all project team members are seamless.",
  ];

  const keyProcess = [
    "QA that is conducted throughout the entire process of Testing.",
    "Review of codes and documents.",
    "Problem tracking.",
    "Manage Configurations.",
    "Monitoring the process.",
    "Controlling risk.",
  ];

  const taskList = [
    "Starting the test plan's development and planning the assignment of the QA team members to particular tasks are the responsibilities of the responsible QA person.",
    "Initial Testing of the first development deliverables is known as the first review. The project's test strategy and test data refinement (if necessary).",
    "Iteration audits are continuing evaluations of intermediate iteration builds.",
    "Final Verification & Validation: The rigorous Testing of the finished product to guarantee its ultimate quality and suitability for deployment.",
  ];

  const testsList = [
    "Testing for regression and functionality.",
    "Usability testing for the GUI.",
    "accessibility evaluations",
    "Compatibility test.",
    "Performance evaluations",
    "Testing of installation and configuration.",
    "Integrity testing of systems.",
    "Security evaluation.",
    "Testing for localization and internationalization.",
    "Testing for User Acceptance (UAT).",
  ];

  useEffect(() => {
    document.title = "Qualit Management - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "Our goal is to help you bring your product to market as quickly and efficiently as possible while ensuring that it meets the highest quality and user experience standards.";
    document.getElementsByTagName("META")[6].content =
      "Qualit Management - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "Our goal is to help you bring your product to market as quickly and efficiently as possible while ensuring that it meets the highest quality and user experience standards.";
    document.getElementsByTagName("META")[12].content =
      "Qualit Management - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "Our goal is to help you bring your product to market as quickly and efficiently as possible while ensuring that it meets the highest quality and user experience standards.";
  }, []);

  return (
    <div className="quality-management-container">
      {/* heading  */}
      <div className="heading-container">
        <h1>Management of Quality</h1>
        <p>
          Individuals have diverse definitions of quality. Depending on whom you
          ask, it may be in form or function. Quality, though, is in both for
          us.
        </p>
        <p>
          For the Quality Management of our projects, HexamileSoft has
          established a ZERO TOLERANCE policy. Our Quality Management System
          (QMS) is based on a complicated set of tech-managerial procedures that
          guarantee quality is supplied throughout the project.
        </p>
      </div>

      {/* Tasks and Objectives */}
      <div className="task-objective-container">
        <h2>Tasks and objectives for HexamileSoft QMS:</h2>
        <ul>
          {objectivesList.map((i) => {
            return <li key={i}>{i}</li>;
          })}
        </ul>
      </div>

      {/* key process  */}
      <div className="key-process-container">
        <div className="upper-wrapper">
          <h2>Unbiased QA Department:</h2>
          <p>
            The QA department at HexamileSoft is a fully developed and mature
            independent section within our organizational hierarchy.
          </p>
          <p>
            Our QA staff does not report to the project manager, so the
            development team does not influence their findings. Our clients
            require autonomy from our QA team.
          </p>
          <p>
            Our QA team comprises knowledgeable QA engineers and seasoned
            testers who work on the projects on a full-time or as-needed basis.
          </p>
        </div>

        <div className="bottom-wrapper">
          <h3>Main Procedures:</h3>
          <ul>
            {keyProcess.map((i) => {
              return <li key={i}>{i}</li>;
            })}
          </ul>
        </div>
      </div>

      {/* Tasks and Objective  */}
      <div className="key-process-container">
        <div className="upper-wrapper">
          <h2>HexamileSoft QMS Tasks and Objectives:</h2>
          <p>
            By fully implementing our procedures throughout the Software
            Development Lifecycle, we carry out our QA on an as-needed basis.
          </p>
          <p>
            From the very beginning of the project, our Lead QA was assigned to
            the project and was involved in business analysis and project
            requirements specification.
          </p>
          <p>
            Our projects are delivered in a tightly regulated manner and below
            budget, thanks to the seamless connection between development and
            QA.
          </p>
        </div>

        <div className="bottom-wrapper">
          <h3>The QA lifecycle typically consists of the following stages:</h3>
          <ul>
            {taskList.map((i) => {
              return <li key={i}>{i}</li>;
            })}
          </ul>
        </div>
      </div>

      {/* test types */}
      <div className="tests-container">
        <h3>
          The QA process may include a variety of test types to give better QA
          depth:
        </h3>
        <ul>
          {testsList.map((i) => {
            return <li key={i}>{i}</li>;
          })}
        </ul>

        <p>
          We continually strive for better results, constantly enhancing our QA
          processes and procedures and putting the satisfaction of our customers
          first.
        </p>
      </div>

      <div className="bottom-container">
        <ProcessFooter />
      </div>
    </div>
  );
}

export default QualityManagementPage;
