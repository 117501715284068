import React from "react";

import "./inner.scss";

function InnerHeading({ titleH, paragH }) {
  return (
    <div className="inner-heading-block">
      <h2>{titleH}</h2>
      <p>{paragH}</p>
    </div>
  );
}

export default InnerHeading;
