import React from "react";

import PY1 from "../../../../assets/work/mobile apps/gift suggestions/gift suggestion.jpg";

import { RxCross1 } from "react-icons/rx";
import { useContext } from "react";
import { WorkMenuData } from "../../OurWork";

function GiftSuggestionAppA() {
  const { setActive8 } = useContext(WorkMenuData);

  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActive8(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActive8(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>
          <div className="top-pop">
            <h2>Gift Suggestion App</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Mobile App</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>George</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                The Gift Suggestion App is a user-friendly and innovative mobile
                application designed to help users find the perfect gift for any
                occasion. With a vast database of gift ideas and advanced
                recommendation algorithms, this App takes the stress out of gift
                shopping and ensures you always have inspiration.
              </strong>
            </p>
            <p>
              The Gift Suggestion App is an extensive collection of gift
              options. The App curates diverse gift ideas, catering to different
              interests, age groups, and preferences. Whether you're searching
              for a birthday present for a tech-savvy friend, an anniversary
              gift for your partner, or a holiday surprise for a family member,
              the App has suggestions for every occasion. The App also allows
              users to create personalized profiles for themselves and their
              loved ones, enabling them to save gift ideas, track previous
              purchases, and receive reminders for upcoming occasions.
            </p>
            <p>
              The gift Suggestion App is integrated with online shopping
              platforms. Once users have found their desired gift, they can
              connect directly to various e-commerce websites or online
              marketplaces through the App. Gift Suggestion App promotes a
              community-driven environment where users can share their gift
              ideas, experiences, and reviews. The gift Suggestion App is
              valuable for anyone seeking inspiration and guidance in gift
              shopping. With its vast collection of gift ideas, personalized
              recommendations, seamless online shopping integration, and
              supportive community, this App simplifies finding the perfect
              gift.
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GiftSuggestionAppA;
