import React from "react";

import "./engagementModel.scss";

import TImg from "../../../assets/service/time.png";
import FT from "../../../assets/service/Time icons/Full Time.png";
import PT from "../../../assets/service/Time icons/Part Time.png";
import HT from "../../../assets/service/Time icons/Hourly.png";

function EngagementModel() {
  return (
    <div className="engagement-model-wrapper">
      <h3>Engagement Model</h3>
      <div className="my-time">
        <div className="time-wrapper">
          <div className="img-time">
            <img src={FT} alt="full time" />
          </div>
          <p>Full-Time</p>
        </div>
        <div className="time-wrapper">
          <div className="img-time">
            <img src={PT} alt="part time" />
          </div>
          <p>Part-Time</p>
        </div>
        <div className="time-wrapper">
          <div className="img-time">
            <img src={HT} alt="hourly" />
          </div>
          <p>Hourly</p>
        </div>
      </div>
    </div>
  );
}

export default EngagementModel;
