import React from "react";
import { useState } from "react";

import "../allClickProjects.scss";

import { GiAbstract005 } from "react-icons/gi";

import { BiCaretRight } from "react-icons/bi";
import { SimpleButon } from "../../../../buttons/MyButon";

function ITCounsultancyClick() {
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);
  const [active7, setActive7] = useState(false);
  const [active8, setActive8] = useState(false);

  const activeHandler = (
    a1 = true,
    a2 = false,
    a3 = false,
    a4 = false,
    a5 = false,
    a6 = false,
    a7 = false,
    a8 = false
  ) => {
    setActive1(a1);
    setActive2(a2);
    setActive3(a3);
    setActive4(a4);
    setActive5(a5);
    setActive6(a6);
    setActive7(a7);
    setActive8(a8);
  };

  return (
    <div className="inner-project-wrapper">
      <div className="heading">
        <h2>What do we do As Your IT Consultant?</h2>
        <p>
          Our role as your IT consultant is to guarantee that your company's IT
          system runs smoothly, securely, and efficiently, allowing you to focus
          on essential business activities while harnessing technology to drive
          development and success.
        </p>
        <hr />
      </div>

      <div className="select-projects-container">
        <div className="left-side">
          {/* Maintain Company's IT System  1 */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler();
            }}
            style={{ backgroundColor: active1 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Maintain Company's IT System</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Eliminate Security Threats  2 */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                false
              );
            }}
            style={{ backgroundColor: active2 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Eliminate Security Threats </span>
            </div>
            <BiCaretRight />
          </div>

          {/* Advice on Technology Best Practices 3 */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false
              );
            }}
            style={{ backgroundColor: active3 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Advice on Technology Best Practices</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Troubleshoot Problems  4 */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false
              );
            }}
            style={{ backgroundColor: active4 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Troubleshoot Problems </span>
            </div>
            <BiCaretRight />
          </div>

          {/* Collaborate With In-house Team  5 */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false
              );
            }}
            style={{ backgroundColor: active5 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Collaborate With In-house Team</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Implement New Tech Solutions 6 */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false
              );
            }}
            style={{ backgroundColor: active6 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Implement New Tech Solutions</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Produce Reports 7 */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false
              );
            }}
            style={{ backgroundColor: active7 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Produce Reports</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Excellent Communication 8 */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true
              );
            }}
            style={{ backgroundColor: active8 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Excellent Communication</span>
            </div>
            <BiCaretRight />
          </div>
        </div>

        {/* content  */}
        <div className="right-side">
          {/* Maintain Company's IT System */}
          {active1 && (
            <div className="mydiv">
              <h3> Maintain Company's IT System</h3>
              <p>
                Our IT experts are committed to keeping your company's IT
                systems working smoothly and securely. We recognize that
                technology is always evolving and that businesses must adapt. As
                a result, we analyze your IT systems regularly to discover
                potential faults, inefficiencies, or weaknesses.
              </p>
              <p>
                Make sure to let obsolete or inefficient IT systems hinder your
                business. Contact us today to learn how we can help you manage
                and improve your company's IT system, keeping you on the cutting
                edge of technology and ensuring your business runs efficiently.
              </p>
            </div>
          )}

          {/* Eliminate Security Threats  2 */}
          {active2 && (
            <div className="mydiv">
              <h3>Eliminate Security Threats </h3>
              <p>
                We recognize that a company's security is critical to its
                success at HexamileSoft. We recognize that organizations
                confront various security challenges that might jeopardize
                sensitive data, intellectual property, and consumer trust.
                Through our comprehensive services, we emphasize tackling and
                removing these security concerns.
              </p>
              <p>
                We at HexamileSoft are committed to allowing you to focus on
                your core business tasks while remaining secure. By
                collaborating with us, you can be confident that your company's
                key assets and sensitive data are secure. Our complete security
                services, customized solutions, and proactive approach ensure
                your organization's resilience in the face of evolving cyber
                threats. <br />
                Choose HexamileSoft to reduce security threats and provide
                comprehensive protection for your business.
              </p>
            </div>
          )}

          {/* Advice on Technology Best Practices 3 */}
          {active3 && (
            <div className="mydiv">
              <h3>Advice on Technology Best Practices</h3>
              <p>
                Businesses must adapt to and use rapid technological changes to
                remain competitive. Recognizing the significance of this, we at
                Hexamilesoft prioritize our client's success and provide
                detailed guidance on best practices in technology.
              </p>
              <p>
                We at Hexamilesoft believe that technology has the potential to
                transform organizations of all sizes. Our clients may optimize
                their technological practices and stay ahead in today's dynamic
                business market by leveraging our experience and strategic
                assistance. We are committed to working with our clients and
                empowering them to use technology to their advantage.
              </p>
            </div>
          )}

          {/* Troubleshoot Problems  4 */}
          {active4 && (
            <div className="mydiv">
              <h3>Troubleshoot Problems </h3>
              <p>
                Our skilled staff at HexamileSoft guarantees that our company
                operates smoothly by delivering effective troubleshooting
                services. We recognize that hardware, software, and network
                issues can occur anytime, causing disruptions and impeding
                productivity. That is why we are here to handle these concerns
                immediately and as effectively as possible.
              </p>
              <p>
                We go beyond simply addressing problems at HexamileSoft and
                provide helpful recommendations to optimize our hardware,
                software, and systems. Our professionals stay current on
                industry trends and technical changes, allowing us to recommend
                relevant hardware updates, software applications, and system
                enhancements. We assist our organization in staying competitive
                by leveraging our experience and ensuring that reliable and
                efficient technologies support our operations.
              </p>
            </div>
          )}

          {/* Collaborate With In-house Team  5 */}
          {active5 && (
            <div className="mydiv">
              <h3>Collaborate With In-house Team </h3>
              <p>
                At Hexamilesoft, we understand the importance of teamwork and
                effective collaboration in achieving the best possible results
                for our clients. A successful partnership between our
                consultants and the client's in-house technical team is crucial
                regarding IT consulting. By collaborating with the client's
                in-house technical staff, we aim to integrate our expertise with
                the organization's existing capabilities seamlessly. This
                collaborative approach enhances our solutions' effectiveness and
                empowers the client's team to take ownership of their IT
                infrastructure and future projects.
              </p>
              <p>
                Our IT consultant team recognizes the value of collaborating
                with the client's in-house technical staff. We can effectively
                solve technical problems, provide training, and create a
                cohesive and knowledgeable IT environment by working together.
                This collaborative approach is essential for long-term success
                and client satisfaction.
              </p>
            </div>
          )}

          {/* Implement New Tech Solutions 6 */}
          {active6 && (
            <div className="mydiv">
              <h3>Implement New Tech Solutions</h3>
              <p>
                HexamileSoft takes care of your system upgrades and new
                technology implementation concerns. We manage these activities
                as your trusted IT consultant, allowing you to focus on your
                main business objectives without interruption.
              </p>
              <p>
                You may be confident that your IT needs are in experienced hands
                when HexamileSoft serves as your IT consultant. We relieve
                stress, allowing you to focus on running your business
                efficiently and meeting your objectives. Our knowledge,
                attention to detail, and commitment to client satisfaction
                distinguish us as a trustworthy partner in implementing and
                managing your IT solutions.
              </p>
            </div>
          )}

          {/* Produce Reports 7 */}
          {active7 && (
            <div className="mydiv">
              <h3>Produce Reports</h3>
              <p>
                At Hexamilesoft, we excel in providing exceptional IT services
                that ensure smooth operations for your business. One of our key
                strengths lies in our ability to produce comprehensive reports.
                Our organization excels at providing exceptional IT services
                that ensure your business runs smoothly. Our ability to provide
                thorough reports that provide significant insights into the
                efficacy of technology within your organization is one of our
                core assets. These reports are prepared weekly, monthly, and
                quarterly, giving you a clear picture of the technical landscape
                and allowing you to make informed decisions.
              </p>
              <p>
                We are committed to generating these reports because we believe
                in transparency and the value of data-driven decision-making. We
                understand that having accurate, up-to-date information is vital
                to your organization's performance.
              </p>{" "}
              <p>
                We help you to make educated decisions, optimize your IT
                resources, and achieve improved operational efficiency by
                leveraging our expertise in creating precise reports on the
                efficacy of the technology. Join forces with us to benefit from
                a transparent and well-managed IT environment.
              </p>
            </div>
          )}

          {/* Excellent Communication 8 */}
          {active8 && (
            <div className="mydiv">
              <h3>Excellent Communication</h3>
              <p>
                We take great pleasure in our organization's team members'
                written and vocal communication skills. This ability enables us
                to solve problems quickly and effectively since good
                communication ensures everyone is on the same page.
              </p>
              <p>
                Our team's communication skills go beyond internal exchanges.
                Our team members excel in clearly communicating information,
                listening attentively, and addressing problems while working
                with clients, partners, or other external stakeholders. It
                contributes to developing solid relationships and ensures that
                everyone engaged is well-informed and satisfied with the
                results.
              </p>
              <p>
                Collaboration, problem-solving, decision-making, and external
                interactions are all enhanced by excellent communication
                abilities. It fosters a culture of open communication and
                continual growth by creating an environment where individuals
                feel comfortable expressing their ideas, concerns, and comments.
                We can quickly and swiftly traverse any difficulty or project
                because of our team's great communication skills.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ITCounsultancyClick;
