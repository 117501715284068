import React from "react";

import "./trustC.css";

function ImagesAround({ imgB, imgC, title, classN, imgState, setImgState }) {
  return (
    <div
      className={classN}
      onMouseEnter={() => {
        setImgState(true);
      }}
      onMouseLeave={() => {
        setImgState(false);
      }}
    >
      {imgState ? (
        <img src={imgC} alt={title} />
      ) : (
        <img src={imgB} alt={title} />
      )}
    </div>
  );
}

export default ImagesAround;
