import React from "react";

import PY1 from "../../../../assets/work/mobile apps/call recorder/call recorder.jpg";

import { RxCross1 } from "react-icons/rx";
import { useContext } from "react";
import { WorkMenuData } from "../../OurWork";

function CallRecorderAppA() {
  const { setActive6 } = useContext(WorkMenuData);

  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActive6(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActive6(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>
          <div className="top-pop">
            <h2>Call Recording App</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Mobile App</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>Jhon Spell</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                The Call Recorder App is a powerful tool allowing users to
                record phone conversations easily.
              </strong>
            </p>
            <p>
              This App provides a convenient way to capture and store important
              conversations for future reference, personal or professional
              purposes. <br />
              Call Recorder App is its compatibility with various platforms,
              including both Android and iOS devices. Users can download the App
              from their respective app stores and install it on their
              smartphones, enabling them to start recording their calls
              immediately. The App offers a user-friendly interface that makes
              navigating and configuring the recording settings easy. Users can
              choose to record all incoming and outgoing calls automatically or
              manually select specific calls to record. This flexibility allows
              for personalized usage based on individual preferences and
              requirements.
            </p>
            <p>
              Privacy is an important consideration regarding call recording,
              and the Call Recorder App considers this. Users can
              password-protect their recorded files, ensuring that sensitive
              conversations remain secure and accessible only to authorized
              individuals. The App provides high-quality audio recording,
              clearly capturing both sides of the conversation. It is
              particularly useful for individuals who must keep records of
              important business discussions, legal matters, or interviews. The
              recorded files can be easily played back within the App or shared
              via email, messaging apps, or cloud storage services.
            </p>
            <p>
              The Call Recorder App is a valuable tool for individuals who need
              to record and manage phone conversations. With its user-friendly
              interface, versatile features, and emphasis on privacy and
              security, this App provides a convenient solution for capturing
              and storing important calls with ease and peace of mind.
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallRecorderAppA;
