import React from "react";
import { useState } from "react";

import "../allClickProjects.scss";

import { GiAbstract005 } from "react-icons/gi";

import { BiCaretRight } from "react-icons/bi";
import { SimpleButon } from "../../../../buttons/MyButon";

function TechSupportClick() {
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);
  const [active7, setActive7] = useState(false);
  const [active8, setActive8] = useState(false);

  const activeHandler = (
    a1 = true,
    a2 = false,
    a3 = false,
    a4 = false,
    a5 = false,
    a6 = false,
    a7 = false,
    a8 = false
  ) => {
    setActive1(a1);
    setActive2(a2);
    setActive3(a3);
    setActive4(a4);
    setActive5(a5);
    setActive6(a6);
    setActive7(a7);
    setActive8(a8);
  };

  return (
    <div className="inner-project-wrapper">
      <div className="heading">
        <h2>What did We do As Your Technical Support Team?</h2>
        <p>
          Our tech support team provides round-the-clock monitoring and
          technical support to address any issues detected or reported in your
          system. They work tirelessly to ensure quick and effective resolution
          of any technical problems that may arise, ensuring that your system
          runs smoothly at all times.
        </p>
        <hr />
      </div>

      <div className="select-projects-container">
        <div className="left-side">
          {/* Identify Problems 1  */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler();
            }}
            style={{ backgroundColor: active1 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Identify Problems </span>
            </div>
            <BiCaretRight />
          </div>

          {/* Replace or Repair 2 */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                false
              );
            }}
            style={{ backgroundColor: active2 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Replace or Repair</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Troubleshoot Technical Issues 3 */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false
              );
            }}
            style={{ backgroundColor: active3 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Troubleshoot Technical Issues </span>
            </div>
            <BiCaretRight />
          </div>

          {/* Resolve Network Issue 4 */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false
              );
            }}
            style={{ backgroundColor: active4 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Resolve Network Issue </span>
            </div>
            <BiCaretRight />
          </div>

          {/* Collaborate With Your In-house Team 5 */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false
              );
            }}
            style={{ backgroundColor: active5 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Collaborate With Your In-house Team </span>
            </div>
            <BiCaretRight />
          </div>

          {/* Equipment Safety Checks 6 */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false
              );
            }}
            style={{ backgroundColor: active6 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Equipment Safety Checks</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Testing and Evaluating New Techniques 7 */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false
              );
            }}
            style={{ backgroundColor: active7 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Testing and Evaluating New Techniques</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Regular Reports 8 */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true
              );
            }}
            style={{ backgroundColor: active8 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Regular Reports</span>
            </div>
            <BiCaretRight />
          </div>
        </div>

        {/* content  */}
        <div className="right-side">
          {/* Identify Problems 1  */}
          {active1 && (
            <div className="mydiv">
              <h3>Identify Problems</h3>
              <p>
                As your Technical Support team, we identify, investigate, and
                resolve any technical problems with your computer software and
                hardware to ensure that your organization's operations go on
                steadily.
              </p>
            </div>
          )}

          {/* Replace or Repair 2 */}
          {active2 && (
            <div className="mydiv">
              <h3>Replace or Repair</h3>
              <p>
                We have the expertise and experience necessary to replace or
                repair any necessary parts of a system. Whether it's hardware or
                software, we quickly identify the issue and take the necessary
                steps to get the system back up and running smoothly. With a
                focus on speed and efficiency, you can trust us to minimize
                downtime and ensure that your systems function optimally.
              </p>
            </div>
          )}

          {/* Troubleshoot Technical Issues 3 */}
          {active3 && (
            <div className="mydiv">
              <h3>Troubleshoot Technical Issues </h3>
              <p>
                Our highly skilled technical experts are dedicated to providing
                top-notch support for all your technical needs. Whether you're
                facing challenges with setup, network configuration, or any
                other technical issue, our team will work tirelessly to diagnose
                the problem and find a resolution that meets your unique
                requirements. With a focus on customer satisfaction and a
                commitment to delivering efficient, effective support, you can
                count on us to be your trusted partner in all things technical.
              </p>
            </div>
          )}

          {/* Resolve Network Issue 4 */}
          {active4 && (
            <div className="mydiv">
              <h3>Resolve Network Issue </h3>
              <p>
                Our team members have the knowledge and expertise to diagnose
                and troubleshoot even the most complex network problems. From
                identifying the root cause of the issue to implementing
                effective solutions, our team works diligently to ensure a
                seamless resolution process for our clients. Whether through
                remote support or on-site assistance, we're committed to
                ensuring that our clients can resume their normal operations as
                quickly as possible. With a focus on efficiency and customer
                satisfaction, you can trust us to resolve your network issues
                with speed and expertise.
              </p>
            </div>
          )}

          {/* Collaborate With Your In-house Team 5 */}
          {active5 && (
            <div className="mydiv">
              <h3>Collaborate With Your In-house Team </h3>
              <p>
                As a technical support team, our success depends on close
                collaboration with our client's staff. We understand that our
                clients rely on their internal systems and networks to drive
                their business operations, and that's why we prioritize open and
                effective communication with their team members. Whether through
                regular check-ins, updates on progress, or troubleshooting
                sessions, our team works closely with your staff to ensure that
                their technology needs are met and their goals are achieved.
                With a focus on building strong, long-lasting relationships,
                we're committed to becoming a trusted partner for our clients
                and helping their businesses succeed.
              </p>
            </div>
          )}

          {/* Equipment Safety Checks 6 */}
          {active6 && (
            <div className="mydiv">
              <h3>Equipment Safety Checks</h3>
              <p>
                We prioritize the safety of our clients and their equipment.
                That's why we regularly conduct electrical safety checks on all
                equipment to ensure that it functions properly and meets
                industry standards. These checks help us identify potential
                hazards and take the necessary steps to address them before they
                become major problems. With a focus on preventative maintenance
                and a commitment to safety, you can trust us to keep your
                equipment functioning safely and efficiently.
              </p>
            </div>
          )}

          {/* Testing and Evaluating New Techniques 7 */}
          {active7 && (
            <div className="mydiv">
              <h3>Testing and Evaluating New Techniques</h3>
              <p>
                As one of the best technical support teams, we're always at the
                forefront of new and emerging technologies. Staying up-to-date
                and knowledgeable on the latest developments is crucial to
                providing the best possible service to our clients. That's why
                we invest time and resources into testing and evaluating new
                techniques and tools and incorporating them into our support
                processes whenever they can improve our efficiency or better
                serve our clients' needs. With a commitment to continuous
                learning and improvement, you can trust us to always be at the
                cutting edge of the industry and provide top-notch support to
                our clients.
              </p>
            </div>
          )}

          {/* Regular Reports 8 */}
          {active8 && (
            <div className="mydiv">
              <h3>Regular Reports</h3>
              <p>
                We at HexamileSoft, understand the importance of keeping our
                clients informed and updated on the status of their technical
                issues. That's why we pride ourselves on providing comprehensive
                and regular reports on the support we provide.
                <br />
                Our reports keep our clients informed and showcase our
                commitment to delivering exceptional customer service. They
                provide detailed information on the nature of the issues, the
                steps taken to resolve them, and the outcome, enabling our
                clients to have full transparency and peace of mind. Effective
                reporting is essential in building and maintaining strong
                relationships with our clients and ensuring their continued
                satisfaction with our services.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TechSupportClick;
