import React from "react";

import PY1 from "../../../../assets/work/web/project 5/1.jpg";

import { RxCross1 } from "react-icons/rx";
import { useContext } from "react";
import { WorkMenuData } from "../../OurWork";

function XCPowerWeb() {
  const { setActive5 } = useContext(WorkMenuData);

  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActive5(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActive5(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>
          <div className="top-pop">
            <h2>XC Power</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Web Design</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>RSA Bill</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                XC Power Website: Empowering Your Energy Transition Journey
              </strong>
            </p>
            <p>
              XC Power is a revolutionary online platform that aims to empower
              individuals and businesses in their energy transition journey.
              With a comprehensive range of resources, tools, and services, XC
              Power provides a one-stop destination for all renewable energy
              needs. Whether you're looking to adopt clean energy solutions,
              optimize your energy consumption, or stay informed about the
              latest industry trends, XC Power is here to guide you every step.
            </p>
            <p>
              XC Power is an empowering online platform that simplifies the
              energy transition and helps users embrace renewable energy
              solutions. With its marketplace, expert advice, energy assessment
              tools, educational resources, project management services, and
              news updates, XC Power equips individuals and businesses with the
              knowledge and tools necessary to make informed decisions and drive
              positive change in the energy sector.
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default XCPowerWeb;
