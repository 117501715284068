import React from "react";
import { useState } from "react";

import "../allClickProjects.scss";

import { GiAbstract005 } from "react-icons/gi";

import { BiCaretRight } from "react-icons/bi";
import { SimpleButon } from "../../../../buttons/MyButon";

function FrontEndClick() {
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);

  const activeHandler = (
    a1 = true,
    a2 = false,
    a3 = false,
    a4 = false,
    a5 = false,
    a6 = false
  ) => {
    setActive1(a1);
    setActive2(a2);
    setActive3(a3);
    setActive4(a4);
    setActive5(a5);
    setActive6(a6);
  };

  return (
    <div className="inner-project-wrapper">
      <div className="heading">
        <h2>Genius Designs</h2>
        <p>
          We are experts at creating exquisite UI & UX designs handcrafted for
          IT firms. Check out our design approach, though, before you believe
          us:
        </p>
        <hr />
      </div>

      <div className="select-projects-container">
        <div className="left-side">
          {/* Research  */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler();
            }}
            style={{ backgroundColor: active1 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Research</span>
            </div>
            <BiCaretRight />
          </div>

          {/* IA and Wireframes */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, true, false, false, false, false);
            }}
            style={{ backgroundColor: active2 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>IA and Wireframes</span>
            </div>
            <BiCaretRight />
          </div>

          {/* User Testing */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, true, false, false, false);
            }}
            style={{ backgroundColor: active3 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>User Testing</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Theme (User Interface) */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, false, true, false, false);
            }}
            style={{ backgroundColor: active4 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Theme (User Interface)</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Front-End Developing */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, false, false, true, false);
            }}
            style={{ backgroundColor: active5 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Front-End Developing</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Integration Support */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, false, false, false, true);
            }}
            style={{ backgroundColor: active6 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Integration Support</span>
            </div>
            <BiCaretRight />
          </div>
        </div>

        {/* content  */}
        <div className="right-side">
          {/* Research  */}
          {active1 && (
            <div className="mydiv">
              <h3>
                Goal Analysis, Persona Development, Work Processes, and
                Competitor Evaluation
              </h3>
              <p>
                Establishing objectives and goals is the first step in
                user-centered design. It lays the groundwork for strategy,
                design, content, and information architecture (IA). Business
                priorities frequently need to reflect the reality of true user
                demands. It's not always what we "think" is wonderful that
                consumers find awesome and important. During the research phase,
                we help you by pointing out knowledge gaps and comparing your
                expectations with those of real users. We assist you in
                developing a plan that will serve user needs and your corporate
                objectives. Our analytical and research-driven methodology
                provides strategic insights into how your clients will respond
                to and interact with your application. We select research
                techniques that benefit you based on the time and resources at
                our disposal.
              </p>
              <p>
                Identifying the users of a product or website and what they will
                anticipate from experience is frequently the first step in
                establishing a product roadmap. This study brings to life the
                tastes and behavior of your users. This in-depth data goes
                beyond demographics and offers meaningful information such as
                how a person arrived at the app or site, their preferences,
                potential worries, etc.
              </p>
            </div>
          )}

          {/* IA and Wireframes */}
          {active2 && (
            <div className="mydiv">
              <h3>
                Clickable Prototypes, Information Architecture, and Layouts
              </h3>
              <p>
                Our approach to information architecture prioritizes the needs
                of our clients or users. We focus on understanding what they
                need from us and how to make it easily accessible. To do this,
                we use a combination of existing data, content, best practices,
                and user feedback to organize the IA in a way that aligns with
                our company objectives and provides a positive customer
                experience. We use techniques such as mind mapping and card
                sorting to ensure clear and effective interactions at every
                stage of the architecture.
              </p>
              <p>
                Wireframing is an effective tool for visualizing data structures
                and UI features. It facilitates clear communication among team
                members and enables early testing of workflows, navigation, and
                the overall data structure. It is an important step in the
                design process.
              </p>
            </div>
          )}

          {/* User Testing */}
          {active3 && (
            <div className="mydiv">
              <h3>Storyboarding, In-Person, and Remote User Testing</h3>
              <p>
                We work closely with you to establish Key Performance Indicators
                (KPIs) that align with the objectives set at the start of the UX
                process. We run user testing sessions and conduct thorough
                analyses to turn data into actionable design improvements. Our
                user feedback techniques include one-on-one meetings and remote
                testing, among others. We assist you in making the best decision
                for you and your participants and are always open to learning
                new ways to enhance your product.
              </p>
            </div>
          )}

          {/* Theme (User Interface) */}
          {active4 && (
            <div className="mydiv">
              <h3>Website, Web Application, and Native App Visual Design</h3>
              <p>
                At HexamileSoft, we understand that a product's aesthetic appeal
                can greatly impact its users. While a solid information
                architecture is important, it alone may not drive engagement.
                That's why we use user-centered design to create a unified and
                appealing experience for the target audience. By targeting
                users' deeper emotional needs, we aim to evoke an emotional
                response that enhances their experience. Our goal is to add
                context to users' natural behavior and create a meaningful story
                for them to take away from experience. Additionally, we ensure
                that your product aligns with your brand's integrity and
                cohesiveness.
              </p>
            </div>
          )}

          {/* Front-End Developing  */}
          {active5 && (
            <div className="mydiv">
              <h3>HTML, CSS, JAVASCRIPT, BOOTSTRAP</h3>
              <p>
                We focus on creating clean and modular code. Our team has a deep
                understanding of modern front-end technologies such as HTML5,
                CSS3, and JQuery, and we are adept at deploying and managing
                them with ease, ultimately saving our clients money.
              </p>
              <p>
                It is crucial to be able to code a website that functions
                seamlessly in today's adaptive and responsive web environment.
                Our team of highly skilled front-end developers has created some
                of the most visually stunning HTML/CSS and JavaScript. We hold
                ourselves to the highest standards and pride ourselves in our
                coding abilities. We deliver expertly structured, validated, and
                documented HTML and CSS, as well as robust and adaptable
                JavaScript. Our developers are also skilled in design work,
                ensuring seamless collaboration between designers and
                developers.
              </p>
            </div>
          )}

          {/* Integration Support */}
          {active6 && (
            <div className="mydiv">
              <h3>Integration, Assistance, and Warranty</h3>
              <p>
                We stand behind our designs and code, which is why we offer a
                60-day warranty period to give you ample time to identify any
                issues. We also provide technical support and assistance during
                back-end integration to give you peace of mind.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FrontEndClick;
