import React from "react";

function CoreDiv({ heading, mini, parag }) {
  return (
    <div className="inner-wrapper">
      <h2>{heading}</h2>
      <h3>{mini}</h3>
      <p>{parag}</p>
    </div>
  );
}

export default CoreDiv;
