import React from "react";
import ProcessFooter from "../ProcessFooter";

import { useEffect } from "react";

function DistributedAgile() {
  useEffect(() => {
    document.title = "Distributed Agile - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "HexamileSoft is a digital agency passionate about great ideas, tiny details, and a better approach (Modern Agile Development methodologies) to creating world-class work.";
    document.getElementsByTagName("META")[6].content =
      "Distributed Agile - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "HexamileSoft is a digital agency passionate about great ideas, tiny details, and a better approach (Modern Agile Development methodologies) to creating world-class work.";
    document.getElementsByTagName("META")[12].content =
      "Distributed Agile - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "HexamileSoft is a digital agency passionate about great ideas, tiny details, and a better approach (Modern Agile Development methodologies) to creating world-class work.";
  }, []);

  return (
    <div className="distributed-agile-container">
      <div className="heading-container">
        <h1>Spread Out Agile Development</h1>
        <h3>Our preferred speed is swift. We are Agile for this reason.</h3>
        <p>
          Agile is a liberated approach to software development that is based on
          the concepts of short iterations, ongoing client feedback, and
          immediate work prioritization. <br />
          Agile is used throughout HexamileSoft by our company's philosophy,
          which aims to give clients control over their projects and help them
          make the most of their resources and our expertise.
          <br />
          It takes more than just writing code and fixing bugs to develop
          software. It entails a ton of planning for resources, prioritizing and
          allocating tasks, coordinating with vendors and clients, and other fun
          stuff.
          <br />
          The project's timescales can be entirely affected by a single change
          to the charter. For project managers and clients alike, that is a
          nightmare.
          <br />
          No matter how easy or difficult the project is, a solid structure must
          enable "Work to Happen" to occur as it should. <br />
          That is what causes the majority of projects to lag or, in the worst
          cases, fail to finish.
        </p>
      </div>

      {/* project-behind */}
      <div className="project-behind-container">
        <div className="upper-wrapper">
          <strong>
            The following are the most frequent dangers to the project's
            success:
          </strong>
          <ul>
            <li>
              Continuous scope changes influence project delivery and timeframes
            </li>
            <li>
              Long release cycles put additional strain on managers, testers,
              and developers to provide code.
            </li>
            <li>
              The delayed feedback loop between IT vendors and clients produces
              unwanted deliverables.
            </li>
          </ul>
        </div>

        <div className="bottom-wrapper">
          <p>
            "When working across several time zones with stringent delivery
            timelines and a dynamic industry environment, scattered agile is
            your one-shot power pump to delighted clients and leaping bottom
            lines."
          </p>
        </div>
      </div>

      {/* development */}
      <div className="development-container">
        <h2>HexamileSoft & Agile Development</h2>
        <p>
          We are familiar with Agile and outsourcing. For this reason, when one
          of our clients contacts us to outsource a portion of a project and
          requests that it be completed utilizing agile methodology, we take the
          time to comprehend the business problem the product intends to
          address.
          <br />
          To ensure that you understand what we are doing, establish clear
          communication channels and update you frequently.
        </p>

        <h5>
          Establish roles and duties that are apparent to all team members:
        </h5>

        <ul>
          <li>
            Request client input, examine it, and include it in the upcoming
            version.
          </li>
          <li>
            Release on a set schedule to ensure consistency and dependability.
          </li>
          <li>
            Share a daily or continuous build so that our team and yours may
            work on the same code.
          </li>
          <li>
            Utilize the same bug tracking or ticketing system that you do.
          </li>
        </ul>
      </div>

      {/* our Clients */}
      <div className="development-container">
        <h2>Agile According To Our Clients</h2>
        <p>
          For our clients, Agile implies Total control, allowing them to make
          changes to project requirements based on their most recent goals,
          participate in the ongoing progress of projects, and suggest and
          establish project baselines in their unique ways.
        </p>
        <h3>In summary:</h3>

        <p>
          Project specifications are tailored to the most recent market trends
          and needs. Project charters can be updated to include new features
          without significantly disrupting the project's progress.
          <br />
          Sprints retain instantaneous and continuous feedback, enabling clients
          to analyze the features and express their approval or disapproval of
          their assessment. Quick and condensed development cycles provide for
          the time and cost control.
        </p>
      </div>

      <div className="bottom-container">
        <ProcessFooter />
      </div>
    </div>
  );
}

export default DistributedAgile;
