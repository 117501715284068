import React from "react";

export default function SingleProject({ projectP, title }) {
  return (
    <div className="single-project-container">
      <h2>{title}</h2>
      <img src={projectP} alt={title} />
      <hr className="hr" />
    </div>
  );
}

export function SingleProjectMobile({ projectP1, title1, projectP2, title2 }) {
  return (
    <div className="single-project-container">
      <h2>{title1}</h2>
      <div className="mobile-images">
        <img src={projectP1} alt={title1} />
        <img src={projectP2} alt={title2} />
      </div>
      <hr className="hr" />
    </div>
  );
}
