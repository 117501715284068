import React from "react";

function HeadingContent({ tImg, tHead, tParag }) {
  return (
    <div className="my-content-cellphone">
      <div className="headings">
        <div className="my-img">
          <img src={tImg} alt={tHead} />
        </div>
        <h3>{tHead}</h3>
      </div>
      <p>{tParag}</p>
    </div>
  );
}

export default HeadingContent;
