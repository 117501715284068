import React from "react";

import PY1 from "../../../../assets/work/mobile apps/image finder/image finder.jpg";

import { RxCross1 } from "react-icons/rx";
import { useContext } from "react";
import { WorkMenuData } from "../../OurWork";

function ImageFinderAppA() {
  const { setActive9 } = useContext(WorkMenuData);

  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActive9(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActive9(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>
          <div className="top-pop">
            <h2>Image Finder App</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Mobile App</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>Delilah Thomas</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                The Image Finder App is a powerful and versatile tool designed
                to help users easily search and discover images based on their
                specific needs and preferences.
              </strong>
            </p>
            <p>
              The App revolutionizes how people find and explore visual content
              with a user-friendly interface and advanced search capabilities.
              Image Finder App's key feature is its extensive image database.
              Users can access a vast collection of photographs, illustrations,
              graphics, and other visual assets from reliable and reputable
              platforms.
            </p>
            <p>
              The App's search functionality is its core strength. It employs
              advanced algorithms and techniques, including image recognition
              and deep learning, to analyze and categorize images based on their
              content, color, composition, and other visual attributes. It
              allows users to perform highly specific searches and find images
              that match their requirements. The App also offers filters and
              sorting options to refine search results further. Users can adjust
              parameters such as image size, orientation, date, and licensing
              rights to narrow their search and find the perfect image for their
              needs. Additionally, the App provides suggestions and related
              images based on the user's search queries, helping them discover
              new and relevant content they may have already considered.
            </p>
            <p>
              The App enables easy sharing of images via social media, email,
              messaging platforms, or direct downloads. It allows users to
              seamlessly incorporate the discovered visuals into their projects
              or share them with others. <br />
              Users can effortlessly explore the App's features and
              functionalities with its intuitive interface and smooth
              navigation. The App's design is visually appealing, providing a
              pleasant browsing experience that enhances overall user
              satisfaction. The Image Finder App is a versatile and
              user-friendly tool that simplifies discovering and accessing
              various images
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageFinderAppA;
