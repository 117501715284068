import React from "react";
import HeadingContent from "./HeadingContent";

import ACT from "../../../../assets/service/got_idea/Adapting to change.png";
import CIT from "../../../../assets/service/got_idea/Coding icon.png";
import PIT from "../../../../assets/service/got_idea/Prototyping Icon.png";
import UIT from "../../../../assets/service/got_idea/user icon.png";
import STT from "../../../../assets/service/got_idea/Smooth Transfer.png";
import CT from "../../../../assets/service/got_idea/Confidential.png";
import whyUs from "../../../../assets/service/got_idea/whyHex.jpg";

function WhyHexaContent() {
  const p1 =
    "As your technical co-founder and extended development team, we take care of all technical aspects of your product development, allowing you to focus on other important areas, such as marketing and promoting your product. By handling the technical work, we help you save time and money, which you can use to grow your business.";

  const p2 =
    "We understand the dynamic nature of startups, and our process is tailored to accommodate any changes in direction without compromising on quality or timelines. We can adapt quickly to the fast-paced startup environment and keep your project on track.";

  const p3 =
    "We recognize the significance of user experience in driving product adoption. We demonstrate this by incorporating a user-centered approach in our development process, continually gathering feedback from you, and iteratively refining the user experience. This ensures that the final product meets your users' needs and exceeds their expectations.";

  const p4 =
    "We specialize in rapidly creating prototypes to showcase your idea to stakeholders. Our expertise in this area allows us to help you quickly develop a Minimum Viable Product (MVP), so you can gather valuable customer feedback and improve your product efficiently. This approach can help you validate your idea and make informed decisions about your product development before committing significant resources.";

  const p5 =
    "We offer ongoing technical support and maintenance but also have a seamless handover process if you bring development in-house. We ensure a smooth transition by transferring all necessary knowledge and skills.";

  const p6 =
    "We keep your information confidential; all code and IP developed belong to you. We do not retain any rights or claim ownership.";

  return (
    <div className="we-are-hexa-container">
      <div className="heading-content">
        <h2>Why HexamileSoft?</h2>
        <p>
          We handle every aspect of the technical process, from
          conceptualization and prototyping to the development, deployment, and
          ongoing management of live applications.
        </p>
        <hr />
      </div>

      <div className="got-idea-inner-container">
        <div className="left-side">
          <img src={whyUs} alt="hexamilesoft" />
        </div>
        <div className="right-side">
          <HeadingContent tImg={CIT} tHead={"Not Just Coding"} tParag={p1} />
          <HeadingContent
            tImg={ACT}
            tHead={"Adaptability to Change"}
            tParag={p2}
          />
          <HeadingContent
            tImg={UIT}
            tHead={"Significance OF User Experience"}
            tParag={p3}
          />
          <HeadingContent
            tImg={PIT}
            tHead={"Prototyping and Minimum Viable Product"}
            tParag={p4}
          />
          <HeadingContent tImg={STT} tHead={"Smooth Transfers"} tParag={p5} />
          <HeadingContent
            tImg={CT}
            tHead={"Importance of Confidentiality "}
            tParag={p6}
          />
        </div>
      </div>
    </div>
  );
}

export default WhyHexaContent;
