import React from "react";
import ServiceContact from "../../contact/ServiceContact";
import InnerHeading from "../../headings/InnerHeading";
import ServiceHead from "../../top_head/ServiceHead";
import TrustPilot from "../../trust/TrustPilot";
import UpperContent from "../../upper_content/UpperContent";
import TrustedBy from "../../trusted_by_imgs/TrustedBy";
import StartProject from "../../start_project/StartProject";
import EngagementModel from "../../engagement_models/EngagementModel";
import HireEngineers from "../../hire_engineers/HireEngineers";
import WhyHexaContent from "./WhyHexaContent";
import ClickProjects from "./ClickProjects";

import "./gotAnIdea.scss";

// images
import dTeam from "../../../../assets/service/services/Got a business idea.jpg";

import { useEffect } from "react";

function GotAnIdea() {
  const paragH1 =
    "We can help you bring your idea to life, whether it's just a rough sketch on paper or a detailed document. We act as your technical co-founders and dedicated development team, enabling you to focus on strategic initiatives such as marketing while we handle the technical implementation of your product. ";

  const paragCon1 =
    "From ideation to deployment and maintenance, we handle all technical aspects while catering to the specific needs of entrepreneurs. We work in stages to develop a compelling product demonstration for investors and other stakeholders. Our startup DNA drives our commitment to supporting entrepreneurs throughout their product development journey.";
  const paragCon2 =
    "Trust us to be the catalyst for your startup's success. At HexamileSoft, we specialize in developing products that can reach millions of users. Our team of over 50 experts in product development can help your startup achieve success. ";
  const paragCon3 =
    "Our belief that your great idea deserves a team just as passionate about it as you are drives us to do our best. We offer comprehensive services for startups, including consulting, design, programming, infrastructure management, and marketing. We also have a strong network to help you raise capital through venture capital firms and accelerator programs. Let us be your technology partner and work with you to bring your idea to life and make it a reality.";

  const startP =
    "At HexamileSoft, we're all about startups! It's in our DNA. We love new ideas and bringing them to life, and you'll see that in our hands-on approach to your project. From concept to launch, we're your go-to partner for all things startup. Let's make your ideas a reality! ";

  useEffect(() => {
    document.title = "Business Idea - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "We understand the challenges involved in building a startup product. Do not fret anymore and tell us your requirements.";
    document.getElementsByTagName("META")[6].content =
      "Business Idea - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "We understand the challenges involved in building a startup product. Do not fret anymore and tell us your requirements.";
    document.getElementsByTagName("META")[12].content =
      "Business Idea - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "We understand the challenges involved in building a startup product. Do not fret anymore and tell us your requirements.";
  }, []);

  return (
    <div className="services-container">
      <div className="heading-container">
        <ServiceHead title={"Implement Your Ideas"} parag={"Have an Idea?"} />
      </div>

      {/* InnerHeading */}
      <div className="title-heading-container">
        <div className="heading-wrapper">
          <InnerHeading
            titleH={"We Bring Your Ideas to Life"}
            paragH={paragH1}
          />
        </div>
        <hr />
        <div className="top-inner-container">
          <UpperContent
            imgCon={dTeam}
            paragCon1={paragCon1}
            paragCon2={paragCon2}
            paragCon3={paragCon3}
            btnPath={"#gba"}
          />
        </div>
      </div>

      {/* trusted by */}
      <div className="trusted-by-container">
        <TrustedBy />
      </div>

      {/* click projects  */}
      <div className="click-projects-container">
        <ClickProjects />
      </div>

      {/* why hexamile  */}
      <div className="why-hexa-container">
        <WhyHexaContent />
      </div>

      {/* hire engineer  */}
      <div className="hire-engineer-container">
        <HireEngineers
          engrName={"Hire Software Engineers"}
          engrPrice={"2200"}
          btnPath={"#gba"}
        />
        <EngagementModel />
      </div>

      {/* start your project  */}
      <div className="start-your-project-container">
        <StartProject
          titleProj={"Interested in Creating Something Awesome With Us?"}
          startP={startP}
          btnPath={"#gba"}
        />
      </div>

      {/* TrustPilot */}
      <div className="trust-pilot-wrap">
        <TrustPilot />
      </div>

      {/* ServiceContact */}
      <div className="service-contact-wrapper" id="gba">
        <ServiceContact />
      </div>
    </div>
  );
}

export default GotAnIdea;
