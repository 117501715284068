import React from "react";
import { useEffect } from "react";

function PrivacyPolicy() {
  useEffect(() => {
    document.title = "Privacy Policy - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "We keep your information confidential, and all code and IP developed for you belong to you. We do not retain any rights or claim ownership.";
    document.getElementsByTagName("META")[6].content =
      "Privacy Policy - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "We keep your information confidential, and all code and IP developed for you belong to you. We do not retain any rights or claim ownership.";
    document.getElementsByTagName("META")[12].content =
      "Privacy Policy - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "We keep your information confidential, and all code and IP developed for you belong to you. We do not retain any rights or claim ownership.";
  }, []);

  return (
    <div className="policy-container">
      <div className="policy-wrapper">
        <h1>Privacy Policy</h1>

        <div className="first-wrapper">
          <p>
            At HexamileSoft, our visitors' privacy is one of our top objectives.
            This privacy policy document outlines the data we gather, record,
            and use and its intended use. <br />
            If you need any additional information about our privacy policy or
            if you have any other queries, don't hesitate to get in touch with
            us.
            <br />
            This statement only applies to our online activities. It applies to
            site users who may have shared, gathered, or both types of
            information on our website. This policy does not cover any data
            gathered offline or from other websites.
          </p>
        </div>

        <div className="second-wrapper">
          <h2>Information we gather:</h2>
          <p>
            You will know why and for what purpose we are asking for your
            personal information when we ask you for it. We might get your name,
            email address, phone number, message content, and any other details
            you give us when you contact us directly. <br />
            We could ask for your contact information when you create an
            account, such as your name, address, phone number, and email.
          </p>
        </div>
        <div className="third-wrapper">
          <h2>How do we use your information:</h2>
          <p>
            We use the information that we gather in a variety of ways, such as:{" "}
            <br />
            Providing, running, and maintaining our website Enhance, customize,
            and broaden our website Recognize and evaluate how you use our
            website. Create new products, services, and features. Communicate
            with you for marketing and promotional purposes and to give you
            updates and other information about the website.
          </p>
        </div>

        <div className="forth-wrapper">
          <h2>Children policy:</h2>
          <p>
            Our services are only expected to use by youngsters younger than
            sixteen (16).
            <br />
            We will only intentionally gather individual data straightforwardly
            from kids younger than sixteen (16) with parental permission.
            <br />
            For the cancellation of individual information for any reason,
            Contact Us.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
