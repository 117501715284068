import React from "react";

import PY1 from "../../../../assets/work/web/project 2/1.png";

import { RxCross1 } from "react-icons/rx";
import { useContext } from "react";
import { WorkMenuData } from "../../OurWork";

function SparkleanCleaningWeb() {
  const { setActive2 } = useContext(WorkMenuData);

  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActive2(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActive2(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>
          <div className="top-pop">
            <h2>Sparklean Cleaning Website</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Web Design</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>Daniel</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                Sparklean Cleaning Services is a professional cleaning company
                that offers a wide range of cleaning solutions for residential
                and commercial clients. Their website is a comprehensive
                platform where customers can learn about their services, request
                quotes, and schedule cleaning appointments.
              </strong>
            </p>
            <p>
              The Sparklean Cleaning Services website is designed with a clean
              and modern aesthetic, reflecting their commitment to cleanliness
              and professionalism. The user-friendly interface makes it easy for
              visitors to navigate the various sections and find the necessary
              information. One of the website's key features is the detailed
              description of the cleaning services offered by Sparklean. They
              provide a comprehensive list of services, including regular house
              cleaning, deep cleaning, move-in/move-out cleaning, office
              cleaning, and post-construction cleaning. The website also
              highlights the company's commitment to customer satisfaction.
              Testimonials from previous clients are prominently displayed,
              showcasing the positive experiences and high level of service
              provided by Sparklean Cleaning Services. It helps to build trust
              and confidence in potential customers, reassuring them that they
              are choosing a reliable and reputable cleaning service provider.
            </p>
            <p>
              The website offers a convenient quote request and scheduling
              system to streamline the booking process. Customers can fill out
              the online form with their cleaning requirements, including the
              service type, the space size, and any specific requests. The team
              at Sparklean then promptly responds with a detailed quote and
              available time slots for scheduling the cleaning appointment.
              Additionally, the website provides valuable information about the
              company, such as their background, mission, and the cleaning
              products they use.
            </p>
            <p>
              The website also features a frequently asked questions (FAQ)
              section, addressing common queries that customers may have. It is
              a helpful resource for visitors, answering questions about
              pricing, cleaning procedures, and other important details.
            </p>
            <p>
              Overall, the Sparklean Cleaning Services website effectively
              communicates the company's professionalism, range of services, and
              dedication to customer satisfaction.
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SparkleanCleaningWeb;

export function HomeSparkleanCleaningWeb({ setActiveP2 }) {
  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActiveP2(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActiveP2(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>
          <div className="top-pop">
            <h2>Sparklean Cleaning Website</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Web Design</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>Daniel</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                Sparklean Cleaning Services is a professional cleaning company
                that offers a wide range of cleaning solutions for residential
                and commercial clients. Their website is a comprehensive
                platform where customers can learn about their services, request
                quotes, and schedule cleaning appointments.
              </strong>
            </p>
            <p>
              The Sparklean Cleaning Services website is designed with a clean
              and modern aesthetic, reflecting their commitment to cleanliness
              and professionalism. The user-friendly interface makes it easy for
              visitors to navigate the various sections and find the necessary
              information. One of the website's key features is the detailed
              description of the cleaning services offered by Sparklean. They
              provide a comprehensive list of services, including regular house
              cleaning, deep cleaning, move-in/move-out cleaning, office
              cleaning, and post-construction cleaning. The website also
              highlights the company's commitment to customer satisfaction.
              Testimonials from previous clients are prominently displayed,
              showcasing the positive experiences and high level of service
              provided by Sparklean Cleaning Services. It helps to build trust
              and confidence in potential customers, reassuring them that they
              are choosing a reliable and reputable cleaning service provider.
            </p>
            <p>
              The website offers a convenient quote request and scheduling
              system to streamline the booking process. Customers can fill out
              the online form with their cleaning requirements, including the
              service type, the space size, and any specific requests. The team
              at Sparklean then promptly responds with a detailed quote and
              available time slots for scheduling the cleaning appointment.
              Additionally, the website provides valuable information about the
              company, such as their background, mission, and the cleaning
              products they use.
            </p>
            <p>
              The website also features a frequently asked questions (FAQ)
              section, addressing common queries that customers may have. It is
              a helpful resource for visitors, answering questions about
              pricing, cleaning procedures, and other important details.
            </p>
            <p>
              Overall, the Sparklean Cleaning Services website effectively
              communicates the company's professionalism, range of services, and
              dedication to customer satisfaction.
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
