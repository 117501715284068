import React from "react";

import "./trustedBy.scss";


// black
import IMG1 from "../../../assets/service/trustpilot/1.png";
import IMG2 from "../../../assets/service/trustpilot/18.png";
import IMG3 from "../../../assets/service/trustpilot/4.png";
import IMG4 from "../../../assets/service/trustpilot/5.png";
import IMG5 from "../../../assets/service/trustpilot/15.png";

// blue
import IMGB1 from "../../../assets/service/trustpilot/color logos/1.png";
import IMGB2 from "../../../assets/service/trustpilot/color logos/18.png";
import IMGB3 from "../../../assets/service/trustpilot/color logos/4.png";
import IMGB4 from "../../../assets/service/trustpilot/color logos/5.png";
import IMGB5 from "../../../assets/service/trustpilot/color logos/15.png";

import { useState } from "react";

function TrustedBy() {
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(true);
  const [active3, setActive3] = useState(true);
  const [active4, setActive4] = useState(true);
  const [active5, setActive5] = useState(true);

  return (
    <div className="trusted-by-wrapper">
      <div className="upper-con">
        <p>
          <span>Trusted By</span>
        </p>

        <div className="all-img">
          {/* img 1 */}
          <div
            className="img"
            onMouseEnter={() => {
              setActive1(false);
            }}
            onMouseLeave={() => {
              setActive1(true);
            }}
          >
            {active1 ? (
              <img src={IMG1} alt="trusted by" />
            ) : (
              <img src={IMGB1} alt="trusted by" />
            )}
          </div>

          {/* img 2 */}
          <div
            className="img"
            onMouseEnter={() => {
              setActive2(false);
            }}
            onMouseLeave={() => {
              setActive2(true);
            }}
          >
            {active2 ? (
              <img src={IMG2} alt="trusted by" />
            ) : (
              <img src={IMGB2} alt="trusted by" />
            )}
          </div>

          {/* img 3 */}
          <div
            className="img"
            onMouseEnter={() => {
              setActive3(false);
            }}
            onMouseLeave={() => {
              setActive3(true);
            }}
          >
            {active3 ? (
              <img src={IMG3} alt="trusted by" />
            ) : (
              <img src={IMGB3} alt="trusted by" />
            )}
          </div>

          {/* img 4 */}
          <div
            className="img"
            onMouseEnter={() => {
              setActive4(false);
            }}
            onMouseLeave={() => {
              setActive4(true);
            }}
          >
            {active4 ? (
              <img src={IMG4} alt="trusted by" />
            ) : (
              <img src={IMGB4} alt="trusted by" />
            )}
          </div>

          {/* img 5 */}
          <div
            className="img"
            onMouseEnter={() => {
              setActive5(false);
            }}
            onMouseLeave={() => {
              setActive5(true);
            }}
          >
            {active5 ? (
              <img src={IMG5} alt="trusted by" />
            ) : (
              <img src={IMGB5} alt="trusted by" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrustedBy;
