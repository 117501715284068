import React from "react";
import { useContext } from "react";
import { GlobalData } from "../../../../App";
import { SimpleButon } from "../../../buttons/MyButon";

function HowWeWorkContent({
  title,
  parag1,
  parag2,
  parag3,
  img,
  btnText,
  path,
  direction,
}) {
  const { setColorProcess } = useContext(GlobalData);

  return (
    <div className="inner-wrapper" style={{ flexDirection: direction }}>
      <div className="left-wrapper">
        <h2>{title}</h2>
        <p>{parag1}</p>
        <p>{parag2}</p>
        <p>{parag3}</p>
        <div
          className="btn-inner"
          onClick={() => {
            setColorProcess(false);
          }}
        >
          <SimpleButon btn={btnText} path={path} />
        </div>
      </div>

      <div className="right-wrapper">
        <img src={img} alt={title} />
      </div>
    </div>
  );
}

export default HowWeWorkContent;
