import React, { useState } from "react";
import ContactForm from "../../contact_page/form/ContactForm";
import PopUp from "../../contact_page/PopUp";

import "./serviceContact.scss";

function ServiceContact() {
  const [formActive, setFormActive] = useState(false);

  return (
    <div className="service-contact-container">
      <div className="upper-container">
        <h2>Ready to Bring Your Next Glorious Project to Life?</h2>
        <p>
          <span>Talk to us now! </span>
          <a href="tel:+923237403000">
            <span>+92 323 740 3000</span>
          </a>
        </p>
        <hr />
        <p>
          Thank you for considering HexamileSoft. To move forward with your
          request, please take a moment to fill out the form provided or send us
          an <a href="mailto:hello@hexamilesoft.com">email</a>. We will respond
          to you as soon as possible.
        </p>
      </div>

      <div className="form-contaner">
        <ContactForm setFormActive={setFormActive} />
      </div>

      <div className="popup-container">{formActive ? <PopUp /> : null}</div>
    </div>
  );
}

export default ServiceContact;
