import React from "react";
import ServiceContact from "../../contact/ServiceContact";

import "../../pages/servicesSpare.css";
import TrustPilot from "../../trust/TrustPilot";
import ServiceHead from "../../top_head/ServiceHead";
import InnerHeading from "../../headings/InnerHeading";
import UpperContent from "../../upper_content/UpperContent";
import TrustedBy from "../../trusted_by_imgs/TrustedBy";
import StartProject from "../../start_project/StartProject";
import HireEngineers from "../../hire_engineers/HireEngineers";
import EngagementModel from "../../engagement_models/EngagementModel";
import TechSupportClick from "./TechSupportClick";

// images
import MyImg from "../../../../assets/service/services/Technical Support.jpg";
import { useEffect } from "react";

function TechSupport() {
  const paragH1 =
    "As Technical Support Engineers, our duties include managing, maintaining, and fixing IT systems, troubleshooting faults and network problems, and setting up hardware and software. Our role is to offer technical assistance to clients on-site and remotely to resolve any issues from start to finish.";

  const paragCon1 =
    "Tech support is a vital component in the present digital economy, and its significance can not be overstated. It ensures smooth operations by maintaining the proper functioning of networks and systems, updating software and hardware as needed, and preventing technical difficulties from causing frustration to non-technical colleagues.";
  const paragCon2 =
    "At HexamileSoft, our tech support team is a dynamic group of highly skilled and knowledgeable individuals with varying roles and responsibilities, from CIOs and technical officers to entry-level IT assistants. These professionals work together to provide seamless support to clients and contribute to the overall success of the enterprise. Our tech support team's expertise and dedication to their work ensure that all technical issues are resolved promptly and efficiently, making it easier for everyone to carry out their daily tasks.";

  const startP =
    "No issue! Our qualified staff can take your original desktop mockup and realign all the pieces for you to ensure that your site appears great on tablets and smartphones. ";

  useEffect(() => {
    document.title = "Technical Support - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "Our support team ensures non-stop monitoring and technical assistance to resolve detected and reported problems in your system.";
    document.getElementsByTagName("META")[6].content =
      "Technical Support - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "Our support team ensures non-stop monitoring and technical assistance to resolve detected and reported problems in your system.";
    document.getElementsByTagName("META")[12].content =
      "Technical Support - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "Our support team ensures non-stop monitoring and technical assistance to resolve detected and reported problems in your system.";
  }, []);

  return (
    <div className="services-container">
      <div className="heading-container">
        <ServiceHead
          title={"Taking Ownership of Your Tech Problems"}
          parag={"Technical Support"}
        />
      </div>

      {/* InnerHeading */}
      <div className="title-heading-container">
        <div className="heading-wrapper">
          <InnerHeading
            titleH={"We Diagnose and Troubleshoot Your Problems"}
            paragH={paragH1}
          />
        </div>
        <hr />
        <div className="top-inner-container">
          <UpperContent
            imgCon={MyImg}
            paragCon1={paragCon1}
            paragCon2={paragCon2}
            paragCon3={""}
            btnPath={"#ts"}
          />
        </div>
      </div>

      {/* trusted by */}
      <div className="trusted-by-container">
        <TrustedBy />
      </div>

      {/* click projects  */}
      <div className="click-projects-container">
        <TechSupportClick />
      </div>

      {/* hire engineer  */}
      <div className="hire-engineer-container">
        <HireEngineers
          engrName={"Hire Tech Support Services"}
          engrPrice={"2200"}
          btnPath={"#ts"}
        />
        <EngagementModel />
      </div>

      {/* start your project  */}
      <div className="start-your-project-container">
        <StartProject
          titleProj={"No Response-Forward Designs?"}
          startP={startP}
          btnPath={"#ts"}
        />
      </div>

      {/* TrustPilot */}
      <div className="trust-pilot-wrap">
        <TrustPilot />
      </div>

      {/* ServiceContact */}
      <div className="service-contact-wrapper" id="ts">
        <ServiceContact />
      </div>
    </div>
  );
}

export default TechSupport;
