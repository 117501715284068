import React from "react";

function Testimonials({ pPhoto, parag, name, role, company, country }) {
  return (
    <div className="testimonial">
      <div className="img">
        <img src={pPhoto} alt={name} />
      </div>
      <p>
        <em>{parag}</em>
      </p>
      <h3>{name}</h3>
      <div className="info">
        <span>{role}, </span>
        <span>{company}| </span>
        <span>{country}</span>
      </div>
    </div>
  );
}

export default Testimonials;
