import React, { useState } from "react";
import { Link } from "react-router-dom";

import P1 from "../../assets/home/projects/list/project1/p1.png";
import SingleProject from "./SingleProject";

// CSS
import "./myProject.scss";
import { IconButton } from "../../buttons/MyButon";

function HondaProject() {
  const [img, setImg] = useState([
    { imgTitle: "Header", img: P1 },
    { imgTitle: "Footer", img: P1 },
  ]);

  return (
    <div className="my-project">
      <div className="project-upper-wrapper">
        <div className="img-wrapper">
          <h1>Honda Project</h1>
          <img src={P1} alt="head" />
        </div>
        <div className="project-middle-wrapper">
          <h2>Honda Project</h2>
          <p>UK’s #1 Aesthetic Treatment Now in Pakistan</p>
          <div className="inner-container">
            <p className="left-wrapper">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptates dolores incidunt sint minima quae ratione. Ullam natus
              nihil aperiam blanditiis eveniet distinctio itaque corporis,
              fugiat quasi vel dolorum quam, a non numquam ipsa dolor? Labore,
              suscipit nam repellat inventore, provident sequi quam ipsum
              deserunt maxime molestiae placeat sunt. Quod, aspernatur.
            </p>
            <div className="right-wrapper">
              <h2>Services</h2>
              <ul>
                <li>WordPress</li>
                <li>Marketplace Development</li>
                <li>UI / UX Design</li>
                <li>Responsive</li>
              </ul>
              <div className="btn">
                <Link>
                  <IconButton btn={"Visit Website"} path={"/home"} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {img.map((i, key) => {
        console.log();
        return (
          <div className="map-div" key={key}>
            <SingleProject title={i.imgTitle} projectP={i.img} />
          </div>
        );
      })}
    </div>
  );
}

export default HondaProject;
