import React, { createContext } from "react";
import { NewProjects } from "../home_page/MyProjects";

import { useState } from "react";
import { useEffect } from "react";

import Pro1 from "../../assets/work/web/project 1/1.png";
import Pro2 from "../../assets/work/web/project 2/1.png";
import Pro3 from "../../assets/work/web/project 3/1.png";
import Pro4 from "../../assets/work/web/project 4/1.png";
import Pro5 from "../../assets/work/web/project 5/1.jpg";

import CallRecH from "../../assets/work/mobile apps/call recorder/call recorder.jpg";
import EventReminderH from "../../assets/work/mobile apps/event reminder/Event Reminder.jpg";
import GiftSuggestH from "../../assets/work/mobile apps/gift suggestions/gift suggestion.jpg";
import ImgFinderH from "../../assets/work/mobile apps/image finder/image finder.jpg";
import MovieTrailerH from "../../assets/work/mobile apps/movie trailor/Movie Trailer.jpg";
import NotesAppH from "../../assets/work/mobile apps/notes app/notes.jpg";
import OlxAppH from "../../assets/work/mobile apps/olx app/online shopping.jpg";
import PorterYorkWeb from "./web_dev/porter_york/PorterYorkWeb";
import SparkleanCleaningWeb from "./web_dev/sparklean_cleaning/SparkleanCleaningWeb";
import YorkeConstructionWeb from "./web_dev/yorke_construction/YorkeConstructionWeb";
import ChefTableWeb from "./web_dev/chef_table/ChefTableWeb";
import XCPowerWeb from "./web_dev/xc_power/XCPowerWeb";
import CallRecorderAppA from "./mobile_app/call_recorder/CallRecorderAppA";
import EventReminder from "./mobile_app/event_reminder/EventReminder";
import GiftSuggestionAppA from "./mobile_app/gift_suggestion/GiftSuggestionAppA";
import ImageFinderAppA from "./mobile_app/image_finder/ImageFinderAppA";
import MoviesTrailerAppA from "./mobile_app/movies_trailer/MoviesTrailerAppA";
import NotesAppA from "./mobile_app/notes_app/NotesAppA";
import ShoppingAppA from "./mobile_app/shopping_app/ShoppingAppA";

export const WorkMenuData = createContext();

function OurWork() {
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);
  const [active7, setActive7] = useState(false);
  const [active8, setActive8] = useState(false);
  const [active9, setActive9] = useState(false);
  const [active10, setActive10] = useState(false);
  const [active11, setActive11] = useState(false);
  const [active12, setActive12] = useState(false);

  useEffect(() => {
    document.title = "Our Work - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "HexamileSoft deals in Game, Web, and Mobile Application Development, Graphics, Product Photography, SEO Optimization, Content Writing, and Social Media Management.";
    document.getElementsByTagName("META")[6].content =
      "Our Work - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "HexamileSoft deals in Game, Web, and Mobile Application Development, Graphics, Product Photography, SEO Optimization, Content Writing, and Social Media Management.";
    document.getElementsByTagName("META")[12].content =
      "Our Work - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "HexamileSoft deals in Game, Web, and Mobile Application Development, Graphics, Product Photography, SEO Optimization, Content Writing, and Social Media Management.";
  }, []);

  return (
    <WorkMenuData.Provider
      value={{
        active1: active1,
        setActive1: setActive1,

        active2: active2,
        setActive2: setActive2,

        active3: active3,
        setActive3: setActive3,

        active4: active4,
        setActive4: setActive4,

        active5: active5,
        setActive5: setActive5,

        active6: active6,
        setActive6: setActive6,

        active7: active7,
        setActive7: setActive7,

        active8: active8,
        setActive8: setActive8,

        active9: active9,
        setActive9: setActive9,

        active10: active10,
        setActive10: setActive10,

        active11: active11,
        setActive11: setActive11,

        active12: active12,
        setActive12: setActive12,
      }}
    >
      <div className="our-work-container">
        <div className="projects-wrapper">
          <div className="Heading-Container">
            <h1>Our Portfolio</h1>
            <p>
              As we strive to enhance our team's capabilities and increase
              customer satisfaction, we always improve our services, including
              our development methodologies, engineering practices, management
              techniques, and quality assurance standards.
            </p>
          </div>
          <hr />
          <div className="inner-wrapper">
            <NewProjects
              project={Pro1}
              name="Porter And York"
              type="Web"
              setActive={setActive1}
            />
            <NewProjects
              project={Pro2}
              name="Sparklean Cleaning Services"
              type="Web"
              setActive={setActive2}
            />
            <NewProjects
              project={Pro3}
              name="Yorke Construction Corporation"
              type="Web"
              setActive={setActive3}
            />
            <NewProjects
              project={Pro4}
              name="Chef's Table"
              type="Web"
              setActive={setActive4}
            />
            <NewProjects
              project={Pro5}
              name="XC Power"
              type="Web"
              setActive={setActive5}
            />
            <NewProjects
              project={CallRecH}
              name="Call Recorder App"
              type="Mobile App"
              setActive={setActive6}
            />
            <NewProjects
              project={EventReminderH}
              name="Event Reminder"
              type="Mobile App"
              setActive={setActive7}
            />
            <NewProjects
              project={GiftSuggestH}
              name="GIFT Suggestion"
              type="Mobile App"
              setActive={setActive8}
            />
            <NewProjects
              project={ImgFinderH}
              name="Image Finder"
              type="Mobile App"
              setActive={setActive9}
            />
            <NewProjects
              project={MovieTrailerH}
              name="Movies Trailer"
              type="Mobile App"
              setActive={setActive10}
            />
            <NewProjects
              project={NotesAppH}
              name="Notes App"
              type="Mobile App"
              setActive={setActive11}
            />
            <NewProjects
              project={OlxAppH}
              name="Online Shopping App"
              type="Mobile App"
              setActive={setActive12}
            />
          </div>
        </div>

        {active1 && <PorterYorkWeb />}
        {active2 && <SparkleanCleaningWeb />}
        {active3 && <YorkeConstructionWeb />}
        {active4 && <ChefTableWeb />}
        {active5 && <XCPowerWeb />}
        {active6 && <CallRecorderAppA />}
        {active7 && <EventReminder />}
        {active8 && <GiftSuggestionAppA />}
        {active9 && <ImageFinderAppA />}
        {active10 && <MoviesTrailerAppA />}
        {active11 && <NotesAppA />}
        {active12 && <ShoppingAppA />}
      </div>
    </WorkMenuData.Provider>
  );
}

export default OurWork;
