import React from "react";

import PopUpIcon from "../../assets/pop.png";
import { SimpleButon } from "../../buttons/MyButon";

function PopUp() {
  return (
    <div className="popup">
      <div className="pop">
        <img src={PopUpIcon} alt="icon" />
        <h2>We Have Recieved Your Request</h2>
        <p>We will soon get in touch with you.</p>
        <div
          className="btn"
          onClick={() => {
            document.body.style.overflow = "auto";
          }}
        >
          <SimpleButon btn={"Go To Home"} path={"/"} />
        </div>
      </div>
    </div>
  );
}

export default PopUp;
