import React from "react";

function Culture() {
  return (
    <div className="inner-wrapper">
      <h2>Team Culture</h2>
      <p>
        At HexamileSoft, we are a family-like team, i.e., we look after each
        other, share our burdens, and take responsibility. We are family-like,
        not family, because we do not pull each other down like those few toxic
        members in your families at home.
      </p>
      <p>So, what to expect from working with a family-like team?</p>
      <p>
        First and foremost, be bold and test new theories and ideas. They are a
        great way to learn new things even when they fail. It is okay to make
        mistakes, but it is not okay to not learn from them and continue
        repeating them.
      </p>
      <p>
        Second, expect your team members to question your ideas because that's
        what team members do. Their unique thought process helps you refine the
        idea and get another viewpoint because no two people think alike. By
        entertaining those questions from your fellows, you can reach the best
        plan more quickly.
      </p>
      <p>
        Lastly, we are committed to achieving greatness but admit that we are
        not there yet. Big goals and bold ideas fuel our company. We are
        constantly adapting to the fast-paced changes in our field. As we
        experience rapid growth, we understand that missteps and obstacles may
        occur. However, we see these challenges as growth, learning, and
        exciting opportunities. We appreciate your patience and support as we
        navigate these challenges and strive to reach our full potential.
      </p>
    </div>
  );
}

export default Culture;
