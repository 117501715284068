import React from "react";
import ServiceContact from "../../contact/ServiceContact";
import EngagementModel from "../../engagement_models/EngagementModel";
import InnerHeading from "../../headings/InnerHeading";
import HireEngineers from "../../hire_engineers/HireEngineers";
import StartProject from "../../start_project/StartProject";
import ServiceHead from "../../top_head/ServiceHead";
import TrustPilot from "../../trust/TrustPilot";
import TrustedBy from "../../trusted_by_imgs/TrustedBy";
import UpperContent from "../../upper_content/UpperContent";
import MobileProjectsClick from "./MobileProjectsClick";

// image
import MA from "../../../../assets/service/services/mobile app development.jpg";
import { useEffect } from "react";

function MobileAppDev() {
  const paragH1 =
    "Unleashing More than an App - Crafting Experiences, Fueling Businesses, Bringing Dreams to Life. With Passion, Discernment, and Determination, We Deliver Exceptional Products, Unhindered by B.S.";

  const paragCon1 =
    "Looking for a budget-friendly mobile app development partner that delivers top-notch quality? That's HexamileSoft for you.";

  const paragCon2 =
    "At HexamileSoft, we design and develop iOS and Android apps for businesses of all sizes, from Fortune 500 companies to startups. We thrive on helping startups become household names and corporations launch new products. We emphasize simplicity, user-friendly design, and compliance with usability standards. Our clients often choose us for our innovative ideas and ability to turn those ideas into cutting-edge mobile apps.";

  useEffect(() => {
    document.title = "Mobile App Development - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "Looking for a budget-friendly Mobile App Development partner that delivers top-notch quality? That's HexaMileSoft for you. Get your ideal iOS, Android, and Windows apps built efficiently.";
    document.getElementsByTagName("META")[6].content =
      "Mobile App Development - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "Looking for a budget-friendly Mobile App Development partner that delivers top-notch quality? That's HexaMileSoft for you. Get your ideal iOS, Android, and Windows apps built efficiently.";
    document.getElementsByTagName("META")[12].content =
      "Mobile App Development - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "Looking for a budget-friendly Mobile App Development partner that delivers top-notch quality? That's HexaMileSoft for you. Get your ideal iOS, Android, and Windows apps built efficiently.";
  }, []);

  return (
    <div className="services-container">
      <div className="heading-container">
        <ServiceHead
          title={"Apps That People Love"}
          parag={"Bringing Ideas to Life "}
        />
      </div>

      {/* InnerHeading */}
      <div className="title-heading-container">
        <div className="heading-wrapper">
          <InnerHeading
            titleH={
              "Elegant and Functional Mobile Apps For IOS, Android, and Windows"
            }
            paragH={paragH1}
          />
        </div>
        <hr />
        <div className="top-inner-container">
          <UpperContent
            imgCon={MA}
            paragCon1={paragCon1}
            paragCon2={paragCon2}
            paragCon3={""}
            btnPath={"#mad"}
          />
        </div>
      </div>

      {/* trusted by */}
      <div className="trusted-by-container">
        <TrustedBy />
      </div>

      {/* click projects  */}
      <div className="click-projects-container">
        <MobileProjectsClick />
      </div>

      {/* hire engineer  */}
      <div className="hire-engineer-container">
        <HireEngineers
          engrName={"Hire Mobile App Developer"}
          engrPrice={"2200"}
          btnPath={"#mad"}
        />
        <EngagementModel />
      </div>

      {/* start your project  */}
      <div className="start-your-project-container">
        <StartProject
          titleProj={"INTERESTED IN CREATING SOMETHING AWESOME WITH US?"}
          btnPath={"#mad"}
        />
      </div>

      {/* TrustPilot */}
      <div className="trust-pilot-wrap">
        <TrustPilot />
      </div>

      {/* ServiceContact */}
      <div className="service-contact-wrapper" id="mad">
        <ServiceContact />
      </div>
    </div>
  );
}

export default MobileAppDev;
