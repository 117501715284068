import React from "react";

import ReCAPTCHA from "react-google-recaptcha";

function ContactForm({ setFormActive }) {
  function onChange(value) {
    // console.log("Captcha value:", value);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    formData.append("request_type", "contact_us");

    const captchaValue = document.querySelector(".g-recaptcha-response").value;

    if (!captchaValue) {
      console.log("reCAPTCHA not filled");
      return;
    }
    const values = formData.values();

    for (const value of values) {
      console.log(value);
    }

    const apiUrl = "http://hexamilesoft.com/contact/contactUs.php";
    fetch(apiUrl, {
      method: "POST",
      body: formData,
    }).then((response) => {
      console.log(response.data);
      if (response.ok) {
        console.log("Form submitted successfully");
        document.body.style.overflow = "hidden";
        setFormActive(true);
      } else {
        console.log("Error submitting form");
      }
    });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="first">
          <input
            type="text"
            placeholder="Full Name"
            name="name"
            required
            className="name"
          />
          <input
            type="email"
            placeholder="Email Address"
            name="email"
            required
            className="email"
          />
        </div>
        <div className="second">
          <input
            type="tel"
            placeholder="Phone Number"
            name="number"
            required
            className="phone"
            size="10"
            minLength="8"
            maxLength="15"
          />
          <input
            type="text"
            placeholder="Skype ID"
            name="skype_id"
            className="skype"
          />
        </div>

        <textarea
          placeholder="Tell us something about your project"
          className="msg"
          name="message"
        />

        <ReCAPTCHA
          sitekey="6LcCku4SAAAAAE4H4Jd_zttJ5jX1vUq2e3D-5nWb"
          onChange={onChange}
        />

        <div className="btn">
          <button type="submit" id="submit" className="btn-submit">
            Submit
          </button>
        </div>
      </form>
    </>
  );
}

export default ContactForm;
