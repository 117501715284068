import React, { useState } from "react";
import LineIcon from "../../assets/Frame_30.png";
import CrossIcon from "../../assets/lines.png";

import MyMenu from "./MyMenu";

import LogoMenu from "./LogoMenu";

function MyHeader() {
  const [activeM, setActiveM] = useState(false);

  return (
    <>
      <header className="header-container">
        <LogoMenu />

        <div
          className="blur-section"
          style={{
            zIndex: activeM ? "2" : "-10",
            backgroundColor: activeM ? "rgba(0, 0, 0, 0.1)" : null,
          }}
          onClick={() => {
            setActiveM(false);
          }}
        ></div>

        {/* mobile menu  */}
        <div className="mobile-menu">
          <div className="mobile-wrapper">
            <div className="mobile-icon">
              {activeM ? (
                <div className="side-menu">
                  <img
                    src={LineIcon}
                    alt="menu"
                    className="cross"
                    onClick={() => {
                      setActiveM(false);
                      console.log(`cross $true`);
                    }}
                  />
                  <MyMenu setActiveM={setActiveM} />
                </div>
              ) : (
                <img
                  src={CrossIcon}
                  alt="menu"
                  onClick={() => {
                    setActiveM(true);
                    console.log(`cross $false`);
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div className="mobile-menu">
          <div className="mobile-wrapper">
            <div className="mobile-icon">
              {activeM ? (
                <div className="side-menu">
                  <img
                    src={LineIcon}
                    alt="menu"
                    className="cross"
                    onClick={() => {
                      setActiveM(false);
                      console.log(`cross $true`);
                    }}
                  />
                  <MyMenu setActiveM={setActiveM} />
                </div>
              ) : (
                <img
                  src={CrossIcon}
                  alt="menu"
                  onClick={() => {
                    setActiveM(true);
                    console.log(`cross $false`);
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <MyMenu setActiveM={setActiveM} />
      </header>
    </>
  );
}

export default MyHeader;
