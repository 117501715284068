import React from "react";

function Owners({ fImg, fName, fPosition }) {
  return (
    <div className="owners-wrapper">
      <div className="img">
        <img src={fImg} alt="founder" />
      </div>
      <div className="title">
        <h2>{fName}</h2>
        <p>{fPosition}</p>
      </div>
    </div>
  );
}

export default Owners;
