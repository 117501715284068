import React from "react";

import FastWork from "../../assets/home/logo_section/Fast Work.png";
import CPak from "../../assets/home/logo_section/CPak.png";
import OZone from "../../assets/home/logo_section/Objects Zone.png";
import HexaSoft from "../../assets/home/logo_section/HexamileSoft.png";

function LogoSection() {
  return (
    <div className="logo-section-container">
      <div className="heading-logo">
        <h2>Our Partners and Associations</h2>
      </div>

      <div className="logo-wrapper">
        <a
          href="https://www.fastest.work"
          target={"_blank"}
          rel="noreferrer"
          className="my-logo"
        >
          <img src={FastWork} alt="fast work" />
        </a>
        <a
          href="https://www.challenge.pk/"
          target={"_blank"}
          rel="noreferrer"
          className="my-logo"
        >
          <img src={CPak} alt="challenge pakistan" />
        </a>
        <a
          href="https://www.objectszone.com"
          target={"_blank"}
          rel="noreferrer"
          className="my-logo"
        >
          <img src={OZone} alt="object zone" />
        </a>
        <a
          href="https://www.hexamilesoft.com/"
          target={"_blank"}
          rel="noreferrer"
          className="my-logo"
        >
          <img src={HexaSoft} alt="Hexamile Soft" />
        </a>
      </div>
    </div>
  );
}

export default LogoSection;
