import React from "react";

function CookiePolicy() {
  return (
    <div className="cookie-policy-container">
      <div className="policy-wrapper">
        <h1>Cookie Policy for HexamileSoft</h1>
        <p>
          This is the Cookie Policy for HexamileSoft, accessible from{" "}
          <a href="https://hexamileSoft.com">hexamileSoft</a>
        </p>

        <div className="inner-wrapper">
          <h2>What Are Cookies</h2>
          <p>
            As is common practice with almost all professional websites this
            site uses cookies, which are tiny files that are downloaded to your
            computer, to improve your experience. This page describes what
            information they gather, how we use it and why we sometimes need to
            store these cookies. We will also share how you can prevent these
            cookies from being stored however this may downgrade or 'break'
            certain elements of the sites functionality.
            <br />
            For more general information on cookies see the Wikipedia article on
            HTTP Cookies.
          </p>
        </div>

        <div className="inner-wrapper">
          <h2>How We Use Cookies</h2>
          <p>
            We use cookies for a variety of reasons detailed below.
            Unfortunately in most cases there are no industry standard options
            for disabling cookies without completely disabling the functionality
            and features they add to this site. It is recommended that you leave
            on all cookies if you are not sure whether you need them or not in
            case they are used to provide a service that you use.
          </p>
        </div>
        <div className="inner-wrapper">
          <h2>Disabling Cookies</h2>
          <p>
            You can prevent the setting of cookies by adjusting the settings on
            your browser (see your browser Help for how to do this). Be aware
            that disabling cookies will affect the functionality of this and
            many other websites that you visit. Disabling cookies will usually
            result in also disabling certain functionality and features of the
            this site. Therefore it is recommended that you do not disable
            cookies.
          </p>
        </div>

        <div className="inner-wrapper">
          <h2>Email newsletters related cookies</h2>
          <p>
            This site offers newsletter or email subscription services and
            cookies may be used to remember if you are already registered and
            whether to show certain notifications which might only be valid to
            subscribed/unsubscribed users.
          </p>
        </div>

        <div className="inner-wrapper">
          <h2>Orders processing related cookies</h2>
          <p>
            This site offers e-commerce or payment facilities and some cookies
            are essential to ensure that your order is remembered between pages
            so that we can process it properly.
          </p>
        </div>

        <div className="inner-wrapper">
          <h2>Third Party Cookies</h2>
          <p>
            In some special cases we also use cookies provided by trusted third
            parties. The following section details which third party cookies you
            might encounter through this site.
            <br />
            This site uses Google Analytics which is one of the most widespread
            and trusted analytics solution on the web for helping us to
            understand how you use the site and ways that we can improve your
            experience. These cookies may track things such as how long you
            spend on the site and the pages that you visit so we can continue to
            produce engaging content.
            <br />
            For more information on Google Analytics cookies, see the official
            Google Analytics page.
          </p>
        </div>

        <div className="inner-wrapper">
          <h2>More Information</h2>
          <p>
            Hopefully that has clarified things for you and as was previously
            mentioned if there is something that you aren’t sure whether you
            need or not it’s usually safer to leave cookies enabled in case it
            does interact with one of the features you use on our site.
            <br />
            However if you are still looking for more information then you can
            contact us through one of our preferred contact methods:
          </p>
          <p>
            By visiting this link:{" "}
            <a href="https://hexamileSoft.com/">HexamileSoft</a>
          </p>
        </div>

        <div className="inner-wrapper">
          <h2>Data Remover</h2>
          <p>
            If you have to have your data removed please open a ticket at{" "}
            <a href="https://hexamileSoft.com/">HexamileSoft</a>
          </p>
          <p>
            We will take care of your request asap.
            <br />
            We no longer use an email address because it always gets spammed and
            therefore some of the emails you send get lost. So please use the
            ticket system.
            <br />
            Thank you.
          </p>
        </div>
      </div>
    </div>
  );
}

export default CookiePolicy;
