import React from "react";
import { useState } from "react";

import "../allClickProjects.scss";

import { GiAbstract005 } from "react-icons/gi";

import { BiCaretRight } from "react-icons/bi";

function ClickProjects() {
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);

  const activeHandler = (a1 = true, a2 = false, a3 = false, a4 = false) => {
    setActive1(a1);
    setActive2(a2);
    setActive3(a3);
    setActive4(a4);
  };

  return (
    <div className="inner-project-wrapper">
      <div className="heading">
        <h2>HexamileSoft- A Startup Development Company </h2>
        <p>
          We understand your vision and work with you to make it a reality. Our
          focus on building a strong relationship and our understanding of
          startup challenges make us the ideal choice for your success.
        </p>
        <hr />
      </div>

      <div className="select-projects-container">
        <div className="left-side">
          {/* Your Technical Co-Founder */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler();
            }}
            style={{ backgroundColor: active1 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Your Technical Co-Founder</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Startup Development */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, true, false, false);
            }}
            style={{ backgroundColor: active2 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Startup Development</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Prototyping and Development */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, true, false);
            }}
            style={{ backgroundColor: active3 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Prototyping and Development</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Deployment, Testing, and Support */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, false, true);
            }}
            style={{ backgroundColor: active4 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Deployment, Testing, and Support</span>
            </div>
            <BiCaretRight />
          </div>
        </div>

        {/* content  */}
        <div className="right-side">
          {/* Your Technical Co-Founder */}
          {active1 && (
            <div className="mydiv">
              <h3>Product Development & Technical Co-founder Role</h3>
              <p>
                At HexamileSoft, we offer product development services as
                technical co-founders for smart startup companies. <br />
                These are the services we design to help you:
              </p>
              <ul>
                <li>Build your product at a low cost. </li>
                <li>Access a team with startup expertise. </li>
                <li>Bring your product to market faster. </li>
                <li>Get access to a team with the right technical skills. </li>
                <li>Achieve phenomenal return with minimal risk. </li>
              </ul>
              <p className="c">
                By choosing to work with us, you'll have a partner that
                understands the startup journey and is dedicated to helping you
                succeed.
              </p>
            </div>
          )}

          {/* Startup Development */}
          {active2 && (
            <div className="mydiv">
              <h3> We handle every stage of starting a business.</h3>
              <p>
                At HexamileSoft, we offer a complete startup development
                solution, covering all steps of product development, such as:
              </p>
              <ul>
                <li>
                  Technical analysis of ideas and assessment of product
                  feasibility
                </li>
                <li>
                  Professional guidance in ideation and product development
                </li>
                <li>
                  Alignment of product strategy with business goals, including
                  feature prioritization, roadmap development, and
                  implementation approach
                </li>
              </ul>
            </div>
          )}

          {/* Prototyping and Development */}
          {active3 && (
            <div className="mydiv">
              <h3>Prototypes and Designs</h3>
              <p>
                At HexamileSoft, our startup development services include the
                following:
              </p>
              <ul>
                <li>
                  Rapid development of a "proof of concept" to gain early
                  feedback and demonstrate to investors
                </li>
                <li>
                  Design and development of a Minimum Viable Product (MVP)
                </li>
                <li>
                  Iterative development, testing, and deployment of the product:
                  including UX design, engineering, cloud hosting, and quality
                  assurance
                </li>
              </ul>
              <p className="c">
                Our goal is to help you bring your product to market as quickly
                and efficiently as possible while ensuring that it meets the
                highest quality and user experience standards.
              </p>
            </div>
          )}

          {/* Deployment, Testing, and Support */}
          {active4 && (
            <div className="mydiv">
              <h3>Deployment, Testing, and Support</h3>
              <p>
                Our startup development services also include the following:
              </p>
              <ul>
                <li>Cloud deployment and management</li>
                <li>Usability testing</li>
                <li>Customer technical support services</li>
                <li>Continuous product enhancement and maintenance</li>
              </ul>

              <p className="c">
                We strive to provide an end-to-end solution for startups, from
                initial concept to ongoing support and maintenance. Our goal is
                to help your startup succeed by providing the necessary tools
                and resources for your product to thrive in the market.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClickProjects;
