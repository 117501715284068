import React from "react";

import PY1 from "../../../../assets/work/web/project 4/1.png";

import { RxCross1 } from "react-icons/rx";
import { useContext } from "react";
import { WorkMenuData } from "../../OurWork";

function ChefTableWeb() {
  const { setActive4 } = useContext(WorkMenuData);

  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActive4(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActive4(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>
          <div className="top-pop">
            <h2>Chef Table Website</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Web Design</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>Benjamin</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                Chef's Table is a culinary-focused website that celebrates the
                artistry and creativity of renowned chefs worldwide. With a
                mission to showcase the stories, techniques, and unique
                perspectives of these culinary masters, Chef's Table offers an
                immersive and inspiring experience for food enthusiasts.
              </strong>
            </p>
            <p>
              The Chef's Table website is a hub for various culinary content,
              including articles, recipes, videos, and interviews. It provides a
              platform for chefs to share their expertise, culinary
              philosophies, and the inspirations behind their innovative dishes.
              Through captivating storytelling and stunning visuals, the website
              offers a glimpse into the fascinating world of these culinary
              visionaries. One of the standout features of the Chef's Table
              website is its extensive collection of chef profiles. These
              profiles delve deep into the lives and careers of renowned chefs,
              exploring their journeys, culinary influences, and signature
              dishes. Users can discover the stories behind the chefs, gaining
              insights into their creative processes and the challenges they've
              overcome to achieve success.
            </p>
            <p>
              The website also offers various recipes from acclaimed chefs,
              allowing users to recreate their favorite dishes in their
              kitchens. In addition to chef profiles and recipes, Chef's Table
              provides thought-provoking articles and features that delve into
              various aspects of the culinary world. The Chef's Table website
              also incorporates a visually stunning design that reflects the
              beauty and creativity of the culinary world. High-quality images
              and videos capture the essence of each dish and the unique
              environments in which they are created. The intuitive user
              interface makes it easy for visitors to navigate the website,
              discover new content, and engage with the culinary community. The
              Chef's Table website is a captivating destination for food lovers,
              providing culinary content, chef profiles, recipes, and
              thought-provoking articles.
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChefTableWeb;

export function HomeChefTableWeb({ setActiveP4 }) {
  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActiveP4(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActiveP4(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>

          <div className="top-pop">
            <h2>Chef Table Website</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Web Design</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>Benjamin</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                Chef's Table is a culinary-focused website that celebrates the
                artistry and creativity of renowned chefs worldwide. With a
                mission to showcase the stories, techniques, and unique
                perspectives of these culinary masters, Chef's Table offers an
                immersive and inspiring experience for food enthusiasts.
              </strong>
            </p>
            <p>
              The Chef's Table website is a hub for various culinary content,
              including articles, recipes, videos, and interviews. It provides a
              platform for chefs to share their expertise, culinary
              philosophies, and the inspirations behind their innovative dishes.
              Through captivating storytelling and stunning visuals, the website
              offers a glimpse into the fascinating world of these culinary
              visionaries. One of the standout features of the Chef's Table
              website is its extensive collection of chef profiles. These
              profiles delve deep into the lives and careers of renowned chefs,
              exploring their journeys, culinary influences, and signature
              dishes. Users can discover the stories behind the chefs, gaining
              insights into their creative processes and the challenges they've
              overcome to achieve success.
            </p>
            <p>
              The website also offers various recipes from acclaimed chefs,
              allowing users to recreate their favorite dishes in their
              kitchens. In addition to chef profiles and recipes, Chef's Table
              provides thought-provoking articles and features that delve into
              various aspects of the culinary world. The Chef's Table website
              also incorporates a visually stunning design that reflects the
              beauty and creativity of the culinary world. High-quality images
              and videos capture the essence of each dish and the unique
              environments in which they are created. The intuitive user
              interface makes it easy for visitors to navigate the website,
              discover new content, and engage with the culinary community. The
              Chef's Table website is a captivating destination for food lovers,
              providing culinary content, chef profiles, recipes, and
              thought-provoking articles.
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
