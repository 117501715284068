import React from "react";
import { useEffect } from "react";
import MobileAppDeveloper from "./mobile_app_dev/MobileAppDeveloper";
import CPHPDev from "./php_dev/CPHPDev";
import ProjectManager from "./project_manager/ProjectManager";
import QAEngineer from "./qa_engineer/QAEngineer";

function JobOpening() {
  useEffect(() => {
    document.title = "Job Opening - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "We always seek talented and experienced individuals to join our awesome team. Check our recent job openings.";
    document.getElementsByTagName("META")[6].content =
      "Job Opening - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "We always seek talented and experienced individuals to join our awesome team. Check our recent job openings.";
    document.getElementsByTagName("META")[12].content =
      "Job Opening - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "We always seek talented and experienced individuals to join our awesome team. Check our recent job openings.";
  }, []);

  return (
    <div className="job-opening-container">
      <div className="job-opening-head-container">
        <h1>Job Openings</h1>
        <p>Join our Fabulous Team of Intelligent Individuals</p>
      </div>

      <div className="apply-job-form">
        <p>There are no vacancies available at this time.</p>
      </div>

      {/* project manager  */}
      {/* <div className="my-container">
        <ProjectManager />
      </div> */}

      {/* Mobile App Developer */}
      {/* <div className="my-container">
        <MobileAppDeveloper />
      </div> */}

      {/* Senior PHP Developer */}
      {/* <div className="my-container">
        <CPHPDev />
      </div> */}

      {/* Senior QA Engineer */}
      {/* <div className="my-container">
        <QAEngineer />
      </div> */}
    </div>
  );
}

export default JobOpening;
