import React from "react";
import { SimpleButon, SimpleButonId } from "../../../../buttons/MyButon";

import "./ecomPrice.scss";

function EcomPrice({ btnPath }) {
  return (
    <div className="dev-price-container">
      <div className="price-head">
        <h2>AFFORDABLE ECOMMERCE PACKAGES</h2>
        <p>Flexible & Easy Pricing for wide Audience Groups</p>
        <hr />
      </div>
      <div className="all-prices-wrapper">
        <div className="outer-price-block">
          <div className="price-block">
            <div className="price-title">
              <h3>STARTER</h3>
            </div>
            <div className="price-p">
              <strong>
                <span>$</span>999
              </strong>
            </div>
            <hr />
            <ul>
              <li>Ready Theme Customized</li>
              <li>
                Design Process: Client provides reference URLs, RED SIGNAL team
                researches the matching themes available and sends 2-4
                suggestions to the clients for approval. Approved theme is
                implemented with minor modifications suggested by client
              </li>
              <li>
                Responsive: Our ecommerce designs are tailored to fit across
                every device.
              </li>
              <li>
                Custom Features: Default features available in the selected
                Ecommerce (Woocommerce, Magento)
              </li>
              <li>
                Integration with Google Analytics - know your traffic sources
                and customer behaviour
              </li>
              <li>
                Payments with Amazon Payments, PayPal, Authorize.net, and Google
                Checkout
              </li>
              <li>Maintenance: 3 months free support for any bug fixes</li>
              <li>Timeline: 5-7 Days</li>
              <li>Help Documents & User Guides</li>
              <li>Phone & Email Support</li>
            </ul>
            <div className="price-btn">
              <SimpleButonId btn={"Let's Talk"} path={btnPath} />
            </div>
          </div>
        </div>

        <div className="outer-price-block">
          <div className="price-block my-center">
            <div className="price-title">
              <h3>MID SIZE BUSINESS</h3>
            </div>
            <div className="price-p">
              <strong>
                <span>$</span>2499
              </strong>
            </div>
            <hr />
            <ul>
              <li>
                Custom Design based on client requirements and suggestions
              </li>
              <li>
                Design Process Client provides initial ideas and references. RED
                SIGNAL develops layouts after extensive research for 3/4 website
                pages like landing page, product listing and filters and product
                detail. Once approved, we start building your store.
              </li>
              <li>
                Custom Features Up to 80 hours of work included for multiple
                extensions installations to customize Products Module, Shipping,
                integration with Online email marketing solution like mailchip,
                integration with compatible inventory management, integration
                with ERP solutions etc.
              </li>
              <li>
                Integration with Google Analytics - know your traffic sources
                and customer behaviour
              </li>
              <li>
                Integration with Google Analytics - know your traffic sources
                and customer behaviour
              </li>
              <li>
                Payments with Amazon Payments, PayPal, Authorize.net, Google
                Checkout or any major payment Gateway
              </li>
              <li>
                SSL Security Support for All Online Order and Sensitive
                Transactions
              </li>
              <li>
                SEO Implementation of URLre-writing and metas to make it search
                friendly
              </li>
              <li>Maintenance: 3 months free support for any bug fixes</li>
              <li>Timeline 15-20 Days</li>
              <li>Help Documents & User Guides</li>
              <li>Phone & Email Support</li>
            </ul>
            <div className="price-btn">
              <SimpleButonId btn={"Let's Talk"} path={btnPath} />
            </div>
          </div>
        </div>

        <div className="outer-price-block">
          <div className="price-block">
            <div className="price-title">
              <h3>LARGE BUSINESS</h3>
            </div>
            <div className="price-p">
              <strong>
                <span>$</span>ask price
              </strong>
            </div>
            <hr />
            <ul>
              <li>
                Strategy: We design a winning and stable eCommerce strategy for
                your online success
              </li>
              <li>
                Design Process Client shares an initial idea. RED SIGNAL does
                extensive research and develops wireframes. Upon finalization of
                wireframes, RED SIGNAL develops design layouts utilizing its
                industry experience and latest design trends. Actual design is
                built around one of the approved wireframes
              </li>
              <li>
                Custom Features Tell us about your business processes and we
                come up with ideas to enrich your eCommerce experience by
                implementing features that improve your conversion. Advance
                Product rules, order management, customized solution for your
                shipping needs, multilingual implementation and much more
              </li>
              <li>
                Integration: Integration services for ecommerce solutions,
                including development of configurable integration modules that
                connect web frontend to the supply chain management process:
                Payment gateways, Corporate accounting systems, Logistics
                management systems, Warehouse management software, CRM systems
              </li>
              <li>
                Scalability: To handle sensitive data and transactions, our
                development approach includes software security consulting and
                OWASP and PCI DSS pre-certification service.
              </li>
              <li>
                Security & Performance: High scalability to support the growing
                number of visitors, user accounts, catalog records and
                transactions. RED SIGNAL has extensive experience in multi-tier
                application development and database modeling / optimization
                that yield excellent scalability results.
              </li>
              <li>Timeline 60-80 Days</li>
              <li>
                Maintenance: Dedicated support making sure website runs smooth
                at all times
              </li>
              <li>Help Documents & User Guides</li>
              <li>Phone & Email Support</li>
            </ul>
            <div className="price-btn">
              <SimpleButonId btn={"Let's Talk"} path={btnPath} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EcomPrice;
