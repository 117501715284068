import React from "react";
import { useEffect } from "react";

function LifeAtHexmile() {
  useEffect(() => {
    document.title = "Life At-HexamileSoft - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "Life at HexamileSoft is full of creative freedom, result driven, and rewarding. We value talent and hard work and show appreciation with bonuses, increments, and celebrations.";
    document.getElementsByTagName("META")[6].content =
      "Life At-HexamileSoft - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "Life at HexamileSoft is full of creative freedom, result driven, and rewarding. We value talent and hard work and show appreciation with bonuses, increments, and celebrations.";
    document.getElementsByTagName("META")[12].content =
      "Life At-HexamileSoft - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "Life at HexamileSoft is full of creative freedom, result driven, and rewarding. We value talent and hard work and show appreciation with bonuses, increments, and celebrations.";
  }, []);

  return (
    <div className="life-at-hexamile-container">
      <div className="hexa-head-container">
        <h1>Life At HexaMileSoft</h1>
        <p>
          A place is what people make it. At HexamileSoft, you're free to work
          how you like it, even if it means turning your workspace into a theme
          park. As long as you utilize the space assigned to you and don't take
          liberties with your co-workers' workspace, everything is acceptable
          for us if it helps you work more creatively.
        </p>
      </div>

      <div className="hexa-content-container">
        <h2>Welcome to the HexaMileSoft Family!</h2>
        <p>
          As an official member of the HexamileSoft family, be ready to face the
          ever-emerging fresh challenges, possibilities, and adventures. There
          are client meetings, deadlines, analyses, code after coding, and more.
          <br />
          We aim to "help them grow, no matter what it takes," and by "them," we
          mean our clients. As for the company's growth, we grow together with
          our team.
        </p>
        <p>
          Our work culture doesn't involve reporting to a chain of managers to
          confirm that you have completed your tasks. In most cases, we report
          directly to our clients while taking their real-time feedback and
          making changes to the product accordingly. Sometimes, however, we work
          under a project head or a supervisor who works as the team's voice and
          communicates with the client. In both cases, we don't follow the toxic
          traditional management rules of reporting to a supervisor, who reports
          to another manager, who then reports to the boss, before continuing
          with the next tasks.
        </p>
        <p>
          We believe in your abilities to handle situations maturely and always
          stand behind you to help in case you need our help. You are skilled
          enough to communicate with the clients while following up, assisting,
          educating, and finally making them satisfied with the project
          outcomes.
        </p>
        <p>
          We provide Core i7 machines to designers and programmers, while a ton
          of Android smartphones, iPhones, and iPad are used for QA. There are
          slim LEDs to watch movies in your free time, and most importantly, we
          keep our headphones and glasses private.
          <br />
          Team HexamileSoft wishes you the best for your journey ahead with the
          team.
        </p>
      </div>
    </div>
  );
}

export default LifeAtHexmile;
