import React from "react";
import { useState } from "react";

import "../allClickProjects.scss";

import { GiAbstract005 } from "react-icons/gi";

import { BiCaretRight } from "react-icons/bi";
import { SimpleButon } from "../../../../buttons/MyButon";

function SEOClick() {
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);

  const activeHandler = (
    a1 = true,
    a2 = false,
    a3 = false,
    a4 = false,
    a5 = false
  ) => {
    setActive1(a1);
    setActive2(a2);
    setActive3(a3);
    setActive4(a4);
    setActive5(a5);
  };

  return (
    <div className="inner-project-wrapper">
      <div className="heading">
        <h2>HexamileSoft- A Site Rank Company</h2>
        <p>
          Here are a few reasons why you should choose us to help you improve
          your website's search engine rankings, drive more organic traffic, and
          generate more leads and sales.
        </p>
        <hr />
      </div>

      <div className="select-projects-container">
        <div className="left-side">
          {/* Proven Results   */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler();
            }}
            style={{ backgroundColor: active1 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Proven Results </span>
            </div>
            <BiCaretRight />
          </div>

          {/* Customized Approach */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, true, false, false, false);
            }}
            style={{ backgroundColor: active2 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Customized Approach</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Transparent Reporting */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, true, false, false);
            }}
            style={{ backgroundColor: active3 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Transparent Reporting</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Expert Team */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, false, true, false);
            }}
            style={{ backgroundColor: active4 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Expert Team</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Affordable Prices  */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, false, false, true);
            }}
            style={{ backgroundColor: active5 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Affordable Prices </span>
            </div>
            <BiCaretRight />
          </div>
        </div>

        {/* content  */}
        <div className="right-side">
          {/* Proven Results  */}
          {active1 && (
            <div className="mydiv">
              <h3>Hundreds of Satisfied Clients</h3>
              <p>
                We believe in letting the record speak for itself. More than
                half of our clients are repeat hires, proving our credibility
                and efficiency. For five years, HexaMileSoft has helped many
                businesses achieve first-page rankings for their most important
                keywords, increasing their organic traffic and generating more
                leads and sales. We employ the latest SEO techniques and
                practices, including integrating quality keywords in the product
                copy, web copy, and blogs, and building backlinks, to achieve
                long-lasting results.
              </p>
            </div>
          )}

          {/* Customized Approach */}
          {active2 && (
            <div className="mydiv">
              <h3>Tailoring Our Services around Your Business Requirements</h3>
              <p>
                We know that every company is different and has its own set of
                difficulties and objectives. That's why we take a customized
                approach to optimizing your business, tailoring our services to
                meet each client's specific needs. Our tailor-made services
                include updating old content, filling content gaps, fixing
                broken links, getting more backlinks, optimizing internal links,
                and many more. We take whatever it takes to help our client's
                growth.
              </p>
            </div>
          )}

          {/* Transparent Reporting */}
          {active3 && (
            <div className="mydiv">
              <h3>Regular Monthly, Quarterly, and Annual Progress Reports </h3>
              <p>
                At HexamileSoft, we believe in transparency and accountability.
                A regular report helps our clients see our gradual progress and
                dedication to our job. That's why we provide regular, detailed
                reports on our progress, including rankings, traffic, and
                conversion data. You'll always be fully aware of what we're
                doing for your company and how it benefits from it.
              </p>
            </div>
          )}

          {/* Expert Team */}
          {active4 && (
            <div className="mydiv">
              <h3>Reliable and Experienced Team</h3>
              <p>
                Our SEO expert team has years of industry experience and stays
                up-to-date with the latest trends and developments. We are
                passionate about helping our clients succeed online, and we
                bring that passion to every project we work on.
              </p>
            </div>
          )}

          {/* Affordable Prices  */}
          {active5 && (
            <div className="mydiv">
              <h3>Convenient to Stay in Budget; SEO for Everyone</h3>
              <p>
                Our SEO services are priced competitively, making it easy for
                businesses of all sizes to get the help they need. Everyone
                should have access to top-quality SEO services, regardless of
                budget.
                <br />
                Please refer to our sample budgets for the SEO services based on
                the executive's expertise.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SEOClick;
