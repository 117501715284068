import React from "react";

import "../../pages/servicesSpare.css";

import TrustPilot from "../../trust/TrustPilot";
import ServiceContact from "../../contact/ServiceContact";
import ServiceHead from "../../top_head/ServiceHead";
import InnerHeading from "../../headings/InnerHeading";
import UpperContent from "../../upper_content/UpperContent";
import TrustedBy from "../../trusted_by_imgs/TrustedBy";
import StartProject from "../../start_project/StartProject";
import HireEngineers from "../../hire_engineers/HireEngineers";
import EngagementModel from "../../engagement_models/EngagementModel";
import DedicatedClick from "./DedicatedClick";

// images
import MyImg from "../../../../assets/service/services/Dedicated Team.jpg";
import { useEffect } from "react";

function DedicatedDevelopment() {
  const paragH1 =
    "By utilizing our Exclusive Resource Initiative, you will never have to concern yourself with expanding your team, recruiting, terminating, workspace, employee benefits and pensions, HR issues, and office equipment.";

  const paragCon1 =
    "Fifty percent of our clients return to us for additional services. We understand the concerns and uncertainties that customers may have before outsourcing their IT needs to a company. As a result, we provide risk-free guarantees, including data security, intellectual property protection, cost-effectiveness, and knowledge transfer.";
  const paragCon2 =
    "We cater to small and large businesses and individual clients for their technology needs, whether for specific stages of development or the entire Software Development Life Cycle (SDLC). By partnering with us, you can eliminate worries about expanding your team, recruiting, terminating, and managing office space and employee benefits.";
  const paragCon3 =
    "With our service, you can hire a tailored team of any size (either offshore, onsite, or hybrid, per your requirements) to create new applications and maintain or upgrade existing ones starting at $2200 per month.";

  const startP =
    "At HexamileSoft, we're all about startups! It's in our DNA. We love new ideas and bringing them to life, and you'll see that in our hands-on approach to your project. From concept to launch, we're your go-to partner for all things startup. Let's make your ideas a reality! ";

  useEffect(() => {
    document.title = "Dedicated Team - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "Our highly skilled team comprises Web, Mobile, and SaaS developers, SEO executives, IT consultants, and Technical assistants.";
    document.getElementsByTagName("META")[6].content =
      "Dedicated Team - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "Our highly skilled team comprises Web, Mobile, and SaaS developers, SEO executives, IT consultants, and Technical assistants.";
    document.getElementsByTagName("META")[12].content =
      "Dedicated Team - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "Our highly skilled team comprises Web, Mobile, and SaaS developers, SEO executives, IT consultants, and Technical assistants.";
  }, []);

  return (
    <div className="services-container">
      <div className="heading-container">
        <ServiceHead
          title={"Optimize Speed, Quality, and Economy; All Three Are with Us!"}
          parag={"Looking to Reduce Expenses or Expand Your Resources Quickly?"}
        />
      </div>

      {/* InnerHeading */}
      <div className="title-heading-container">
        <div className="heading-wrapper">
          <InnerHeading
            titleH={
              "Professional, Committed Programmers You Can Rely on for Your Upcoming Project"
            }
            paragH={paragH1}
          />
        </div>
        <hr />
        <div className="top-inner-container">
          <UpperContent
            imgCon={MyImg}
            paragCon1={paragCon1}
            paragCon2={paragCon2}
            paragCon3={paragCon3}
            btnPath={"#d-contact"}
          />
        </div>
      </div>

      {/* trusted by */}
      <div className="trusted-by-container">
        <TrustedBy />
      </div>

      {/* click projects  */}
      <div className="click-projects-container">
        <DedicatedClick />
      </div>

      {/* hire engineer  */}
      <div className="hire-engineer-container">
        <HireEngineers
          engrName={"Hire Software Engineers"}
          engrPrice={"2200"}
          btnPath={"#d-contact"}
        />
        <EngagementModel />
      </div>

      {/* start your project  */}
      <div className="start-your-project-container">
        <StartProject
          titleProj={"Interested in Creating Something Awesome With Us?"}
          startP={startP}
          btnPath={"#d-contact"}
        />
      </div>

      {/* TrustPilot */}
      <div className="trust-pilot-wrap">
        <TrustPilot />
      </div>

      {/* ServiceContact */}
      <div className="service-contact-wrapper" id="d-contact">
        <ServiceContact />
      </div>
    </div>
  );
}

export default DedicatedDevelopment;
