import React from "react";
import { Link } from "react-router-dom";

function ContactLinks({ title, parag, path, linkText }) {
  return (
    <div className="inner">
      <h2>{title}</h2>
      <p>{parag}</p>
      <Link to={path}>{linkText}</Link>
    </div>
  );
}

export default ContactLinks;

export function ContactLinks1({ title, parag, path, linkText }) {
  return (
    <div className="inner">
      <h2>{title}</h2>
      <p>{parag}</p>
      <a href={path}>{linkText}</a>
    </div>
  );
}