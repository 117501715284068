import React, { useState } from "react";

// web
import W1 from "../../../assets/home/platform/web/w1.svg";
import W2 from "../../../assets/home/platform/web/w2.svg";
import W3 from "../../../assets/home/platform/web/w3.svg";
import W4 from "../../../assets/home/platform/web/w4.svg";
import W5 from "../../../assets/home/platform/web/w5.svg";
import W6 from "../../../assets/home/platform/web/w6.svg";
import W7 from "../../../assets/home/platform/web/w7.svg";
import W8 from "../../../assets/home/platform/web/w8.svg";
import W9 from "../../../assets/home/platform/web/css.svg";
import W10 from "../../../assets/home/platform/web/js.svg";
import W11 from "../../../assets/home/platform/web/react-js-logo.svg";
import W12 from "../../../assets/home/platform/web/vuejs-logo.svg";
import W13 from "../../../assets/home/platform/web/angular-logo.svg";
import W14 from "../../../assets/home/platform/web/backbone-js-logo.svg";
import W15 from "../../../assets/home/platform/web/meteor.svg";
import W16 from "../../../assets/home/platform/web/knockout-logo.svg";
import W17 from "../../../assets/home/platform/web/ember-logo.svg";
import W18 from "../../../assets/home/platform/web/tailwind.svg";
import W19 from "../../../assets/home/platform/web/nextjs.svg";

// Mobile
import M1 from "../../../assets/home/platform/mobile/ios-logo.svg";
import M2 from "../../../assets/home/platform/mobile/android-logo.svg";
import M3 from "../../../assets/home/platform/mobile/xamarin-logo-vertical.svg";
import M4 from "../../../assets/home/platform/mobile/cordova-logo.svg";
import M5 from "../../../assets/home/platform/mobile/react-native-logo.svg";
import M6 from "../../../assets/home/platform/mobile/pwa-logo.svg";
import M7 from "../../../assets/home/platform/mobile/flutter-logo.svg";
import M8 from "../../../assets/home/platform/mobile/kotlin-programming-language.svg";
import M9 from "../../../assets/home/platform/mobile/ionic-icon.svg";
import M10 from "../../../assets/home/platform/mobile/apache_cordova.svg";
import M11 from "../../../assets/home/platform/mobile/framework-7.svg";
import M12 from "../../../assets/home/platform/mobile/jquery-mobile.svg";

// platform
import PL1 from "../../../assets/home/platform/platform/pl1.svg";
import PL2 from "../../../assets/home/platform/platform/magento-logo-2.svg";
import PL3 from "../../../assets/home/platform/platform/pl3.svg";
import PL4 from "../../../assets/home/platform/platform/pl4.svg";
import PL5 from "../../../assets/home/platform/platform/pl5.svg";
import PL6 from "../../../assets/home/platform/platform/pl6.svg";
import PL7 from "../../../assets/home/platform/platform/pl7.svg";
import PL8 from "../../../assets/home/platform/platform/pl8.svg";
import PL9 from "../../../assets/home/platform/platform/pl9.svg";
import PL10 from "../../../assets/home/platform/platform/pl10.svg";
import PL11 from "../../../assets/home/platform/platform/pl11.svg";
import PL12 from "../../../assets/home/platform/platform/pl12.svg";
import PL13 from "../../../assets/home/platform/platform/pl13.svg";
import PL14 from "../../../assets/home/platform/platform/pl14.svg";
import PL15 from "../../../assets/home/platform/platform/pl15.svg";
import PL16 from "../../../assets/home/platform/platform/pl16.svg";

// cloud
import C1 from "../../../assets/home/platform/cloud/c1.svg";
import C2 from "../../../assets/home/platform/cloud/azure-full.svg";
import C3 from "../../../assets/home/platform/cloud/c3.svg";
import C4 from "../../../assets/home/platform/cloud/c4.svg";
import C6 from "../../../assets/home/platform/cloud/c6.svg";
import C7 from "../../../assets/home/platform/cloud/c7.svg";
import C8 from "../../../assets/home/platform/cloud/c8.svg";
import C9 from "../../../assets/home/platform/cloud/c9.svg";
import C10 from "../../../assets/home/platform/cloud/c10.svg";

// database
import DB1 from "../../../assets/home/platform/database/db1.svg";
import DB2 from "../../../assets/home/platform/database/db2.svg";
import DB3 from "../../../assets/home/platform/database/db3.svg";
import DB4 from "../../../assets/home/platform/database/db4.svg";
import DB10 from "../../../assets/home/platform/database/db10.svg";
import DB6 from "../../../assets/home/platform/database/db6.svg";
import DB7 from "../../../assets/home/platform/database/db7.svg";
import DB8 from "../../../assets/home/platform/database/db8.svg";
import DB9 from "../../../assets/home/platform/database/db9.svg";
import DB11 from "../../../assets/home/platform/database/db11.svg";
import DB12 from "../../../assets/home/platform/database/db12.svg";
import DB13 from "../../../assets/home/platform/database/db13.svg";

import LeftImgs from "./LeftImgs";

import { AiOutlineMobile } from "react-icons/ai";
import { CgWebsite } from "react-icons/cg";
import { GiPlatform } from "react-icons/gi";
import { AiOutlineCloudServer } from "react-icons/ai";
import { HiOutlineDatabase } from "react-icons/hi";

function Platforms() {
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);

  const active1Handler = () => {
    setActive1(true);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
  };
  const active2Handler = () => {
    setActive1(false);
    setActive2(true);
    setActive3(false);
    setActive4(false);
    setActive5(false);
  };
  const active3Handler = () => {
    setActive1(false);
    setActive2(false);
    setActive3(true);
    setActive4(false);
    setActive5(false);
  };
  const active4Handler = () => {
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(true);
    setActive5(false);
  };
  const active5Handler = () => {
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(true);
  };

  return (
    <div className="platform-container">
      <ul>
        <li>
          <p
            onClick={() => {
              active1Handler();
            }}
            style={{
              backgroundColor: active1 ? "#1368b7" : null,
              color: active1 ? "white" : null,
            }}
          >
            Web
          </p>
          <span
            onClick={() => {
              active1Handler();
            }}
            style={{ backgroundColor: active1 ? "white" : null }}
          >
            <CgWebsite
              style={{
                height: active1 ? "3.1rem" : null,
                width: active1 ? "3.1rem" : null,
              }}
            />
          </span>
        </li>
        <li>
          <p
            onClick={() => {
              active2Handler();
            }}
            style={{
              backgroundColor: active2 ? "#1368b7" : null,
              color: active2 ? "white" : null,
            }}
          >
            Mobile
          </p>
          <span
            onClick={() => {
              active2Handler();
            }}
            style={{ backgroundColor: active2 ? "white" : null }}
          >
            <AiOutlineMobile
              style={{
                height: active2 ? "3.1rem" : null,
                width: active2 ? "3.1rem" : null,
              }}
            />
          </span>
        </li>
        <li>
          <p
            onClick={() => {
              active3Handler();
            }}
            style={{
              backgroundColor: active3 ? "#1368b7" : null,
              color: active3 ? "white" : null,
            }}
          >
            Platform
          </p>
          <span
            onClick={() => {
              active3Handler();
            }}
            style={{ backgroundColor: active3 ? "white" : null }}
          >
            <GiPlatform
              style={{
                height: active3 ? "3.1rem" : null,
                width: active3 ? "3.1rem" : null,
              }}
            />
          </span>
        </li>
        <li>
          <p
            onClick={() => {
              active4Handler();
            }}
            style={{
              backgroundColor: active4 ? "#1368b7" : null,
              color: active4 ? "white" : null,
            }}
          >
            Cloud
          </p>
          <span
            onClick={() => {
              active4Handler();
            }}
            style={{ backgroundColor: active4 ? "white" : null }}
          >
            <AiOutlineCloudServer
              style={{
                height: active4 ? "3.1rem" : null,
                width: active4 ? "3.1rem" : null,
              }}
            />
          </span>
        </li>
        <li>
          <p
            onClick={() => {
              active5Handler();
            }}
            style={{
              backgroundColor: active5 ? "#1368b7" : null,
              color: active5 ? "white" : null,
            }}
          >
            Relational Database / Data Storage
          </p>
          <span
            onClick={() => {
              active5Handler();
            }}
            style={{ backgroundColor: active5 ? "white" : null }}
          >
            <HiOutlineDatabase
              style={{
                height: active5 ? "3.1rem" : null,
                width: active5 ? "3.1rem" : null,
              }}
            />
          </span>
        </li>
      </ul>
      <div className="right-side">
        {/* for web  */}
        {active1 ? (
          <>
            <div className="title">
              <h2>Web</h2>
            </div>
            <div className="images">
              <LeftImgs pImg={W1} title=".net" />
              <LeftImgs pImg={W2} title="java" />
              <LeftImgs pImg={W3} title="python" />
              <LeftImgs pImg={W4} title="nodejs" />
              <LeftImgs pImg={W5} title="php" />
              <LeftImgs pImg={W6} title="csharp" />
              <LeftImgs pImg={W7} title="go" />
              <LeftImgs pImg={W8} title="html" />
              <LeftImgs pImg={W9} title="css" />
              <LeftImgs pImg={W10} title="javascript" />
              <LeftImgs pImg={W11} title="reactjs" />
              <LeftImgs pImg={W18} title="tailwind" />
              <LeftImgs pImg={W12} title="vuejs" />
              <LeftImgs pImg={W13} title="angular" />
              <LeftImgs pImg={W14} title="backbone" />
              <LeftImgs pImg={W15} title="meteor" />
              <LeftImgs pImg={W16} title="knockout" />
              <LeftImgs pImg={W17} title="ember" />
              <LeftImgs pImg={W19} title="nextjs" />
            </div>
          </>
        ) : null}

        {/* for mobile  */}
        {active2 ? (
          <>
            <div className="title">
              <h2>Mobile</h2>
            </div>
            <div className="images">
              <LeftImgs pImg={M1} title="ios" />
              <LeftImgs pImg={M3} title="xamarin" />
              <LeftImgs pImg={M4} title="cordova" />
              <LeftImgs pImg={M6} title="PWD" />
              <LeftImgs pImg={M7} title="flutter" />
              <LeftImgs pImg={M5} title="react native" />
              <LeftImgs pImg={M9} title="ionic" />
              <LeftImgs pImg={M8} title="kotlin" />
              <LeftImgs pImg={M10} title="apache_cordova" />
              <LeftImgs pImg={M11} title="framework-7" />
              <LeftImgs pImg={M12} title="jquery-mobile" />
              <LeftImgs pImg={M2} title="android" />
            </div>
          </>
        ) : null}

        {/* for platform  */}
        {active3 ? (
          <>
            <div className="title">
              <h2>Platform</h2>
            </div>
            <div className="images">
              <LeftImgs pImg={PL1} title="dynamics 365" />
              <LeftImgs pImg={PL4} title="salesforce" />
              <LeftImgs pImg={PL7} title="sharepoint" />
              <LeftImgs pImg={PL5} title="sap" />
              <LeftImgs pImg={PL3} title="power bi" />
              <LeftImgs pImg={PL8} title="quicknode" />
              <LeftImgs pImg={PL2} title="magento" />
              <LeftImgs pImg={PL9} title="solidity" />
              <LeftImgs pImg={PL10} title="hardhat" />
              <LeftImgs pImg={PL13} title="the graph" />
              <LeftImgs pImg={PL11} title="ipfs" />
              <LeftImgs pImg={PL14} title="chainlink" />
              <LeftImgs pImg={PL15} title="vs code" />
              <LeftImgs pImg={PL16} title="git" />
              <LeftImgs pImg={PL12} title="arweave" />
              <LeftImgs pImg={PL6} title="servicenow" />
            </div>
          </>
        ) : null}

        {/* for cloud  */}
        {active4 ? (
          <>
            <div className="title">
              <h2>Cloud</h2>
            </div>
            <div className="images">
              <LeftImgs pImg={C1} title="aws" />
              <LeftImgs pImg={C2} title="azure" />
              <LeftImgs pImg={C4} title="google cloud" />
              <LeftImgs pImg={C3} title="digital ocean" />
              <LeftImgs pImg={C6} title="ibm cloud" />
              <LeftImgs pImg={C7} title="tencent cloud" />
              <LeftImgs pImg={C8} title="alibaba cloud" />
              <LeftImgs pImg={C10} title="Oracle Cloud" />
              <LeftImgs pImg={C9} title="rackspace" />
            </div>
          </>
        ) : null}
        {/* for database  */}
        {active5 ? (
          <>
            <div className="title">
              <h2>Database</h2>
            </div>
            <div className="images">
              <LeftImgs pImg={DB6} title="sql server" />
              <LeftImgs pImg={DB7} title="oracle" />
              <LeftImgs pImg={DB3} title="azure synapse analytics" />
              <LeftImgs pImg={DB1} title="amazon RDS" />
              <LeftImgs pImg={DB11} title="ibm db2" />
              <LeftImgs pImg={DB2} title="azure sql database" />
              <LeftImgs pImg={DB10} title="MongoDB" />
              <LeftImgs pImg={DB9} title="google cloud server" />
              <LeftImgs pImg={DB8} title="postgre SQL" />
              <LeftImgs pImg={DB12} title="single store" />
              <LeftImgs pImg={DB13} title="firebase" />
              <LeftImgs pImg={DB4} title="amazon s3" />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Platforms;
