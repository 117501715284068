import React from "react";

function JoinUsIcons({ icon, title }) {
  return (
    <div className="join-us-icons">
      <div className="inner-wrapper">
        <div className="icon">
          <img src={icon} alt={title} />
        </div>
        <span>{title}</span>
      </div>
    </div>
  );
}

export default JoinUsIcons;
