import React from "react";

import PY1 from "../../../../assets/work/mobile apps/event reminder/Event Reminder.jpg";

import { RxCross1 } from "react-icons/rx";
import { useContext } from "react";
import { WorkMenuData } from "../../OurWork";

function EventReminder() {
  const { setActive7 } = useContext(WorkMenuData);

  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActive7(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActive7(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>
          <div className="top-pop">
            <h2>Event Reminder App</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Mobile App</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>Skyler Bird</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                The Event Reminder App is a user-friendly and efficient mobile
                application designed to help individuals and organizations stay
                organized and never miss important events or appointments.
              </strong>
            </p>
            <p>
              Whether it's personal birthdays, business meetings, or social
              gatherings, this App is a reliable digital assistant to keep track
              of upcoming events and send timely reminders. The App allows users
              to categorize events based on different tags, making it convenient
              to filter and search for specific events when needed.
            </p>
            <p>
              Once events are created, the App's reminder system comes into
              play. Users can set customizable reminders for each event,
              specifying how much time in advance they want to be notified.
              These reminders can be in the form of push notifications, email
              alerts, or even SMS messages, ensuring that users are promptly
              reminded of their upcoming events, no matter where they are.{" "}
              <br />
              The Event Reminder App also offers additional features to enhance
              the user experience. It provides options to set recurring events,
              such as weekly meetings or monthly birthdays, saving users time
              from manually creating repetitive events. Users can also sync
              their App with their device's calendar, enabling seamless
              integration with their existing schedules.
            </p>
            <p>
              The App allows users to invite others to their events. Users can
              easily add participants and send out event invitations through
              email or in-app notifications. <br />
              The Event Reminder App is a reliable and efficient tool for
              managing and organizing events. With its user-friendly interface,
              customizable reminders, and collaborative features, the App helps
              users stay on top of their schedules, avoid missing important
              appointments, and maintain a well-organized and productive
              lifestyle.
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventReminder;
