import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Up from "../../assets/up.png";
import Down from "../../assets/down.png";
import { SimpleButon } from "../../buttons/MyButon";
import { useContext } from "react";
import { GlobalData } from "../../../App";

function Menu({ setActiveM }) {
  const {
    color1,
    setColor1,
    color2,
    setColor2,
    color3,
    setColor3,
    color4,
    setColor4,
    color5,
    setColor5,
    colorCareer,
    setColorCareer,
    colorProcess,
    setColorProcess,
    colorDTeam,
    setColorDTeam,
    activeA,
    setActiveA,
    activeS,
    setActiveS,
    activeC,
    setActiveC,
    hideDropDown,
  } = useContext(GlobalData);

  const [dropDownStopService, setDropDownStopService] = useState(false);
  const [dropDownHoverService, setDropDownHoverService] = useState(false);

  const [dropDownStopProcess, setDropDownStopProcess] = useState(false);
  const [dropDownHoverProcess, setDropDownHoverProcess] = useState(false);

  const [dropDownStopCareer, setDropDownStopCareer] = useState(false);
  const [dropDownHoverCareer, setDropDownHoverCareer] = useState(false);

  const mobileHideHam = () => {
    setActiveM(false);
  };

  const colorsHandler = (
    c1 = false,
    c2 = false,
    c3 = false,
    c4 = false,
    c5 = false,
    c6 = false,
    c7 = false
  ) => {
    setColor1(c1);
    setColor2(c2);
    setColor3(c3);
    setColor4(c4);
    setColor5(c5);
    setColorCareer(c6);
    setColorProcess(c7);
  };

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    // window.addEventListener("scroll", (e) => {
    //   hideDropDown();
    // });

    // for service 
    if (
      (dropDownStopService && !dropDownHoverService) ||
      (dropDownHoverService && !dropDownStopService) ||
      (dropDownHoverService && dropDownStopService)
    ) {
      setActiveS(true);
    } else if (!isMobile) {
      setActiveS(false);
      setColor3(false);
    }

    // for process 
    if (
      (dropDownStopProcess && !dropDownHoverProcess) ||
      (dropDownHoverProcess && !dropDownStopProcess) ||
      (dropDownHoverProcess && dropDownStopProcess)
    ) {
      setActiveA(true);
    } else if (!isMobile) {
      setActiveA(false);
      setColorProcess(false);
    }

    // for career
    if (
      (dropDownStopCareer && !dropDownHoverCareer) ||
      (dropDownHoverCareer && !dropDownStopCareer) ||
      (dropDownHoverCareer && dropDownStopCareer)
    ) {
      setActiveC(true);
    } else if (!isMobile) {
      setActiveC(false);
      setColorCareer(false);
    }



    console.log(
      "stop ", dropDownStopService, dropDownStopProcess, dropDownStopCareer,
      "\nhover ", dropDownHoverService, dropDownHoverProcess, dropDownHoverCareer,
      "\nactive ", activeS, activeA, activeC
    );



    // click outside
    const handleClickOutside = (event) => {
      if (!activeS || !event.target.closest('.list')) {
        // setActiveS(false);
        setDropDownStopService(false);
      }
      if (!activeA || !event.target.closest('.list')) {
        setDropDownStopProcess(false);
      }
      if (!activeC || !event.target.closest('.list')) {
        setDropDownStopCareer(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };



  }, [activeA, activeS, color1, color2, color3, color4, colorDTeam, hideDropDown, dropDownStopService, dropDownHoverService, setActiveS, isMobile, setColor3, dropDownStopProcess, dropDownHoverProcess, setActiveA, setColorProcess, dropDownStopCareer, dropDownHoverCareer, activeC, setActiveC, setColorCareer]);

  const serviceSubMenuClick = () => {
    hideDropDown();
    setActiveM(false);
    setActiveS(false);
    setColor3(false);
    setDropDownHoverService(false);
    setDropDownStopService(false);
  };

  const processSubMenuClick = () => {
    hideDropDown();
    setActiveM(false);
    setActiveA(false);
    setColorProcess(false);
    setDropDownHoverProcess(false);
    setDropDownStopProcess(false);
  };

  const careerSubMenuClick = () => {
    hideDropDown();
    setActiveM(false);
    setActiveC(false);
    setColorCareer(false);
    setDropDownHoverCareer(false);
    setDropDownStopCareer(false);
  };

  return (
    <>
      <nav className="menu-container">
        <ul className="list">
          {/* mobile btn  */}
          <li
            onClick={() => {
              hideDropDown();
              mobileHideHam();
              setActiveS(false);
              setActiveA(false);

              colorsHandler(false, false, false, false, false, false, false);
            }}
          >
            <SimpleButon btn={"Get Quote"} path={"/contact"} />
          </li>

          {/* service */}
          <li
            onMouseEnter={() => {
              if (!dropDownStopService) {
                setDropDownHoverService(true);
                setActiveA(false);
                setActiveC(false);
                colorsHandler(false, false, true, false, false, false, false);

                setDropDownStopProcess(false);
                setDropDownStopCareer(false);
              }
            }}
            onMouseLeave={() => {
              if (!dropDownStopService) {
                setDropDownHoverService(false);
                !isMobile && setActiveS(false);
                dropDownHoverService && setColor3(false);
                !dropDownHoverService && setActiveS(false);
              }
            }}
          >
            <button
              style={{
                color: color3 ? "#1368B7" : null,
                backgroundColor: color3 ? "#DDF0FD" : "white",
                fontWeight: color3 && "500",
                bottom: color3 && "1px",
              }}
            >
              <Link
                style={{
                  color: activeS || color3 ? "#1368B7" : null,
                  fontWeight: color3 && "500",
                }}
                onClick={() => {
                  setDropDownHoverService(false);
                  !isMobile && setDropDownStopService(!dropDownStopService);
                  isMobile && setActiveS(!activeS);
                }}
              >
                Service
                <img src={activeS ? Up : Down} alt="" />
              </Link>
            </button>
            {activeS ? (
              <ul className="sub-list">
                <li>
                  <Link
                    to={"services/got-a-business-idea"}
                    onClick={() => {
                      serviceSubMenuClick();
                    }}
                  >
                    Got a business idea?
                  </Link>
                </li>
                {/* <hr />
                <li
                  onClick={() => {
                    hideDropDown();
                    setActiveM(false);
                  }}
                >
                  <Link
                    to={"services/hire-dedicated-team"}
                    onClick={() => {
                      setColorDTeam(true);
                      setActiveS(true);
                    }}
                  >
                    Hire Dedicated Team
                  </Link>
                </li> */}
                <hr />
                <li>
                  <Link
                    to={"services/mobile-app-development"}
                    onClick={() => {
                      serviceSubMenuClick();
                    }}
                  >
                    Mobile app development
                  </Link>
                </li>
                <hr />
                <li>
                  <Link
                    to={"services/web-development-company"}
                    onClick={() => {
                      serviceSubMenuClick();
                    }}
                  >
                    SaaS / Web Development
                  </Link>
                </li>
                <hr />
                <li>
                  <Link
                    to={"services/game-development"}
                    onClick={() => {
                      serviceSubMenuClick();
                    }}
                  >
                    Game Development
                  </Link>
                </li>
                <hr />
                <li>
                  <Link
                    to={"services/ecommerce-store-development"}
                    onClick={() => {
                      serviceSubMenuClick();
                    }}
                  >
                    eCommerce Development
                  </Link>
                </li>
                <hr />
                <li>
                  <Link
                    to={"services/wordpress-development"}
                    onClick={() => {
                      serviceSubMenuClick();
                    }}
                  >
                    WordPress Development
                  </Link>
                </li>
                <hr />
                <li>
                  <Link
                    to={"services/frontend-development"}
                    onClick={() => {
                      serviceSubMenuClick();
                    }}
                  >
                    Frontend Development
                  </Link>
                </li>
                <hr />
                <li>
                  <Link
                    to={"services/hire-dedicated-developer"}
                    onClick={() => {
                      serviceSubMenuClick();
                    }}
                  >
                    Dedicated Developer
                  </Link>
                </li>
                <hr />
                <li>
                  <Link
                    to={"services/seo"}
                    onClick={() => {
                      serviceSubMenuClick();
                    }}
                  >
                    Search Engine Optimization
                  </Link>
                </li>
                <hr />
                <li>
                  <Link
                    to={"services/qa-and-testing"}
                    onClick={() => {
                      serviceSubMenuClick();
                    }}
                  >
                    QA & Testing
                  </Link>
                </li>
                <hr />
                <li>
                  <Link
                    to={"services/it-consultancy"}
                    onClick={() => {
                      serviceSubMenuClick();
                    }}
                  >
                    IT Consultancy
                  </Link>
                </li>
                <hr />
                <li>
                  <Link
                    to={"services/technical-support"}
                    onClick={() => {
                      serviceSubMenuClick();
                    }}
                  >
                    Technical Support
                  </Link>
                </li>
              </ul>
            ) : null}
          </li>

          {/* about */}
          <li
            onMouseEnter={() => {
              hideDropDown();
              mobileHideHam();
            }}
          >
            <Link
              to={"/about"}
              onMouseEnter={() => {
                colorsHandler(false, true, false, false, false, false, false);

                setDropDownStopService(false);
                setDropDownStopProcess(false);
                setDropDownStopCareer(false);

              }}
              onMouseLeave={() => {
                setColor2(false);
              }}
              style={{
                color: color2 ? "#1368B7" : null,
                backgroundColor: color2 ? "#DDF0FD" : "white",
                fontWeight: color2 && "500",
                bottom: color2 && "1px",
              }}
            >
              About Us
            </Link>
          </li>

          {/* process  */}
          <li
            onMouseEnter={() => {
              if (!dropDownStopProcess) {
                setDropDownHoverProcess(true);
                setActiveA(false);
                setActiveC(false);
                colorsHandler(false, false, false, false, false, false, true);

                setDropDownStopService(false);
                setDropDownStopCareer(false);
              }
            }}
            onMouseLeave={() => {
              if (!dropDownStopProcess) {
                setDropDownHoverProcess(false);
                !isMobile && setActiveA(false);
                dropDownHoverService && setColorProcess(false);
                !dropDownHoverService && setActiveA(false);
              }
            }}
          >
            <button
              style={{
                color: colorProcess ? "#1368B7" : null,
                backgroundColor: colorProcess ? "#DDF0FD" : "white",
                fontWeight: colorProcess && "500",
                bottom: colorProcess && "1px",
              }}
            >
              <Link
                style={{
                  color: activeA || colorProcess ? "#1368B7" : null,
                  fontWeight: colorProcess && "500",
                }}
                onClick={() => {
                  setDropDownHoverProcess(false);
                  !isMobile && setDropDownStopProcess(!dropDownStopProcess);
                  isMobile && setActiveA(!activeA);
                }}
              >
                Process
                <img src={activeA ? Up : Down} alt="" />
              </Link>
            </button>
            {activeA ? (
              <ul className="sub-list">
                <li
                >
                  <Link
                    to={"process/whyhexamilesoft"}
                    onClick={() => {
                      processSubMenuClick();
                    }}
                  >
                    Why HexamileSoft
                  </Link>
                </li>
                <hr />
                <li
                >
                  <Link
                    to={"process/how-we-work"}
                    onClick={() => {
                      processSubMenuClick();
                    }}
                  >
                    How We Work
                  </Link>
                </li>
                <hr />
                <li

                >
                  <Link
                    to={"process/distributed-agile"}
                    onClick={() => {
                      processSubMenuClick();
                    }}
                  >
                    Distributed Agile
                  </Link>
                </li>
                <hr />
                <li

                >
                  <Link
                    to={"process/quality-management/"}
                    onClick={() => {
                      processSubMenuClick();
                    }}
                  >
                    Quality Management
                  </Link>
                </li>
              </ul>
            ) : null}
          </li>

          {/* work */}
          <li
            onMouseEnter={() => {
              hideDropDown();
              setActiveM(false);

              setDropDownStopService(false);
              setDropDownStopProcess(false);
              setDropDownStopCareer(false);
            }}
            onMouseLeave={() => {
              setColor5(false);
            }}
          >
            <Link
              to={"/work"}
              onMouseEnter={() => {
                colorsHandler(false, false, false, false, true, false, false);
              }}
              style={{
                color: color5 ? "#1368B7" : null,
                backgroundColor: color5 ? "#DDF0FD" : "white",
                fontWeight: color5 && "500",
                bottom: color5 && "1px",
              }}
            >
              Our Work
            </Link>
          </li>

          {/* stories  */}
          <li
            onMouseEnter={() => {
              hideDropDown();
              setActiveM(false);

              setDropDownStopService(false);
              setDropDownStopProcess(false);
              setDropDownStopCareer(false);
            }}
            onMouseLeave={() => {
              setColor4(false);
            }}
          >
            <a
              href="http://hexamilesoft.com/stories/"
              onMouseEnter={() => {
                setActiveS(false);
                setActiveA(false);
                setActiveC(false);

                colorsHandler(false, false, false, true, false, false, false);
              }}
              style={{
                color: color4 ? "#1368B7" : null,
                backgroundColor: color4 ? "#DDF0FD" : "white",
                fontWeight: color4 && "500",
                bottom: color4 && "1px",
              }}
            >
              Blog
            </a>
          </li>

          {/* career  */}
          <li
            onMouseEnter={() => {
              if (!dropDownStopCareer) {
                setDropDownHoverCareer(true);
                setActiveA(false);
                setActiveS(false);
                colorsHandler(false, false, false, false, false, true, false);

                setDropDownStopService(false);
                setDropDownStopProcess(false);
              }
            }}
            onMouseLeave={() => {
              if (!dropDownStopCareer) {
                setDropDownHoverCareer(false);
                !isMobile && setActiveC(false);
                dropDownHoverService && setColorCareer(false);
                !dropDownHoverService && setActiveC(false);
              }
            }}
          >
            <button
              style={{
                color: colorCareer ? "#1368B7" : null,
                backgroundColor: colorCareer ? "#DDF0FD" : "white",
                fontWeight: colorCareer && "500",
                bottom: colorCareer && "1px",
              }}
            >
              <Link
                style={{
                  color: activeC || colorCareer ? "#1368B7" : null,
                  fontWeight: colorCareer && "500",
                }}
                onClick={() => {
                  setDropDownHoverCareer(false);
                  !isMobile && setDropDownStopCareer(!dropDownStopCareer);
                  isMobile && setActiveC(!activeC);
                }}
              >
                Careers
                <img src={activeC ? Up : Down} alt="" />
              </Link>
            </button>
            {activeC ? (
              <ul className="sub-list">
                <li

                >
                  <Link
                    to={"careers/life-at-hexamile-soft"}
                    onClick={() => {
                      careerSubMenuClick();
                    }}
                  >
                    Life @ Hexamile
                  </Link>
                </li>
                <hr />
                <li

                >
                  <Link
                    to={"careers/core-values"}
                    onClick={() => {
                      careerSubMenuClick();
                    }}
                  >
                    Our Core Values
                  </Link>
                </li>
                <hr />
                <li

                >
                  <Link
                    to={"careers/job-openings"}
                    onClick={() => {
                      careerSubMenuClick();
                    }}
                  >
                    Job Openings
                  </Link>
                </li>
              </ul>
            ) : null}
          </li>

          {/* contact us  */}
          <li
            onClick={() => {
              hideDropDown();
              setActiveS(false);
              setActiveA(false);

              colorsHandler(false, false, false, false, false, false, false);

              setDropDownStopService(false);
              setDropDownStopProcess(false);
              setDropDownStopCareer(false);
            }}
          >
            <SimpleButon btn={"Contact Us"} path={"/contact"} />
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Menu;
