import React from "react";

import "../../pages/servicesSpare.css";

import TrustPilot from "../../trust/TrustPilot";
import ServiceContact from "../../contact/ServiceContact";
import ServiceHead from "../../top_head/ServiceHead";
import InnerHeading from "../../headings/InnerHeading";
import UpperContent from "../../upper_content/UpperContent";
import TrustedBy from "../../trusted_by_imgs/TrustedBy";
import StartProject from "../../start_project/StartProject";
import HireEngineers from "../../hire_engineers/HireEngineers";
import EngagementModel from "../../engagement_models/EngagementModel";
import SEOClick from "./SEOClick";

// images
import MyImg from "../../../../assets/service/services/Search Engine Optimization.jpg";
import { useEffect } from "react";

function SEO() {
  const paragH1 =
    "Well, maybe you are the best at what you do and deserve to be recognized and appreciated, but did you check what it takes to be recognized? With a sea of websites waiting to be acknowledged and placed in higher ranks, your website has to stand out with its distinct qualities and serve what the customer wants.";

  const paragCon1 =
    "Search Engine Optimization does that perfectly. It helps boost the traffic on your website by highlighting your core services and adding value to the content with words that customers search for, making your website grow organically.";

  const paragCon2 =
    "Our SEO Executives at HexamileSoft take pride in their years of experience in Technical SEO, On-Page SEO, Off-Page SEO, and more. They have helped hundreds of businesses achieve their online goals as a team.";

  const startP =
    "At HexamileSoft, we're all about startups! It's in our DNA. We love new ideas and bringing them to life, and you'll see that in our hands-on approach to your project. From concept to launch, we're your go-to partner for all things startup. Let's make your ideas a reality!";

  useEffect(() => {
    document.title = "Search Engine Optimization - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "Improve your website's organic search score with our professional SEO services to compete for the highest rankings.";
    document.getElementsByTagName("META")[6].content =
      "About us - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "Improve your website's organic search score with our professional SEO services to compete for the highest rankings.";
    document.getElementsByTagName("META")[12].content =
      "About us - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "Improve your website's organic search score with our professional SEO services to compete for the highest rankings.";
  }, []);

  return (
    <div className="services-container">
      <div className="heading-container">
        <ServiceHead
          title={"Helping You Rise through the Ranks"}
          parag={"Need Help in Getting Your Well-deserved Recognition?"}
        />
      </div>

      {/* InnerHeading */}
      <div className="title-heading-container">
        <div className="heading-wrapper">
          <InnerHeading
            titleH={"We Help You Rank Higher and Boost Traffic on Your Website"}
            paragH={paragH1}
          />
        </div>
        <hr />
        <div className="top-inner-container">
          <UpperContent
            imgCon={MyImg}
            paragCon1={paragCon1}
            paragCon2={paragCon2}
            paragCon3={""}
            btnPath={"#seo"}
          />
        </div>
      </div>

      {/* trusted by */}
      <div className="trusted-by-container">
        <TrustedBy />
      </div>

      {/* click projects  */}
      <div className="click-projects-container">
        <SEOClick />
      </div>

      {/* hire engineer  */}
      <div className="hire-engineer-container">
        <HireEngineers
          engrName={"Hire SEO Specialist"}
          engrPrice={"2200"}
          btnPath={"#seo"}
        />
        <EngagementModel />
      </div>

      {/* start your project  */}
      <div className="start-your-project-container">
        <StartProject
          titleProj={"Interested in Creating Something Awesome With Us?"}
          startP={startP}
          btnPath={"#seo"}
        />
      </div>

      {/* TrustPilot */}
      <div className="trust-pilot-wrap">
        <TrustPilot />
      </div>

      {/* ServiceContact */}
      <div className="service-contact-wrapper" id="seo">
        <ServiceContact />
      </div>
    </div>
  );
}

export default SEO;
