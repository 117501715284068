import React from "react";
import { useState } from "react";

import "../allClickProjects.scss";

import { GiAbstract005 } from "react-icons/gi";

import { BiCaretRight } from "react-icons/bi";
import { SimpleButon, SimpleButonId } from "../../../../buttons/MyButon";

function SaaSClick({ btnPath }) {
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);

  const activeHandler = (a1 = true, a2 = false, a3 = false, a4 = false) => {
    setActive1(a1);
    setActive2(a2);
    setActive3(a3);
    setActive4(a4);
  };

  return (
    <div className="inner-project-wrapper">
      <div className="heading">
        <h2>Exceptionally Outstanding Development</h2>
        <p>
          We have a team of experienced specialists across various technologies
          and industries. We guide your product from concept to launch, whether
          it's a prototype, MVP, or full-fledged application.
        </p>
        <hr />
      </div>

      <div className="select-projects-container">
        <div className="left-side">
          {/* PHP Development */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler();
            }}
            style={{ backgroundColor: active1 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>PHP Development</span>
            </div>
            <BiCaretRight />
          </div>

          {/* .Net Development */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, true, false, false);
            }}
            style={{ backgroundColor: active2 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>.Net Development</span>
            </div>
            <BiCaretRight />
          </div>

          {/* ROR Development */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, true, false);
            }}
            style={{ backgroundColor: active3 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>ROR Development</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Angular JS Development */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, false, true);
            }}
            style={{ backgroundColor: active4 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Angular JS Development</span>
            </div>
            <BiCaretRight />
          </div>
        </div>

        {/* content  */}
        <div className="right-side">
          {/* PHP Development */}
          {active1 && (
            <div className="mydiv">
              <h3>PHP Development</h3>
              <p>
                <strong>
                  With a strong team of more than 50 specialists, HexamileSoft
                  is an ISO 9001-certified PHP development firm that excels at
                  creating complicated, bespoke PHP applications.
                </strong>
                <br />
                HexamileSoft provides complete PHP web applications and
                solutions for many industry verticals by using the in-depth
                domain expertise of our PHP professionals and implementing
                industry best practices and processes.
                <br />
                HexamileSoft's PHP solutions provide increased cooperation,
                scalability, and flexibility by utilizing the best technological
                frameworks and approaches. Our solutions meet the varied needs
                of enterprises in a performance-enhancing way while providing
                more affordable knowledge management and more content
                accessibility. HexamileSoft provides business solutions that let
                you function creatively and effectively to accomplish your
                company goals. Our PHP-based online solutions, based on the most
                current technological standards and frameworks, offer the
                highest return on investment (ROI), lower total development
                costs, and completely revamp the workflow system.
              </p>

              <div className="project-btn">
                <SimpleButonId btn={"Hire"} path={btnPath} />
              </div>
            </div>
          )}

          {/* .Net Development */}
          {active2 && (
            <div className="mydiv">
              <h3>
                Web, Desktop, Mobile, Enterprise, and Cloud Expert Services
                in.NET Development and.NET Migration
              </h3>
              <p>
                Utilizing the Microsoft.NET framework, HexamileSoft assists
                businesses in creating strong solutions. We can assist if you're
                looking for a solid, expandable, secure, and .NET development
                best practices-based software design. Your upcoming.NET software
                development initiative can be supported across the following by
                our team of skilled programmers:
              </p>
              <ul>
                <li>ASP.NET Website Development </li>
                <li>Development of Windows Applications</li>
                <li>Enterprise Applications</li>
                <li>.NET Professional Services</li>
              </ul>
              <div className="project-btn">
                <SimpleButonId btn={"Hire"} path={btnPath} />
              </div>
            </div>
          )}

          {/* ROR Development */}
          {active3 && (
            <div className="mydiv">
              <h3>
                With more than five years of expertise and familiarity with all
                the most recent ROR framework versions, our professionals are
                enthusiastic about secure and dynamic ROR web development.
              </h3>
              <p>
                Any successful web development company will tell you that
                quality is the secret to its success. HexamileSoft is among the
                most reputable names in providing the best Ruby on Rails
                development services. As a P@SHA accredited business, we are
                equipped to implement our esteemed client's unique project
                requirements thanks to our 50+ team members and years of
                combined expertise. We are committed to providing services
                within the allotted time that would leave customers completely
                satisfied.
                <br />
                HexamileSoft is a well-known Ruby on Rails development business
                with expertise in the following fields:
              </p>
              <ul>
                <li>Web portal creation</li>
                <li>Web 2.0 development by ROR</li>
                <li>Development of bespoke ROR online & applications</li>
                <li>Support and upkeep for ROR</li>
              </ul>

              <div className="project-btn">
                <SimpleButonId btn={"Hire"} path={btnPath} />
              </div>
            </div>
          )}

          {/* Angular JS Development */}
          {active4 && (
            <div className="mydiv">
              <h3>
                We create web applications that are AngularJS powered. We are
                ardent programmers with substantial expertise in creating
                single-page applications with AngularJS.
              </h3>
              <p>
                Find quick, top-notch AngularJS development solutions that meet
                your needs. For all types of businesses, our skilled developers
                are highly inventive in creating front ends and websites built
                on the AngularJS framework.
                <br />
                Our developers' primary competence areas include building
                responsive single-page web applications and rich website user
                interfaces using AngularJS. These are the services we are
                offering you thanks to these strong points:
              </p>
              <ul>
                <li>
                  Combined the Development of AngularJS with other server-side
                  frameworks, such as ASP.NET, JSP/JAVA, PHP, Ruby on Rails,
                  Lasso, etc.
                </li>
                <li>Creation of Web Applications </li>
                <li>Dynamic App Development</li>
                <li>Development of Web Portals</li>
              </ul>

              <div className="project-btn">
                <SimpleButonId btn={"Hire"} path={btnPath} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SaaSClick;
