import React from "react";
import ServiceContact from "../../contact/ServiceContact";
import EngagementModel from "../../engagement_models/EngagementModel";
import InnerHeading from "../../headings/InnerHeading";
import HireEngineers from "../../hire_engineers/HireEngineers";
import StartProject from "../../start_project/StartProject";
import ServiceHead from "../../top_head/ServiceHead";
import TrustPilot from "../../trust/TrustPilot";
import TrustedBy from "../../trusted_by_imgs/TrustedBy";
import UpperContent from "../../upper_content/UpperContent";
import ECommerceClick from "./EcommerceClick";
import EcomPrice from "./EcomPrice";

// images
import ECom from "../../../../assets/service/services/E-commerce Development.jpg";
import { useEffect } from "react";

function EcommerceDev() {
  const paragH1 =
    "Our primary goal is to help our clients create a cost-effective website that generates a strong return on investment. With over a decade of experience delivering e-commerce platform development and services, we have the expertise to help you take your business to new heights. Our partnership with you is built on this foundation and guides all the work we do for you.";

  const paragCon1 =
    "At HexamileSoft, we understand the importance of creating a positive user experience for your customers to convert them from casual browsers to loyal patrons. As a savvy online retailer, your goal is to meet your customer's needs, and our goal is to help you achieve that. We thoroughly understand customers' needs and use that knowledge to design online storefronts that engage and delight them. From architecture to functionality and navigation, we focus on all the key touchpoints that make for a meaningful user experience, thus fostering a deeper connection with your customers and driving the success of your online store.";

  const startP =
    "At HexamileSoft, we're all about startups! It's in our DNA. We love new ideas and bringing them to life, and you'll see that in our hands-on approach to your project. From concept to launch, we're your go-to partner for all things startup. Let's make your ideas a reality! ";

  useEffect(() => {
    document.title = "Ecommerce Development - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "Get our services to Design, Build, and help operate an online store or marketplace such as Magento 2.0, Shopify, Volusion, BigCommerce, 3Dcart, and Wix.";
    document.getElementsByTagName("META")[6].content =
      "Ecommerce Development - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "Get our services to Design, Build, and help operate an online store or marketplace such as Magento 2.0, Shopify, Volusion, BigCommerce, 3Dcart, and Wix.";
    document.getElementsByTagName("META")[12].content =
      "Ecommerce Development - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "Get our services to Design, Build, and help operate an online store or marketplace such as Magento 2.0, Shopify, Volusion, BigCommerce, 3Dcart, and Wix.";
  }, []);

  return (
    <div className="services-container">
      <div className="heading-container">
        <ServiceHead
          title={"Get Your Store Created Today"}
          parag={"Planning to Grow Your Online Business? "}
        />
      </div>

      {/* InnerHeading */}
      <div className="title-heading-container">
        <div className="heading-wrapper">
          <InnerHeading
            titleH={"An Astute Approach to e-Commerce"}
            paragH={paragH1}
          />
        </div>
        <hr />
        <div className="top-inner-container">
          <UpperContent
            imgCon={ECom}
            paragCon1={paragCon1}
            paragCon2={""}
            paragCon3={""}
            btnPath={"#ecom"}
          />
        </div>
      </div>

      {/* trusted by */}
      <div className="trusted-by-container">
        <TrustedBy />
      </div>

      {/* click projects  */}
      <div className="click-projects-container">
        <ECommerceClick />
      </div>

      {/* price  */}
      {/* <div className="my-service-price-containe">
        <EcomPrice btnPath={"#ecom"} />
      </div> */}

      {/* hire engineer  */}
      <div className="hire-engineer-container">
        <HireEngineers
          engrName={"Hire E-Commerce Specialist"}
          engrPrice={"2200"}
          btnPath={"#ecom"}
        />
        <EngagementModel />
      </div>

      {/* start your project  */}
      <div className="start-your-project-container">
        <StartProject
          titleProj={"Interested in Creating Something Awesome With Us?"}
          startP={startP}
          btnPath={"#ecom"}
        />
      </div>

      {/* TrustPilot */}
      <div className="trust-pilot-wrap">
        <TrustPilot />
      </div>

      {/* ServiceContact */}
      <div className="service-contact-wrapper" id="ecom">
        <ServiceContact />
      </div>
    </div>
  );
}

export default EcommerceDev;
