import React from "react";

import PY1 from "../../../../assets/work/web/project 3/1.png";

import { RxCross1 } from "react-icons/rx";
import { useContext } from "react";
import { WorkMenuData } from "../../OurWork";

function YorkeConstructionWeb() {
  const { setActive3 } = useContext(WorkMenuData);

  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActive3(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActive3(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>

          <div className="top-pop">
            <h2>Yorke Construction Corporation Website</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Web Design</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>Maggie Jasper</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                Yorke Construction Corporation Website: Building Dreams into
                Reality
              </strong>
            </p>
            <p>
              The Yorke Construction Corporation website is a testament to the
              company's dedication to excellence in the construction industry.
              With a user-friendly interface and a wealth of information, the
              website offers an immersive experience that reflects the company's
              core values and showcases its impressive track record.
            </p>
            <p>
              The Yorke Construction Corporation website is a dynamic online
              platform showcasing the company's dedication to excellence,
              safety, and quality in the construction industry. The website
              effectively communicates the company's expertise, track record,
              and commitment to meeting clients' needs through its visually
              appealing design, comprehensive information, and user-friendly
              interface. Whether someone is seeking a construction partner or
              simply exploring the world of construction, the Yorke Construction
              Corporation website is a valuable resource that inspires
              confidence and ignites the imagination.{" "}
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YorkeConstructionWeb;

export function HomeYorkeConstructionWeb({ setActiveP3 }) {
  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActiveP3(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActiveP3(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>
          <div className="top-pop">
            <h2>Yorke Construction Corporation Website</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Web Design</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>Maggie Jasper</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                Yorke Construction Corporation Website: Building Dreams into
                Reality
              </strong>
            </p>
            <p>
              The Yorke Construction Corporation website is a testament to the
              company's dedication to excellence in the construction industry.
              With a user-friendly interface and a wealth of information, the
              website offers an immersive experience that reflects the company's
              core values and showcases its impressive track record.
            </p>
            <p>
              The Yorke Construction Corporation website is a dynamic online
              platform showcasing the company's dedication to excellence,
              safety, and quality in the construction industry. The website
              effectively communicates the company's expertise, track record,
              and commitment to meeting clients' needs through its visually
              appealing design, comprehensive information, and user-friendly
              interface. Whether someone is seeking a construction partner or
              simply exploring the world of construction, the Yorke Construction
              Corporation website is a valuable resource that inspires
              confidence and ignites the imagination.{" "}
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
