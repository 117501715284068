import React from "react";
import { useState } from "react";
import ContactForm from "./form/ContactForm";

import USFlag from "../../assets/contact/us flag.png";

import "./form/contactForm.css";
import ContactLinks, { ContactLinks1 } from "./ContactLinks";
import PopUp from "./PopUp";
import { useEffect } from "react";

function ContactUs() {
  const [formActive, setFormActive] = useState(false);

  useEffect(() => {
    document.title = "Contact Us - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "Contact us to discuss a collaboration, get a free quote, or know more about our services.";
    document.getElementsByTagName("META")[6].content =
      "Contact Us - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "Contact us to discuss a collaboration, get a free quote, or know more about our services.";
    document.getElementsByTagName("META")[12].content =
      "Contact Us - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "Contact us to discuss a collaboration, get a free quote, or know more about our services.";
  }, []);

  return (
    <div className="contactus-form-container">
      <div className="top-container">
        <h1>Got a Proposal for Us?</h1>
        <p>
          We have been waiting to hear that you find us attractive and worthy of
          your projects. But before you hit that submit button, please do us a
          solid and include your website, industry, and contact deets. We
          promise it'll make our relationship that much stronger.
        </p>
      </div>
      <div className="inner-container">
        <div className="upper-container">
          <h2>Get in Touch with Us</h2>
          <p>
            We appreciate your consideration of HexamileSoft and the services we
            offer. To move forward with your project, kindly provide us with the
            details via the form provided or by email to{" "}
            <a href="mailto:hello@hexamilesoft.com">hello@hexamilesoft.com</a>{" "}
            We will respond to you as quickly as possible.
          </p>
        </div>
        <div className="bottom-container">
          <div className="form-wrapper">
            <ContactForm setFormActive={setFormActive} />
          </div>

          <div className="contact-detail">
            {/* <div className="upper">
              <p>USA Office:</p>
              <p>
                <span>368 9th Ave</span>
              </p>
              <p>
                <span>New York, NY 10001</span>
              </p>
              <a href="tel:+447700900835">
                <img src={USFlag} alt="usa" />
                <span>+44 770 090 0835</span>
              </a>
            </div>
            <hr /> */}
            <div className="below">
              <p>
                Office: <br />
                Development Centre/Unit,
              </p>
              <a
                href="https://goo.gl/maps/XKu4JxSSfJA3zWA2A"
                target={"_blank"}
                rel="noreferrer"
                className="adress"
              >
                Hussain Center 1st Floor,
                <br />
                Main Babar Chowk Near ABL,
                <br />
                Faisalabad, 38040
              </a>
              <p>
                Sales:
                <a href="tel:+923025504050" className="num">
                  <span> +92 302 5504050</span>
                </a>
              </p>
              <p>
                HR:
                <a href="tel:0092418549800" className="num">
                  <span> +92 41 8549800</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-link">
        <ContactLinks
          title={"Join The Team"}
          parag={
            "Join a team of highly skilled professionals to grow your expertise and take your career to the next level"
          }
          path={"/careers/job-openings"}
          linkText={"Search Job"}
        />
        <ContactLinks1
          title={"Media & Blog"}
          parag={
            "Experience the convenience of having all your media and blogging needs met in one place."
          }
          path={"http://hexamilesoft.com/stories/"}
          linkText={"Learn More"}
        />
        <ContactLinks
          title={"Why HexamileSoft"}
          parag={
            "Our no-nonsense approach to software development makes us stand-out. We cut through the jargon and deliver practical solutions to real-world problems."
          }
          path={"/about"}
          linkText={"Learn More"}
        />
      </div>

      <div className="popup-container">{formActive ? <PopUp /> : null}</div>
    </div>
  );
}

export default ContactUs;
