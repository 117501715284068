import React from "react";
import ServiceContact from "../../contact/ServiceContact";
import TrustPilot from "../../trust/TrustPilot";

import "../../pages/servicesSpare.css";
import ServiceHead from "../../top_head/ServiceHead";
import InnerHeading from "../../headings/InnerHeading";
import UpperContent from "../../upper_content/UpperContent";
import TrustedBy from "../../trusted_by_imgs/TrustedBy";
import StartProject from "../../start_project/StartProject";
import HireEngineers from "../../hire_engineers/HireEngineers";
import EngagementModel from "../../engagement_models/EngagementModel";
import WordPressClick from "./WordPressClick";
// import WpPrice from "./WpPrice";

// images
import WPD from "../../../../assets/service/services/WordPress Development.jpg";
import { useEffect } from "react";

function WordPressService() {
  const paragH1 =
    "At our company, a website appearance is crucial. We understand that a website should instill confidence in you and your customers rather than requiring an explanation for its outdated or unfinished state. We take the time to understand your goals for the website and then select the appropriate tools, technologies, and team to deliver the project to you on time and within budget.";

  const paragCon1 =
    "A visually appealing website may attract visitors, but the functionality keeps them engaged. We strive to understand your business objectives and tailor the best CMS systems to create a website that meets your specific needs and delivers results. We ensure that your website is visually pleasing but also responsive and user-friendly.";
  const paragCon2 =
    "Our team of WordPress developers specializes in creating clear, easy-to-use themes, websites, and plugins using the latest web development technologies. We design advanced yet user-friendly solutions tailored to your website's performance and responsiveness. We cater to various business types and market segments to provide WordPress solutions that work for you. Our services include the creation of WordPress CMS, plugin development, theme modification, and maintenance, all supported by state-of-the-art infrastructure and top-notch tools.";
  const paragCon3 =
    "At HexamileSoft, we strive to push the boundaries of innovation in our work. We prioritize product quality, sustainability, and a wide appeal to potential customers. While always adhering to industry standards, we strive to break the mold and create something unique and innovative. To cater to the growing mobile market, we prioritize designing mobile-first experiences and interfaces that work seamlessly on any device.";

  const startP =
    "At HexamileSoft, we're all about startups! It's in our DNA. We love new ideas and bringing them to life, and you'll see that in our hands-on approach to your project. From concept to launch, we're your go-to partner for all things startup. Let's make your ideas a reality! ";

  useEffect(() => {
    document.title = "WordPress Development - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "Trust us by providing fully functional websites built utilizing powerful WordPress, Joomla, and Drupal platforms.";
    document.getElementsByTagName("META")[6].content =
      "WordPress Development - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "Trust us by providing fully functional websites built utilizing powerful WordPress, Joomla, and Drupal platforms.";
    document.getElementsByTagName("META")[12].content =
      "WordPress Development - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "Trust us by providing fully functional websites built utilizing powerful WordPress, Joomla, and Drupal platforms.";
  }, []);

  return (
    <div className="services-container">
      <div className="heading-container">
        <ServiceHead
          title={"Effective Websites Driving Desired Results"}
          parag={"WordPress Solutions"}
        />
      </div>

      {/* InnerHeading */}
      <div className="title-heading-container">
        <div className="heading-wrapper">
          <InnerHeading
            titleH={
              "Experts in Developing Robust, Scalable, and Interactive WordPress Solutions"
            }
            paragH={paragH1}
          />
        </div>
        <hr />
        <div className="top-inner-container">
          <UpperContent
            imgCon={WPD}
            paragCon1={paragCon1}
            paragCon2={paragCon2}
            paragCon3={paragCon3}
            btnPath={"#wp"}
          />
        </div>
      </div>

      {/* trusted by */}
      <div className="trusted-by-container">
        <TrustedBy />
      </div>

      {/* click projects  */}
      <div className="click-projects-container">
        <WordPressClick btnPath={"#wp"} />
      </div>

      {/* price  */}
      {/* <div className="my-service-price-containe">
        <WpPrice btnPath={"#wp"} />
      </div> */}

      {/* hire engineer  */}
      <div className="hire-engineer-container">
        <HireEngineers
          engrName={"Hire Wordpress Developer"}
          engrPrice={"2200"}
          btnPath={"#wp"}
        />
        <EngagementModel />
      </div>

      {/* start your project  */}
      <div className="start-your-project-container">
        <StartProject
          titleProj={"Interested in Creating Something Awesome With Us?"}
          startP={startP}
          btnPath={"#wp"}
        />
      </div>

      {/* TrustPilot */}
      <div className="trust-pilot-wrap">
        <TrustPilot />
      </div>

      {/* ServiceContact */}
      <div className="service-contact-wrapper" id="wp">
        <ServiceContact />
      </div>
    </div>
  );
}

export default WordPressService;
