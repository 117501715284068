import React from "react";

import "../../pages/servicesSpare.css";

import TrustPilot from "../../trust/TrustPilot";
import ServiceContact from "../../contact/ServiceContact";
import ServiceHead from "../../top_head/ServiceHead";
import InnerHeading from "../../headings/InnerHeading";
import { UpperContentList } from "../../upper_content/UpperContent";
import TrustedBy from "../../trusted_by_imgs/TrustedBy";
import StartProject from "../../start_project/StartProject";
import HireEngineers from "../../hire_engineers/HireEngineers";
import EngagementModel from "../../engagement_models/EngagementModel";
import QAClick from "./QAClick";

// images
import MyImg from "../../../../assets/service/services/QA and TESTING.jpg";
import { useEffect } from "react";

function QATesting() {
  const paragH1 =
    "Horrible! That might be the end of the world for you. Software and App defects directly cast a negative impact on their popularity. Customers prefer to avoid products where they have to save their data every few minutes in fear that they might lose it. Similarly, a well-established, debugged, smooth-sailing product helps with recommendations and builds the client's trust in the developers.";

  const paragCon1 =
    "Quality Assurance (QA) and Testing prevent potential disasters by ensuring the final product is flawless and efficient. QA verifies product quality and customer satisfaction, while Testing focuses on identifying defects through effective routines, preventing the need for future fixes.";

  const startP =
    "At HexamileSoft, we're all about startups! It's in our DNA. We love new ideas and bringing them to life, and you'll see that in our hands-on approach to your project. From concept to launch, we're your go-to partner for all things startup. Let's make your ideas a reality! ";

  useEffect(() => {
    document.title = "QA and Testing - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "Our experts are always a click away from performing multi-stage, comprehensive testing and auditing of your Software to ensure the design is free of bugs and failures.";
    document.getElementsByTagName("META")[6].content =
      "QA and Testing - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "Our experts are always a click away from performing multi-stage, comprehensive testing and auditing of your Software to ensure the design is free of bugs and failures.";
    document.getElementsByTagName("META")[12].content =
      "QA and Testing - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "Our experts are always a click away from performing multi-stage, comprehensive testing and auditing of your Software to ensure the design is free of bugs and failures.";
  }, []);

  return (
    <div className="services-container">
      <div className="heading-container">
        <ServiceHead
          title={"Maintaining Quality and Standards"}
          parag={"Protecting You from Disasters"}
        />
      </div>

      {/* InnerHeading */}
      <div className="title-heading-container">
        <div className="heading-wrapper">
          <InnerHeading
            titleH={"Imagine Your Software Crashing At the Peak of its Fame!"}
            paragH={paragH1}
          />
        </div>
        <hr />
        <div className="top-inner-container">
          <UpperContentList
            imgCon={MyImg}
            paragCon1={paragCon1}
            paragCon2={""}
            paragCon3={""}
            btnPath={"#qa"}
          />
        </div>
      </div>

      {/* trusted by */}
      <div className="trusted-by-container">
        <TrustedBy />
      </div>

      {/* click projects  */}
      <div className="click-projects-container">
        <QAClick />
      </div>

      {/* hire engineer  */}
      <div className="hire-engineer-container">
        <HireEngineers
          engrName={"Hire QA and Testing Specialist"}
          engrPrice={"2200"}
          btnPath={"#qa"}
        />
        <EngagementModel />
      </div>

      {/* start your project  */}
      <div className="start-your-project-container">
        <StartProject
          titleProj={"Interested in Creating Something Awesome With Us?"}
          startP={startP}
          btnPath={"#qa"}
        />
      </div>

      {/* TrustPilot */}
      <div className="trust-pilot-wrap">
        <TrustPilot />
      </div>

      {/* ServiceContact */}
      <div className="service-contact-wrapper" id="qa">
        <ServiceContact />
      </div>
    </div>
  );
}

export default QATesting;
