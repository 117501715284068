import React from "react";
import { SimpleButon, SimpleButonId } from "../../../buttons/MyButon";

import "./upperContent.scss";

export default function UpperContent({
  imgCon,
  paragCon1,
  paragCon2,
  paragCon3,
  btnPath,
}) {
  return (
    <div className="my-content-container">
      <div className="left-container">
        <img src={imgCon} alt="side" />
      </div>
      <div className="right-container">
        <p>{paragCon1}</p>
        <p>{paragCon2}</p>
        <p>{paragCon3}</p>

        <div className="my-btn-click">
          <SimpleButonId btn={"Contact Us"} path={btnPath} />
        </div>
      </div>
    </div>
  );
}

export function UpperContentList({
  imgCon,
  paragCon1,
  paragCon2,
  paragCon3,
  btnPath,
}) {
  return (
    <div className="my-content-container">
      <div className="left-container">
        <img src={imgCon} alt="" />
      </div>
      <div className="right-container">
        <p>{paragCon1}</p>
        <p>{paragCon2}</p>
        <p>{paragCon3}</p>

        <h3>Accurate and Affordable</h3>
        <p>
          Yup! We are best at our rescue missions. Let your pesters rest while
          we ensure that your product works flawlessly and effectively with no
          future problems. Our testing practices, processes, and technology are
          top-notch to improve efficiency and accuracy and reduce risks, costs,
          and delivery time.
        </p>
        <p>Here's what we do to prove our words.</p>
        <ul>
          <li>Verify Product Quality and customer satisfaction</li>
          <li>Testing to identify defects</li>
          <li>Help ensure products meet specified standards</li>
          <li>Improve product reliability and usability</li>
          <li>Reduce the risk of product failures and customer complaints</li>
        </ul>

        <div className="my-btn-click">
          <SimpleButonId btn={"Contact"} path={btnPath} />
        </div>
      </div>
    </div>
  );
}
