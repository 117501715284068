import React from "react";
import HowWeWorkContent from "./HowWeWorkContent";

import { SimpleButon } from "../../../buttons/MyButon";
import ProcessFooter from "../ProcessFooter";

import IContent from "../../../assets/how_we_work/CONTENT.jpg";
import IDiscovery from "../../../assets/how_we_work/DISCOVERY.jpg";
import IStrategy from "../../../assets/how_we_work/STRATEGY.jpg";
import IDesign from "../../../assets/how_we_work/DESIGN.jpg";
import ISupport from "../../../assets/how_we_work/SUPPORT.jpg";
import ITandL from "../../../assets/how_we_work/TEST & LAUNCH.jpg";
import IDevelopment from "../../../assets/how_we_work/DEVELOPMENT.jpg";
import { useEffect } from "react";
import { useContext } from "react";
import { GlobalData } from "../../../../App";

function HowWeWork() {
  const paragDC1 =
    "We like to discuss and learn more about you before moving forward. Please share your organization's history, offering, and target market with us.";
  const paragDC2 =
    "Our relationship and ability to perform our work will improve as we gain a deeper understanding of your company.";
  const paragDC3 =
    "When you are satisfied (and only then), we will move on to the next stage once we have shared our ideas with you and developed a custom proposal. Have a thought? Why not gargle it up?";

  const paragStrg1 =
    "Before beginning any design or development work, we meticulously plan each project. Here, the user is our first concern; therefore, we must ensure that any designs we develop are usable, clear, and accessible.";
  const paragStrg2 =
    "We study your market, establish the necessary content, and design UX workflows to offer your users the best experience. That will only happen if we hurry in.";

  const paragCnt1 =
    'The much-heralded king, "content," then appears. We take the time to comprehend the purchasing habits of the targeted audience and provide material that incites their desire to make purchases, whether for a sophisticated enterprise online application or the e-commerce website of a well-known brand. In the meantime, we work on editorial style guides, SEO, branding, and voice.';

  const paragDesign1 =
    "Here, our ideas take shape after receiving the necessary verbalization. For you to always be aware of our path, we will work with you to develop various creative ideas while producing content and designing iteratively.";
  const paragDesign2 =
    "As needed, we develop interactive prototypes to explore concepts and potential. In this manner, we can guarantee that the final product is one you will like.";

  const paragDevl1 =
    "Design files, once authorized, are delivered to our team of excellent coders for development. At this point, we take some creative liberties to make the visuals stunning. Our expertise in both design and programming enables us to create excellent visuals and codes. You will receive updates at every stage of the process because we pledge to keep the process open, and the project will only move forward once you give your consent. That also applies to the writing of code.";
  const paragDevl2 =
    "Coming up with the code compatible with your project could take time, but we have you covered on this journey. Only after you have given your approval to the outline will the development begin.";
  const paragDevl3 =
    "We intend to make you appreciate the project’s all components, be they hidden or visible because they all contribute equally to a project's success.";

  const paragTaL1 =
    "We are aware of the value of testing. Although we make every effort to anticipate potential problems, we typically catch them all thanks to our complete testing procedure.";
  const paragTaL2 =
    "The launch can begin after everything has been verified and is ready. We serve as the anchor for your entire dotcom journey rather than just standing by and watching.";

  const paragSuprt1 =
    "In addition to maintaining, enhancing, and actively promoting your website, we maintain close relationships with all our clients.";
  const paragSuprt2 =
    'The story continues, and the hard work still needs to be done. All web and mobile apps experience a "bedding in" period.';
  const paragSuprt3 =
    "It's time to assess the effectiveness of your initiatives, correct any flaws they include, and make any necessary adjustments.";

  const { setColorProcess } = useContext(GlobalData);

  useEffect(() => {
    document.title = "How We Work - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "We work for the better of the best. Our goal is not to look at an idea or problem and work on it to make  'What it Was' but to envision 'What and How Better it Can Be.'";
    document.getElementsByTagName("META")[6].content =
      "How We Work - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "We work for the better of the best. Our goal is not to look at an idea or problem and work on it to make  'What it Was' but to envision 'What and How Better it Can Be.'";
    document.getElementsByTagName("META")[12].content =
      "How We Work - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "We work for the better of the best. Our goal is not to look at an idea or problem and work on it to make  'What it Was' but to envision 'What and How Better it Can Be.'";
  }, []);

  return (
    <div className="how-we-work-container">
      <div className="heading-container">
        <strong>From Idea to Execution</strong>
        <h1>We Have a Way of Working</h1>
        <p>
          Photocopies and pre-made projects are not something we do. We create
          original content. Therefore, we must first sit down and talk with you,
          get to know your business's goals and objectives, conduct our
          research, analyze the data, and then present you with our answer.
        </p>
        <div
          className="talk-btn"
          onClick={() => {
            setColorProcess(false);
          }}
        >
          <SimpleButon btn={"Let's Talk"} path={"/contact"} />
        </div>
      </div>
      <div className="content-container">
        <HowWeWorkContent
          title={"1- Discovery"}
          parag1={paragDC1}
          parag2={paragDC2}
          parag3={paragDC3}
          img={IDiscovery}
          btnText={"Let's Start Friendly Discussion"}
          path={"/contact"}
        />
        <HowWeWorkContent
          title={"2- Strategy"}
          parag1={paragStrg1}
          parag2={paragStrg2}
          parag3={null}
          img={IStrategy}
          btnText={"Let's Start Friendly Discussion"}
          path={"/contact"}
          direction={"row-reverse"}
        />
        <HowWeWorkContent
          title={"3- Content"}
          parag1={paragCnt1}
          parag2={""}
          parag3={null}
          img={IContent}
          btnText={"Let's Start Friendly Discussion"}
          path={"/contact"}
        />
        <HowWeWorkContent
          title={"4- Design"}
          parag1={paragDesign1}
          parag2={paragDesign2}
          parag3={null}
          img={IDesign}
          btnText={"Let's Start Friendly Discussion"}
          path={"/contact"}
          direction={"row-reverse"}
        />
        <HowWeWorkContent
          title={"5- Development"}
          parag1={paragDevl1}
          parag2={paragDevl2}
          parag3={paragDevl3}
          img={IDevelopment}
          btnText={"Let's Start Friendly Discussion"}
          path={"/contact"}
        />
        <HowWeWorkContent
          title={"6- Test & Launch"}
          parag1={paragTaL1}
          parag2={paragTaL2}
          parag3={null}
          img={ITandL}
          btnText={"Let's Start Friendly Discussion"}
          path={"/contact"}
          direction={"row-reverse"}
        />
        <HowWeWorkContent
          title={"7- Support"}
          parag1={paragSuprt1}
          parag2={paragSuprt2}
          parag3={paragSuprt3}
          img={ISupport}
          btnText={"Let's Start Friendly Discussion"}
          path={"/contact"}
        />
      </div>

      <div className="bottom-container">
        <ProcessFooter />
      </div>
    </div>
  );
}

export default HowWeWork;
