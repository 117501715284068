import React, { useState } from "react";
import { Link } from "react-router-dom";

import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { useContext } from "react";
import { FAQData } from "./Faq";

function Questions() {
  const [detail1, setdetail1] = useState({
    title: "What type of services do you cover?",
    para: "We are an IT company and provide all kinds of IT services, including but not limited to Game Development, Graphics, Web-Development, Product Photography, SEO Optimization, Content Writing, Social Media Management, Digital Marketing, and Mobile App Development.",
  });
  const [detail2, setdetail2] = useState({
    title: "What methodologies do you use?",
    para: "We take the time to understand our client's goals and requirements for the project and then employ appropriate tools, technologies, teams, and methodologies to deliver the project to our clients on time and within budget. We can explain our methodologies further if you specify the services you have in mind for which you are inquiring about our methodologies.",
  });
  const [detail3, setdetail3] = useState({
    title: "What will my website look like after you design it?",
    para: "At HexamileSoft, a website's appealing appearance is crucial for attracting visitors, and equally important is its functionality that keeps the visitors engaged. We put efforts into understanding your business objectives and tailoring the best CMS systems to create a website that meets your specific needs and delivers results. We ensure that your website is visually pleasing but also responsive and user-friendly.",
  });
  const [detail4, setdetail4] = useState({
    title: "How can I communicate with Hexamilesoft?",
    para: " Hexamilesoft has the following modes of communication telephone, Email, Whatsapp, Messenger, Chat, and Skype.",
  });
  const [detail5, setdetail5] = useState({
    title: "Why should I hire HexamileSoft?",
    para: " As your technical co-founder and extended development team, we take care of all technical aspects of your product development, allowing you to focus on other important areas, such as marketing and promoting your product. Moreover, we understand the concerns and uncertainties that customers may have before outsourcing their IT needs to a company. As a result, we provide risk-free guarantees, including data security, intellectual property protection, cost-effectiveness, and knowledge transfer.",
  });
  const [detail6, setdetail6] = useState({
    title: "How much experience do you have as a website design company?",
    para: "As a website design company, HexamileSoft has over eight years of experience in creating highly robust, feature-rich, and modular Drupal solutions that provide measurable technology benefits.",
  });
  const [detail7, setdetail7] = useState({
    title: "Can you SEO my website?",
    para: "As SEO experts, we use our technical, programming, and analytical skills to optimize your website's copy and landing pages for search engines. With extensive experience in keyword research, we identify and maximize keyword opportunities, reduce content repetition, and construct links for organic website growth. Trust us to improve your website’s organic search score with our professional SEO services to compete for the highest rankings.",
  });
  const [detail8, setdetail8] = useState({
    title: "Does HexamileSoft work with clients all over Pakistan?",
    para: "Yes, we work with clients around the world, including Pakistan. When it comes to choosing our clients, we prefer quality over quantity. Instead of chasing after lots of work, we prefer clients who are as ambitious and perfectionists as we are. We work with clients looking for innovation and marvel regardless of their size and type.",
  });
  const [detail9, setdetail9] = useState({
    title: "What is your development process and methodology?",
    para: " The projects we undertake are treated with utmost importance. We examine and research a project before quoting a figuring price to our client. We follow n invariable ethical and centric approach for each project task straight, with a set prescript and a well-defined delivery model delivery.",
  });
  const [detail10, setdetail10] = useState({
    title: "How can I be certain about your work quality?",
    para: " We allow our clients to oversee the project development process entirely. You can observe and review the material results of each phase of development. We also offer Status Reports. You can also go through the Status Report of your project. The entire scope of work is represented at the initial stage of development.",
  });

  const {
    q1,
    q2,
    q3,
    q4,
    q5,
    q6,
    q7,
    q8,
    q9,
    q10,
    setQ1,
    setQ2,
    setQ3,
    setQ4,
    setQ5,
    setQ6,
    setQ7,
    setQ8,
    setQ9,
    setQ10,
  } = useContext(FAQData);

  const setQuestions = (
    q1 = false,
    q2 = false,
    q3 = false,
    q4 = false,
    q5 = false,
    q6 = false,
    q7 = false,
    q8 = false,
    q9 = false,
    q10 = false
  ) => {
    setQ1(q1);
    setQ2(q2);
    setQ3(q3);
    setQ4(q4);
    setQ5(q5);
    setQ6(q6);
    setQ7(q7);
    setQ8(q8);
    setQ9(q9);
    setQ10(q10);
  };

  return (
    <>
      {/* q1 */}
      <div className="faq">
        <div className="faq-inner">
          <h3>{detail1.title}</h3>
          <Link
            onClick={() => {
              setQuestions(
                !q1,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
              );
            }}
          >
            {q1 ? (
              <AiOutlineMinusCircle className="icon" />
            ) : (
              <AiOutlinePlusCircle className="icon" />
            )}
          </Link>
        </div>
        {q1 ? <p>{detail1.para}</p> : null}
      </div>

      {/* q2 */}
      <div className="faq">
        <div className="faq-inner">
          <h3>{detail2.title}</h3>
          <Link
            onClick={() => {
              setQuestions(
                false,
                !q2,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
              );
            }}
          >
            {q2 ? (
              <AiOutlineMinusCircle className="icon" />
            ) : (
              <AiOutlinePlusCircle className="icon" />
            )}
          </Link>
        </div>
        {q2 ? <p>{detail2.para}</p> : null}
      </div>

      {/* q3 */}
      <div className="faq">
        <div className="faq-inner">
          <h3>{detail3.title}</h3>
          <Link
            onClick={() => {
              setQuestions(
                false,
                false,
                !q3,
                false,
                false,
                false,
                false,
                false,
                false,
                false
              );
            }}
          >
            {q3 ? (
              <AiOutlineMinusCircle className="icon" />
            ) : (
              <AiOutlinePlusCircle className="icon" />
            )}
          </Link>
        </div>
        {q3 ? <p>{detail3.para}</p> : null}
      </div>

      {/* q4 */}
      <div className="faq">
        <div className="faq-inner">
          <h3>{detail4.title}</h3>
          <Link
            onClick={() => {
              setQuestions(
                false,
                false,
                false,
                !q4,
                false,
                false,
                false,
                false,
                false,
                false
              );
            }}
          >
            {q4 ? (
              <AiOutlineMinusCircle className="icon" />
            ) : (
              <AiOutlinePlusCircle className="icon" />
            )}
          </Link>
        </div>
        {q4 ? <p>{detail4.para}</p> : null}
      </div>

      {/* q5 */}
      <div className="faq">
        <div className="faq-inner">
          <h3>{detail5.title}</h3>
          <Link
            onClick={() => {
              setQuestions(
                false,
                false,
                false,
                false,
                !q5,
                false,
                false,
                false,
                false,
                false
              );
            }}
          >
            {q5 ? (
              <AiOutlineMinusCircle className="icon" />
            ) : (
              <AiOutlinePlusCircle className="icon" />
            )}
          </Link>
        </div>
        {q5 ? <p>{detail5.para}</p> : null}
      </div>

      {/* q6 */}
      <div className="faq">
        <div className="faq-inner">
          <h3>{detail6.title}</h3>
          <Link
            onClick={() => {
              setQuestions(
                false,
                false,
                false,
                false,
                false,
                !q6,
                false,
                false,
                false,
                false
              );
            }}
          >
            {q6 ? (
              <AiOutlineMinusCircle className="icon" />
            ) : (
              <AiOutlinePlusCircle className="icon" />
            )}
          </Link>
        </div>
        {q6 ? <p>{detail6.para}</p> : null}
      </div>

      {/* q7 */}
      <div className="faq">
        <div className="faq-inner">
          <h3>{detail7.title}</h3>
          <Link
            onClick={() => {
              setQuestions(
                false,
                false,
                false,
                false,
                false,
                false,
                !q7,
                false,
                false,
                false
              );
            }}
          >
            {q7 ? (
              <AiOutlineMinusCircle className="icon" />
            ) : (
              <AiOutlinePlusCircle className="icon" />
            )}
          </Link>
        </div>
        {q7 ? <p>{detail7.para}</p> : null}
      </div>

      {/* q8 */}
      <div className="faq">
        <div className="faq-inner">
          <h3>{detail8.title}</h3>
          <Link
            onClick={() => {
              setQuestions(
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                !q8,
                false,
                false
              );
            }}
          >
            {q8 ? (
              <AiOutlineMinusCircle className="icon" />
            ) : (
              <AiOutlinePlusCircle className="icon" />
            )}
          </Link>
        </div>
        {q8 ? <p>{detail8.para}</p> : null}
      </div>

      {/* q9 */}
      <div className="faq">
        <div className="faq-inner">
          <h3>{detail9.title}</h3>
          <Link
            onClick={() => {
              setQuestions(
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                !q9,
                false
              );
            }}
          >
            {q9 ? (
              <AiOutlineMinusCircle className="icon" />
            ) : (
              <AiOutlinePlusCircle className="icon" />
            )}
          </Link>
        </div>
        {q9 ? <p>{detail9.para}</p> : null}
      </div>

      {/* q10  */}
      <div className="faq">
        <div className="faq-inner">
          <h3>{detail10.title}</h3>
          <Link
            onClick={() => {
              setQuestions(
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                !q10
              );
            }}
          >
            {q10 ? (
              <AiOutlineMinusCircle className="icon" />
            ) : (
              <AiOutlinePlusCircle className="icon" />
            )}
          </Link>
        </div>
        {q10 ? <p>{detail10.para}</p> : null}
      </div>
    </>
  );
}

export default Questions;
