import React from "react";
import { SimpleButonId } from "../../../buttons/MyButon";

import "./startProject.scss";

function StartProject({ titleProj, startP, btnPath }) {
  return (
    <div className="start-project-wrapper">
      <h2>Interested in Creating Something Awesome With Us?</h2>
      <p>
        At HexamileSoft, we're all about startups! It's in our DNA. We love new
        ideas and bringing them to life; you'll see that in our hands-on
        approach to your project. From concept to launch, we're your go-to
        partner for all things startup. Let's make your ideas a reality!
      </p>
      <div className="my-start-btn">
        <SimpleButonId btn={"Start your Project"} path={btnPath} />
      </div>
    </div>
  );
}

export default StartProject;
