import React, { useState } from "react";

import TP from "../../../assets/service/trustpilot/tp.jpg";
import Stars from "../../../assets/service/trustpilot/stars.jpg";

// import IMG1 from "../../../assets/service/trustpilot/img1.jpg";
import IMG1 from "../../../assets/service/trustpilot/1.png";
import IMGC1 from "../../../assets/service/trustpilot/color logos/1.png";

import IMG2 from "../../../assets/service/trustpilot/2.png";
import IMGC2 from "../../../assets/service/trustpilot/color logos/2.png";

import IMG3 from "../../../assets/service/trustpilot/3.png";
import IMGC3 from "../../../assets/service/trustpilot/color logos/3.png";

import IMG4 from "../../../assets/service/trustpilot/4.png";
import IMGC4 from "../../../assets/service/trustpilot/color logos/4.png";

import IMG5 from "../../../assets/service/trustpilot/5.png";
import IMGC5 from "../../../assets/service/trustpilot/color logos/5.png";

import IMG6 from "../../../assets/service/trustpilot/12.png";
import IMGC6 from "../../../assets/service/trustpilot/color logos/12.png";

import IMG7 from "../../../assets/service/trustpilot/11.png";
import IMGC7 from "../../../assets/service/trustpilot/color logos/11.png";

import IMG8 from "../../../assets/service/trustpilot/8.png";
import IMGC8 from "../../../assets/service/trustpilot/color logos/8.png";

import IMG9 from "../../../assets/service/trustpilot/9.png";
import IMGC9 from "../../../assets/service/trustpilot/color logos/9.png";

import IMG10 from "../../../assets/service/trustpilot/10.png";
import IMGC10 from "../../../assets/service/trustpilot/color logos/10.png";

import IMG11 from "../../../assets/service/trustpilot/13.png";
import IMGC11 from "../../../assets/service/trustpilot/color logos/13.png";

import IMG12 from "../../../assets/service/trustpilot/14.png";
import IMGC12 from "../../../assets/service/trustpilot/color logos/14.png";

import IMG13 from "../../../assets/service/trustpilot/15.png";
import IMGC13 from "../../../assets/service/trustpilot/color logos/15.png";

import IMG14 from "../../../assets/service/trustpilot/16.png";
import IMGC14 from "../../../assets/service/trustpilot/color logos/16.png";

import ImagesAround from "./ImagesAround";

function TrustPilot() {
  const [img1, setImg1] = useState(false);
  const [img2, setImg2] = useState(false);
  const [img3, setImg3] = useState(false);
  const [img4, setImg4] = useState(false);
  const [img5, setImg5] = useState(false);
  const [img6, setImg6] = useState(false);
  const [img7, setImg7] = useState(false);
  const [img8, setImg8] = useState(false);
  const [img9, setImg9] = useState(false);
  const [img10, setImg10] = useState(false);
  const [img11, setImg11] = useState(false);
  const [img12, setImg12] = useState(false);
  const [img13, setImg13] = useState(false);
  const [img14, setImg14] = useState(false);

  return (
    <div className="trustpilot-container">
      <div className="left-container">
        <div className="images-around">
          <ImagesAround
            classN="img1"
            imgB={IMG1}
            imgC={IMGC1}
            title="title"
            imgState={img1}
            setImgState={setImg1}
          />
          {/* <ImagesAround
            classN="img2"
            imgB={IMG2}
            imgC={IMGC2}
            title="title"
            imgState={img2}
            setImgState={setImg2}
          /> */}
          <ImagesAround
            classN="img3"
            imgB={IMG3}
            imgC={IMGC3}
            title="title"
            imgState={img3}
            setImgState={setImg3}
          />
          <ImagesAround
            classN="img4"
            imgB={IMG4}
            imgC={IMGC4}
            title="title"
            imgState={img4}
            setImgState={setImg4}
          />
          <ImagesAround
            classN="img5"
            imgB={IMG5}
            imgC={IMGC5}
            title="title"
            imgState={img5}
            setImgState={setImg5}
          />
          <ImagesAround
            classN="img6"
            imgB={IMG6}
            imgC={IMGC6}
            title="title"
            imgState={img6}
            setImgState={setImg6}
          />
          <ImagesAround
            classN="img7"
            imgB={IMG7}
            imgC={IMGC7}
            title="title"
            imgState={img7}
            setImgState={setImg7}
          />
          {/* <ImagesAround
            classN="img12"
            imgB={IMG12}
            imgC={IMGC12}
            title="title"
            imgState={img12}
            setImgState={setImg12}
          /> */}
          <ImagesAround
            classN="img14"
            imgB={IMG14}
            imgC={IMGC14}
            title="title"
            imgState={img14}
            setImgState={setImg14}
          />
        </div>

        <div className="box-container">
          <div className="head-img">
            <img src={TP} alt="trust pilot" />
          </div>
          <div className="rating">
            <h2>Excellent</h2>
            <div className="rating-img">
              <img src={Stars} alt="rating" />
            </div>
          </div>
          <hr />
          <div className="bottom-text">
            <p>
              This is the third time I hire this team and they are the fastest
              and best development team ever! Never lets me down and always
              understands my ideas and always puts in extra hours and works for
              free! BEST EVER!
            </p>
            <p>
              Durim P. Android Gaming application, | <span>Denmark</span>
            </p>
          </div>
        </div>

        <div className="images-around">
          <ImagesAround
            classN="img8"
            imgB={IMG8}
            imgC={IMGC8}
            title="title"
            imgState={img8}
            setImgState={setImg8}
          />
          <ImagesAround
            classN="img9"
            imgB={IMG9}
            imgC={IMGC9}
            title="title"
            imgState={img9}
            setImgState={setImg9}
          />
          <ImagesAround
            classN="img10"
            imgB={IMG10}
            imgC={IMGC10}
            title="title"
            imgState={img10}
            setImgState={setImg10}
          />
          {/* <ImagesAround
            classN="img11"
            imgB={IMG11}
            imgC={IMGC11}
            title="title"
            imgState={img11}
            setImgState={setImg11}
          /> */}
          <ImagesAround
            classN="img13"
            imgB={IMG13}
            imgC={IMGC13}
            title="title"
            imgState={img13}
            setImgState={setImg13}
          />
        </div>
      </div>
      <div className="right-container">
        <h2>
          Trusted By <span>Industry Leaders.</span>
        </h2>
        <p>
          We have gained the trust of global industry leaders because of our dedication to excellence and unwavering emphasis on providing high-quality software solutions. Our clients, who range from Fortune 500 organizations to cutting-edge startups, are confident in HexaMileSoft's ability to provide results that far exceed their expectations.
        </p>
        <p>In addition to developing software, HexaMileSoft is a strategic partner dedicated to advancing the success of its clients. We guarantee that we produce creative and cutting-edge solutions that foster corporate success by bringing a wealth of knowledge and expertise to every project.</p>
      </div>
    </div>
  );
}

export default TrustPilot;
