import React from "react";

import PY1 from "../../../../assets/work/mobile apps/movie trailor/Movie Trailer.jpg";

import { RxCross1 } from "react-icons/rx";
import { useContext } from "react";
import { WorkMenuData } from "../../OurWork";

function MoviesTrailerAppA() {
  const { setActive10 } = useContext(WorkMenuData);

  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActive10(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActive10(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>
          <div className="top-pop">
            <h2>Movies Trailer App</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Mobile App</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>Julia</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                MovieTrailers - Your Ultimate App for Film Enthusiasts
              </strong>
            </p>
            <p>
              MovieTrailers is a cutting-edge mobile application for movie
              lovers and enthusiasts. It offers a comprehensive platform to
              discover, watch, and stay up-to-date with upcoming movie trailers,
              teasers, and exclusive sneak peeks. With its intuitive interface
              and robust features, MovieTrailers ensures an immersive and
              convenient experience for users seeking a glimpse into the
              exciting world of cinema.
            </p>
            <p>
              MovieTrailers is the ultimate App for movie lovers, providing a
              comprehensive platform to explore, watch, and stay connected with
              upcoming films' latest trailers and teasers. With its extensive
              collection, personalized recommendations, release reminders, and
              social interaction features, MovieTrailers offers an immersive
              experience that enhances your passion for cinema. Download the App
              today and journey through the captivating world of movies. Lights,
              camera, action!
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoviesTrailerAppA;
