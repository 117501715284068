import { Route, Routes } from "react-router-dom";

import Footer from "./components/footer/Footer";
import About from "./components/pages/about_page/About";
import Faq from "./components/pages/about_page/faq/Faq";
import Home from "./components/pages/home_page/Home";
import Seo from "./components/pages/services_page/pages/seo_dev/Seo";
import MyHeader from "./components/header/my_header/MyHeader";
import { createContext } from "react";
import Career from "./components/pages/career_pages/career/Career";
import HondaProject from "./components/pages/single_project/HondaProject";
import WordPressService from "./components/pages/services_page/pages/wordpress_dev/WordpPress";
import FrontEnd from "./components/pages/services_page/pages/front_end_dev/FrontEnd";
import DedicatedDevelopment from "./components/pages/services_page/pages/dedicated_developer/DedicatedDevelopment";
import TopBar from "./components/header/my_header/TopBar";
import PrivacyPolicy from "./components/pages/privcy_policy/PrivacyPolicy";
import HandBook from "./components/pages/about_page/handbook/HandBook";
import OurWork from "./components/pages/work_portfolio/OurWork";
import QATesting from "./components/pages/services_page/pages/qa_and_testing/QATesting";
import ITCounsultancy from "./components/pages/services_page/pages/it_consultancy/ITCounsultancy";
import { useState } from "react";
import ContactUs from "./components/pages/contact_page/ContactUs";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import USER from "../src/components/assets/user.png";
import WhyUs from "./components/pages/process/why_us/WhyUs";
import HowWeWork from "./components/pages/process/how_we_work/HowWeWork";
import DistributedAgile from "./components/pages/process/distributed_agile/DistributedAgile";
import QualityManagementPage from "./components/pages/process/quality_management/QualityManagementPage";
import GotAnIdea from "./components/pages/services_page/pages/got_a_business_idea/GotAnIdea";
import MobileAppDev from "./components/pages/services_page/pages/mobile_app_development/MobileAppDev";
import SaasWebDev from "./components/pages/services_page/pages/saas_web_development/SaasWebDev";
import GameDev from "./components/pages/services_page/pages/game_dev/GameDev";
import EcommerceDev from "./components/pages/services_page/pages/ecommerce_dev/EcommerceDev";
import TechSupport from "./components/pages/services_page/pages/technical_support/TechSupport";
import LifeAtHexmile from "./components/pages/career_pages/life_at_hexamile/LifeAtHexmile";
import JobOpening from "./components/pages/career_pages/job_openening/JobOpening";
import CallRecorderAppA from "./components/pages/work_portfolio/mobile_app/call_recorder/CallRecorderAppA";
import EventReminder from "./components/pages/work_portfolio/mobile_app/event_reminder/EventReminder";
import GiftSuggestionAppA from "./components/pages/work_portfolio/mobile_app/gift_suggestion/GiftSuggestionAppA";
import ImageFinderAppA from "./components/pages/work_portfolio/mobile_app/image_finder/ImageFinderAppA";
import PorterYorkWeb from "./components/pages/work_portfolio/web_dev/porter_york/PorterYorkWeb";
import SparkleanCleaningWeb from "./components/pages/work_portfolio/web_dev/sparklean_cleaning/SparkleanCleaningWeb";
import YorkeConstructionWeb from "./components/pages/work_portfolio/web_dev/yorke_construction/YorkeConstructionWeb";
import ChefTableWeb from "./components/pages/work_portfolio/web_dev/chef_table/ChefTableWeb";
import XCPowerWeb from "./components/pages/work_portfolio/web_dev/xc_power/XCPowerWeb";
import MoviesTrailerAppA from "./components/pages/work_portfolio/mobile_app/movies_trailer/MoviesTrailerAppA";
import NotesAppA from "./components/pages/work_portfolio/mobile_app/notes_app/NotesAppA";
import ShoppingAppA from "./components/pages/work_portfolio/mobile_app/shopping_app/ShoppingAppA";
import HireDedicatedTeam from "./components/pages/services_page/pages/dedicated_team/HireDedicatedTeam";
import CookiePolicy from "./components/pages/cookie_policy/CookiePolicy";
import PopUpMessage from "./components/pages/popup_message/PopUpMessage";
import PageNotFound from "./components/pages/error/PageNotFound";

export const GlobalData = createContext();

function App() {
  const [active, setActive] = useState(false);
  const [color1, setColor1] = useState(false);
  const [color2, setColor2] = useState(false);
  const [color3, setColor3] = useState(false);
  const [color4, setColor4] = useState(false);
  const [color5, setColor5] = useState(false);
  const [colorCareer, setColorCareer] = useState(false);
  const [colorProcess, setColorProcess] = useState(false);
  const [colorDTeam, setColorDTeam] = useState(false);
  const [activeS, setActiveS] = useState(false);
  const [activeA, setActiveA] = useState(false);
  const [activeC, setActiveC] = useState(false);

  const [activeM, setActiveM] = useState(false);

  const hideDropDown = () => {
    setActiveS(false);
    setActiveA(false);
    setActiveC(false);
  };

  return (
    <GlobalData.Provider
      value={{
        color1: color1,
        setColor1: setColor1,
        color2: color2,
        setColor2: setColor2,
        color3: color3,
        setColor3: setColor3,
        color4: color4,
        setColor4: setColor4,
        color5: color5,
        setColor5: setColor5,
        colorCareer: colorCareer,
        setColorCareer: setColorCareer,
        colorProcess: colorProcess,
        setColorProcess: setColorProcess,
        colorDTeam: colorDTeam,
        setColorDTeam: setColorDTeam,
        activeA: activeA,
        setActiveA: setActiveA,
        activeS: activeS,
        setActiveS: setActiveS,
        activeC: activeC,
        setActiveC: setActiveC,
        hideDropDown: hideDropDown,
        activeM: activeM,
        setActiveM: setActiveM,
      }}
    >
      <PopUpMessage />
      <FloatingWhatsApp
        phoneNumber={+923025504050}
        accountName={"HexamileSoft"}
        avatar={USER}
        statusMessage={"Typically replies within a few min..."}
      />
      <TopBar />
      <MyHeader />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/contact" element={<ContactUs />} />

        <Route exact path="/about" element={<About />} />
        <Route exact path="/faq" element={<Faq />} />

        <Route
          exact
          path="services/got-a-business-idea"
          element={<GotAnIdea />}
        />
        {/* <Route
          exact
          path="services/hire-dedicated-team"
          element={<HireDedicatedTeam />}
        /> */}
        <Route
          exact
          path="services/mobile-app-development"
          element={<MobileAppDev />}
        />
        <Route
          exact
          path="services/web-development-company"
          element={<SaasWebDev />}
        />
        <Route exact path="services/game-development" element={<GameDev />} />
        <Route
          exact
          path="services/ecommerce-store-development"
          element={<EcommerceDev />}
        />
        <Route
          exact
          path="services/wordpress-development"
          element={<WordPressService />}
        />
        <Route
          exact
          path="services/frontend-development"
          element={<FrontEnd />}
        />
        <Route
          exact
          path="services/hire-dedicated-developer"
          element={<DedicatedDevelopment />}
        />
        <Route exact path="services/seo" element={<Seo />} />
        <Route exact path="services/qa-and-testing" element={<QATesting />} />
        <Route
          exact
          path="services/it-consultancy"
          element={<ITCounsultancy />}
        />
        <Route
          exact
          path="services/technical-support"
          element={<TechSupport />}
        />
        <Route exact path="careers/core-values" element={<Career />} />
        <Route
          exact
          path="careers/life-at-hexamile-soft"
          element={<LifeAtHexmile />}
        />
        <Route exact path="careers/job-openings" element={<JobOpening />} />

        <Route exact path="/portfolio/single" element={<HondaProject />} />

        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/cookie-policy" element={<CookiePolicy />} />

        <Route exact path="/handbook" element={<HandBook />} />

        <Route exact path="/work" element={<OurWork />} />

        <Route exact path="/process/whyhexamilesoft" element={<WhyUs />} />

        <Route exact path="/process/how-we-work" element={<HowWeWork />} />

        <Route
          exact
          path="process/distributed-agile"
          element={<DistributedAgile />}
        />
        <Route
          exact
          path="process/quality-management/"
          element={<QualityManagementPage />}
        />

        <Route
          exact
          path="portfolio/call-recorder-app"
          element={<CallRecorderAppA />}
        />
        <Route
          exact
          path="portfolio/event-reminder-app"
          element={<EventReminder />}
        />
        <Route
          exact
          path="portfolio/gift-suggestion-app"
          element={<GiftSuggestionAppA />}
        />
        <Route
          exact
          path="portfolio/image-finder"
          element={<ImageFinderAppA />}
        />
        <Route
          exact
          path="portfolio/movies-trailer"
          element={<MoviesTrailerAppA />}
        />
        <Route exact path="portfolio/notes-app" element={<NotesAppA />} />
        <Route
          exact
          path="portfolio/online-shopping-app"
          element={<ShoppingAppA />}
        />

        <Route
          exact
          path="portfolio/porter-and-york"
          element={<PorterYorkWeb />}
        />
        <Route
          exact
          path="portfolio/sparklean-clean"
          element={<SparkleanCleaningWeb />}
        />
        <Route
          exact
          path="portfolio/yorke-construction"
          element={<YorkeConstructionWeb />}
        />
        <Route exact path="portfolio/chef-table" element={<ChefTableWeb />} />
        <Route exact path="portfolio/xc-power" element={<XCPowerWeb />} />

        <Route exact path="/*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </GlobalData.Provider>
  );
}

export default App;

// npm i react-router-dom
// npm i sass
// npm i react-icons
// npm install react-bootstrap bootstrap
// npm i react-responsive-carousel
// npm i react-countup
// npm i react-floating-whatsapp
// npm install @emailjs/browser --save
// npm install react - responsive

// npm install--save react - google - recaptcha

// npm uninstall <package_name>
// npm uninstall reactjs-popup --save
