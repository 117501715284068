import React from "react";
import FooterIcon from "../assets/LogoMark.png";

import {
  FaFacebookSquare,
  FaWhatsappSquare,
  FaTwitterSquare,
  FaLinkedin,
} from "react-icons/fa";

import { MdLocationOn, MdMail } from "react-icons/md";

import { Link } from "react-router-dom";
import { GlobalData } from "../../App";
import { useContext } from "react";

function Footer() {
  const {
    setColor1,
    setColor2,
    setColor3,
    setColor4,
    setColor5,
    setColorCareer,
    setColorProcess,
    setActiveS,
    hideDropDown,
  } = useContext(GlobalData);

  const menuColors = (
    c1 = false,
    c2 = false,
    c3 = false,
    c4 = false,
    c5 = false,
    c6 = false,
    activeService = false
  ) => {
    setColor1(c1);
    setColor2(c2);
    setColor3(c3);
    setColor4(c4);
    setColor5(c5);
    setColorCareer(c6);
    setActiveS(activeService);
    setColorProcess(false);
    hideDropDown();
  };

  return (
    <div className="footer">
      {/* left div */}
      <div className="footer-container">
        <div className="company-con">
          <div className="company">
            <Link
              to={"/"}
              onClick={() => {
                menuColors(
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false
                );
              }}
            >
              <img src={FooterIcon} alt="HexamileSoft" />
              <h2>
                Hexamile<span>Soft</span>
                <p>(Solution-Simple-Fast-Secure)</p>
              </h2>
            </Link>

            <div className="social-icons">
              <a
                href="https://www.facebook.com/Challengepk/"
                target={"_blank"}
                rel="noreferrer"
              >
                <FaFacebookSquare />
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=923025504050"
                target={"_blank"}
                rel="noreferrer"
              >
                <FaWhatsappSquare />
              </a>
              <a
                href="https://twitter.com/hexamilesoft"
                target={"_blank"}
                rel="noreferrer"
              >
                <FaTwitterSquare />
              </a>
              <a
                href="https://www.linkedin.com/company/hexamilesoft/"
                target={"_blank"}
                rel="noreferrer"
              >
                <FaLinkedin />
              </a>
            </div>

            <div className="working-hours">
              <p>
                Working Hours
                <br /> Monday-Friday: 9:00 AM - 9:00 PM
              </p>
            </div>
          </div>
        </div>

        <div className="outer-wrapper">
          <div>
            {/* quick link  */}
            <div className="inner-wrapper">
              <h2>Quick Links</h2>
              <Link
                to={"/services/got-a-business-idea"}
                onClick={() => {
                  menuColors(
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false
                  );
                }}
              >
                Got A Business Idea
              </Link>
              <Link
                to={"/services/mobile-app-development"}
                onClick={() => {
                  menuColors(
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false
                  );
                }}
              >
                Mobile Development
              </Link>
              <Link
                to={"/services/game-development"}
                onClick={() => {
                  menuColors(
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false
                  );
                }}
              >
                Game Development
              </Link>
              <Link
                to={"/services/web-development-company"}
                onClick={() => {
                  menuColors(
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false
                  );
                }}
              >
                Web Development
              </Link>
              <Link
                to={"/services/wordpress-development"}
                onClick={() => {
                  menuColors(
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false
                  );
                }}
              >
                WordPress Development
              </Link>
              <Link
                to={"/services/frontend-development"}
                onClick={() => {
                  menuColors(
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false
                  );
                }}
              >
                Frontend Development
              </Link>
              {/* <Link
                to={"services/hire-dedicated-team"}
                onClick={() => {
                  menuColors(
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false
                  );
                }}
              >
                Hire Dedicated Team
              </Link> */}
            </div>
            {/* about  */}
            <div className="inner-wrapper">
              <h2>About</h2>
              <Link
                to={"/about"}
                onClick={() => {
                  menuColors(false, true, false, false, false, false, false);
                }}
              >
                About Us
              </Link>
              <Link
                to={"/work"}
                onClick={() => {
                  menuColors(false, false, false, false, true, false, false);
                }}
              >
                Our Work
              </Link>
              <Link
                to={"careers/job-openings"}
                onClick={() => {
                  menuColors(false, false, false, false, false, true, false);
                }}
              >
                We Are Hiring
              </Link>
              <Link
                to={"/faq"}
                onClick={() => {
                  menuColors();
                }}
              >
                FAQs
              </Link>
              <Link
                to={"/privacy-policy"}
                onClick={() => {
                  menuColors();
                }}
              >
                Privacy Policy
              </Link>
              <Link
                to={"/handbook"}
                onClick={() => {
                  menuColors();
                }}
              >
                Our Employee Handbook
              </Link>
              <Link
                to={"/cookie-policy"}
                onClick={() => {
                  menuColors();
                }}
              >
                Cookie Policy
              </Link>
            </div>

            {/* contact  */}
            <div className="inner-wrapper">
              <h2>Contact</h2>
              <div>
                <div>
                  <MdMail />
                </div>
                <a href="mailto:hello@hexamilesoft.com">
                  hello@hexamilesoft.com
                </a>
              </div>
              <div>
                <div>
                  <MdLocationOn />
                </div>
                <div className="my-contet">
                  <p>Development Centre/Unit:</p>
                  <a
                    href="https://goo.gl/maps/hjNn85ciwJm8JnW17"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Hussain Center 1st Floor,
                    <br />
                    Main Babar Chowk Near ABL,
                    <br />
                    Faisalabad, 38040
                  </a>
                </div>
              </div>
            </div>

            <div className="mobile-icons-container">
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/Challengepk/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <FaFacebookSquare />
                </a>
                <a
                  href="https://api.whatsapp.com/send/?phone=923025504050



                  "
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <FaWhatsappSquare />
                </a>
                <a
                  href="https://twitter.com/hexamilesoft"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <FaTwitterSquare />
                </a>
                <a
                  href="https://www.linkedin.com/company/hexamilesoft/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <FaLinkedin />
                </a>
              </div>

              <div className="working-hours">
                <p>
                  Working Hours
                  <br /> Monday-Friday: 9:00 AM - 9:00 PM
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      {/* copyright */}
      <div className="copy-right">
        <div>
          <span>Copyright © 2023 hexamilesoft.com All Rights Reserved.</span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
