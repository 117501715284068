import React from "react";
import { useContext } from "react";
import { GlobalData } from "../../../App";
import { SimpleButon } from "../../buttons/MyButon";

import "./processFooter.scss";

function ProcessFooter() {
  const { setColorProcess } = useContext(GlobalData);

  return (
    <div className="process-footer-container">
      <h2>Interested in Creating Something Awesome With Us?</h2>
      <p>
        At HexamileSoft, we're all about startups! It's in our DNA. We love new
        ideas and bringing them to life, and you'll see that in our hands-on
        approach to your project. From concept to launch, we're your go-to
        partner for all things startup. Let's make your ideas a reality!
      </p>
      <div className="btn-start" onClick={() => {setColorProcess(false)}}>
        <SimpleButon btn={"Let's Get Started"} path={"/contact"} />
      </div>
    </div>
  );
}

export default ProcessFooter;
