import React from "react";

import PY1 from "../../../../assets/work/web/project 1/1.png";

import { RxCross1 } from "react-icons/rx";
import { useContext } from "react";
import { WorkMenuData } from "../../OurWork";

function PorterYorkWeb() {
  const { setActive1 } = useContext(WorkMenuData);

  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActive1(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActive1(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>
          <div className="top-pop">
            <h2>Poter And York Website</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Web Design</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>Sarah</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                Porter and York is an online gourmet meat retailer website that
                provides high-quality, premium cuts of meat to customers across
                the United States. With a commitment to excellence and a focus
                on providing top-notch customer service, Porter and York have
                become a trusted name in the meat industry.
              </strong>
            </p>
            <p>
              The Porter and York website serves as a convenient platform for
              customers to explore and purchase their desired meat products from
              the comfort of their own homes. The website is well-designed and
              user-friendly, making it easy for customers to navigate and find
              the exact cuts of meat they are looking for. One of the key
              features of the Porter and York website is its extensive selection
              of meats. The website provides detailed information about each
              product, including its source, grading, and flavor profile. It
              allows customers to make informed decisions based on their
              specific requirements. Whether you're looking for a tender ribeye
              steak, succulent lamb chops, or juicy chicken breasts, Porter and
              York has you covered.
            </p>
            <p>
              The ordering process on the Porter and York website is
              straightforward. Customers can browse the various categories or
              use the search function to find their desired products. Once
              they've made their selections, they can proceed to the checkout
              page, where they can review their order and choose their preferred
              delivery options. Porter and York offer nationwide shipping,
              ensuring that customers from all corners of the country can enjoy
              their premium meats. The Porter and York website provides meat
              enthusiasts with a convenient and enjoyable shopping experience.
              With its wide selection of premium cuts, dedication to
              sustainability, and excellent customer service, Porter and York
              has established itself as a go-to destination for those seeking
              top-quality meats delivered straight to their doorsteps.
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PorterYorkWeb;

export function HomePorterYorkWeb({ setActiveP1 }) {
  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActiveP1(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActiveP1(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>
          <div className="top-pop">
            <h2>Poter And York Website</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Web Design</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>Sarah</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                Porter and York is an online gourmet meat retailer website that
                provides high-quality, premium cuts of meat to customers across
                the United States. With a commitment to excellence and a focus
                on providing top-notch customer service, Porter and York have
                become a trusted name in the meat industry.
              </strong>
            </p>
            <p>
              The Porter and York website serves as a convenient platform for
              customers to explore and purchase their desired meat products from
              the comfort of their own homes. The website is well-designed and
              user-friendly, making it easy for customers to navigate and find
              the exact cuts of meat they are looking for. One of the key
              features of the Porter and York website is its extensive selection
              of meats. The website provides detailed information about each
              product, including its source, grading, and flavor profile. It
              allows customers to make informed decisions based on their
              specific requirements. Whether you're looking for a tender ribeye
              steak, succulent lamb chops, or juicy chicken breasts, Porter and
              York has you covered.
            </p>
            <p>
              The ordering process on the Porter and York website is
              straightforward. Customers can browse the various categories or
              use the search function to find their desired products. Once
              they've made their selections, they can proceed to the checkout
              page, where they can review their order and choose their preferred
              delivery options. Porter and York offer nationwide shipping,
              ensuring that customers from all corners of the country can enjoy
              their premium meats. The Porter and York website provides meat
              enthusiasts with a convenient and enjoyable shopping experience.
              With its wide selection of premium cuts, dedication to
              sustainability, and excellent customer service, Porter and York
              has established itself as a go-to destination for those seeking
              top-quality meats delivered straight to their doorsteps.
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
