import React, { useEffect, useState } from "react";
import { SimpleButon } from "../../buttons/MyButon";
import HeroImg from "../../assets/home/email.png";
import MyServices from "./MyServices";
import OurOffers from "./OurOffers";
import Testimonials from "./Testimonials";
import MyProjects, { NewProjects } from "./MyProjects";

import ImgPre from "../../assets/social_media/card.jpg";

// service images
import TV from "../../assets/home/tv.png";
import PEN from "../../assets/home/pen.png";
import CPEN from "../../assets/home/c-pen.png";
import LIKE from "../../assets/home/like.png";
import SEO from "../../assets/home/seo.png";
import RPEN from "../../assets/home/r-pen.png";
import FEND from "../../assets/home/frontend.png";
import DTEAM from "../../assets/home/d-team.png";
import SEOO from "../../assets/home/s-e-o.png";
import QA from "../../assets/home/qa.png";
import ITC from "../../assets/home/it-c.png";
import TECHS from "../../assets/home/tech-s.png";

// light service icons
import LTV from "../../assets/home/light/pc.png";
import LPN from "../../assets/home/light/pen.png";
import LPEN from "../../assets/home/light/cross.png";
import LCPEN from "../../assets/home/light/penc.png";
import LLIKE from "../../assets/home/light/like.png";
import LSEO from "../../assets/home/light/grow.png";
import LFE from "../../assets/home/light/frontend.png";
import LDTEAM from "../../assets/home/light/d-team.png";
import LSEOO from "../../assets/home/light/seo.png";
import LITC from "../../assets/home/light/itc.png";
import LITS from "../../assets/home/light/its.png";
import LQA from "../../assets/home/light/qa.png";

// offers images
import Service1 from "../../assets/home/service-1.png";
import Service2 from "../../assets/home/service-2.png";
import Service3 from "../../assets/home/service-3.png";
import Service4 from "../../assets/home/service-4.png";
import Service5 from "../../assets/home/service-5.png";
import Service6 from "../../assets/home/service-6.png";

// project
import Pro1 from "../../assets/work/web/project 1/1.png";
import Pro2 from "../../assets/work/web/project 2/1.png";
import Pro3 from "../../assets/work/web/project 3/1.png";
import Pro4 from "../../assets/work/web/project 4/1.png";

// Testimonial images
import UserPic from "../../assets/career/user.png";

// Testimonials cerosel
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// projects list
import Solutions from "./solutions_page/Solutions";
import Industry from "./solutions_page/Industry";
import Platforms from "./platforms/Platforms";

import "./homecss.css";
import LogoSection from "./LogoSection";
import PorterYorkWeb, {
  HomePorterYorkWeb,
} from "../work_portfolio/web_dev/porter_york/PorterYorkWeb";
import { HomeSparkleanCleaningWeb } from "../work_portfolio/web_dev/sparklean_cleaning/SparkleanCleaningWeb";
import { HomeYorkeConstructionWeb } from "../work_portfolio/web_dev/yorke_construction/YorkeConstructionWeb";
import { HomeChefTableWeb } from "../work_portfolio/web_dev/chef_table/ChefTableWeb";

function Home() {
  const [activeP1, setActiveP1] = useState(false);
  const [activeP2, setActiveP2] = useState(false);
  const [activeP3, setActiveP3] = useState(false);
  const [activeP4, setActiveP4] = useState(false);

  // service text
  const ServiceText1 =
    "We understand the challenges involved in building a startup product. Do not fret anymore and tell us your requirements. Let us do the hard work for you.";
  const ServiceText2 =
    "Get your ideal iOS, Android, and Windows apps built efficiently.";
  const ServiceText3 =
    "We use MVC frameworks with rich frontends to develop SaaS and cloud-based apps effectively and rapidly.";
  const ServiceText4 =
    "We bring your ideas to life with our artistic and technical skills to bring the games from development to completion.";
  const ServiceText5 =
    "Get our services to Design, Build, and help operate an online store or marketplace such as Magneto 2.0, Shopify, Volusion, Big Commerce, 3Dcart, and Wix.";
  const ServiceText6 =
    "Trust us by providing fully functional websites built utilizing powerful WordPress, Joomla, and Drupal platforms.";
  const ServiceText7 =
    "We will architect and build the design provided in a simple format and deliver pixel-perfect HTML, Bootstrap, and Email templates with a money-back guarantee.";
  const ServiceText8 =
    "Trust our highly skilled team, comprising Web, Mobile, and SaaS developers, with your next project.";
  const ServiceText9 =
    "Improve your website’s organic search score with our professional SEO services to compete for the highest rankings.";
  const ServiceText10 =
    "Our experts are always a click away from performing multi-stage, comprehensive testing and auditing of your Software. They ensure that the design meets your expectations and is free of bugs or failures.";
  const ServiceText11 =
    "Turn to our IT consultants to help you eliminate workflow pain points, implement new technological solutions, and consolidate app portfolios.";
  const ServiceText12 =
    "Our support team ensures non-stop monitoring and technical assistance to resolve detected and reported problems in your system.";

  // we offer paragraphs
  const offer1 =
    "We work for the better of the best. Our goal is not to look at an idea or problem and work on it to make 'What it Was' but to envision 'What and How Better it Can Be.' Our working approach is completely based on this mentality. We no longer solely receive \"design briefs\" from our clients. They come to us with advanced problems and requirements - tasks that deserve future-proof solutions only. We take pride in challenging conventional assumptions and creating futuristic solutions across the Digital Ecosystem.";
  const offer2 =
    "We don’t do copy-pastes. HexamileSoft is a digital agency passionate about great ideas, tiny details, and a better approach (agile development) to creating world-class work. Our works stand out from many others due to our perfect delivery and attention to detail. We call it digital tailoring created around the modern Agile Development Methodologies.";
  const offer3 =
    "There is more to websites than pretty shapes and cute colors. They are designed and developed with a purpose. That’s the prime reason we believe in function before form. We follow the highest web standards and proven practices to create Websites, Mobile Apps, and eCommerce stores that fulfill their ‘purpose’ and remain gorgeous to look at.";
  const offer4 =
    "Picking the right technology for delightful user experiences and interaction requires deep insights into tech, potential users, and business needs. We leverage the potential of new technologies to take what was once impossible and make it a reality. Our deep expertise spans a diverse range of software and coding technologies, which we leverage early on within the innovation process to push the limits of ideas, optimize validation and accelerate time-to-market.";
  const offer5 =
    "Your mighty ideas deserve apt brains to get on. We are a close-knit family of talented and driven people that strive to deliver quality and honesty. We’ve proven that just 50 or so competent people working with the right tools is all it takes to outperform larger organizations which are often more concerned with their pecking order than their productivity.";
  const offer6 =
    "When it comes to choosing our clients, we prefer quality over quantity. Instead of chasing after lots of work, we prefer clients who are as ambitious and perfectionists as we are. We work with clients looking for innovation and marvel regardless of their size and type. Our team collaborates with some of the best creative agencies around the world. Moreover, we are also open to smaller startups and entrepreneurs.";

  // Testimonials
  const testi1 =
    "\" They're not just another contractor; they're rather an expansion of my in-house marketing team who is always there to idealize my IT endeavors. I couldn't be happier with the level of service, support and commitment I’ve received from HexamileSoft. \"";
  const testi2 =
    '"Best Workers. Have worked a couple of times with these guys before and they always exceed expectations. Thanks again Hexamilesoft"';
  const testi3 =
    '"Hexamilesoft did an excellent very communicative and expressive work. I liked the quality of the work surprised me because it added a lot to the project. I recommend to all employers, Very Good!"';

  useEffect(() => {
    document.title = "Hexamilesoft | Solution-Simple-Fast-Secure";
    document.getElementsByTagName("META")[3].content =
      "We are a small IT company catering to all your IT needs, from Development and QA to Optimizations, all under one roof. ";
    document.getElementsByTagName("META")[6].content =
      "Hexamilesoft | Solution-Simple-Fast-Secure ";
    document.getElementsByTagName("META")[7].content =
      "We are a small IT company catering to all your IT needs, from Development and QA to Optimizations, all under one roof. ";
    document.getElementsByTagName("META")[12].content =
      "Hexamilesoft | Solution-Simple-Fast-Secure";
    document.getElementsByTagName("META")[13].content =
      "We are a small IT company catering to all your IT needs, from Development and QA to Optimizations, all under one roof. ";
  }, []);

  return (
    <>
      <div className="home-container">
        <section className="home-hero">
          <div className="home-wrapper">
            <div className="left-side">
              <h1>
                Hi <span>there!</span>
              </h1>
              <p>
                We are HexaMileSoft. We leverage cutting-edge technology to
                develop web-based solutions and specialized products catering to
                niche market segments. Our goal is to enhance the online
                experiences of industry experts and enthusiasts alike.
              </p>
              <div className="btn">
                <SimpleButon btn={"Learn More"} path={"/work"} />
              </div>
              <p>
                We are your tech experts and designers. We have a massive
                experience with startups, automation of business processes, and
                building an online presence. Got a Business Idea? Let's discuss
                this with our top-quality team lead developers and project
                managers; they will quickly solve your problem.
              </p>
              <p className="ask">Have a project in mind?</p>
              <div className="btn">
                <SimpleButon path="/contact" btn="Let's Connect" />
              </div>
            </div>
            <div className="right-side">
              <img src={HeroImg} alt="email" />
            </div>
          </div>
        </section>

        {/* services  */}
        <div className="service-wrapper">
          <div>
            <MyServices
              heading="Got a Business Idea?"
              logo={TV}
              path={"services/got-a-business-idea"}
              text={ServiceText1}
              lLogo={LTV}
            />
            <MyServices
              heading="Mobile Apps Development"
              logo={PEN}
              path={"services/mobile-app-development"}
              text={ServiceText2}
              lLogo={LPN}
            />
            <MyServices
              heading="SaaS / Web Development"
              logo={CPEN}
              path={"services/web-development-company"}
              text={ServiceText3}
              lLogo={LCPEN}
            />
            <MyServices
              heading="Game Development"
              logo={LIKE}
              path={"services/game-development"}
              text={ServiceText4}
              lLogo={LLIKE}
            />
            <MyServices
              heading="eCommerce Development"
              logo={SEO}
              path={"services/ecommerce-store-development"}
              text={ServiceText5}
              lLogo={LSEO}
            />
            <MyServices
              heading="WordPress Development"
              logo={RPEN}
              path={"services/wordpress-development"}
              text={ServiceText6}
              lLogo={LPEN}
            />
            <MyServices
              heading="Frontend Development"
              logo={FEND}
              path={"services/frontend-development"}
              text={ServiceText7}
              lLogo={LFE}
            />
            <MyServices
              heading="Dedicated Team"
              logo={DTEAM}
              path={"services/hire-dedicated-developer"}
              text={ServiceText8}
              lLogo={LDTEAM}
            />
            <MyServices
              heading="Search Engine Optimization (SEO)"
              logo={SEOO}
              path={"services/seo"}
              text={ServiceText9}
              lLogo={LSEOO}
            />
            <MyServices
              heading="QA & Testing"
              logo={QA}
              path={"services/qa-and-testing"}
              text={ServiceText10}
              lLogo={LQA}
            />
            <MyServices
              heading="IT Consultancy"
              logo={ITC}
              path={"services/it-consultancy"}
              text={ServiceText11}
              lLogo={LITC}
            />
            <MyServices
              heading="Technical Support"
              logo={TECHS}
              path={"services/technical-support"}
              text={ServiceText12}
              lLogo={LITS}
            />
          </div>
        </div>

        {/* peojejcts */}
        <div className="projects-wrapper">
          <h2>Our Work Speaks For Itself</h2>
          <p>
            Check out our latest work in UI/UX design, Mobile & Web app
            development.
          </p>
          <hr />
          <div className="inner-wrapper">
            <NewProjects
              project={Pro1}
              name="Porter And York"
              type="Web"
              setActive={setActiveP1}
            />
            <NewProjects
              project={Pro2}
              name="Sparklean Cleaning Services"
              type="web"
              setActive={setActiveP2}
            />
            <NewProjects
              project={Pro3}
              name="Yorke Construction Corporation"
              type="web"
              setActive={setActiveP3}
            />
            <NewProjects
              project={Pro4}
              name="Chef's Table"
              type="web"
              setActive={setActiveP4}
            />
          </div>
        </div>
        <>
          {activeP1 && <HomePorterYorkWeb setActiveP1={setActiveP1} />}
          {activeP2 && <HomeSparkleanCleaningWeb setActiveP2={setActiveP2} />}
          {activeP3 && <HomeYorkeConstructionWeb setActiveP3={setActiveP3} />}
          {activeP4 && <HomeChefTableWeb setActiveP4={setActiveP4} />}
        </>

        {/* offers  */}
        <div className="our-offers-wrapper">
          <div className="wrapper">
            <h2>What Makes US Unique?</h2>
            <hr />
            <p>
              Our team of experienced UX designers, Software engineers, App
              developers, and Digital Strategists makes us confident that we can
              cater to all your business needs. As a digital agency, we focus on
              providing customized services to our clients. We are here to
              listen to you attentively from the first meeting until the
              solution is delivered and even after; to find out exactly what you
              need. This way, we can pinpoint the customer's requirements and
              make the interaction the best possible experience. We combine your
              requirements with our expertise and skills to deliver an
              outstanding solution that caters perfectly to your needs.
            </p>
          </div>
          <OurOffers
            Img={Service1}
            Heading="Advanced Approach"
            offer={offer1}
          />
          <OurOffers
            Img={Service2}
            Heading="Customized Services"
            Direction="row-reverse"
            offer={offer2}
          />
          <OurOffers Img={Service3} Heading="Purpose Oriented" offer={offer3} />
          <OurOffers
            Img={Service4}
            Heading="Innovative Solutions"
            Direction="row-reverse"
            offer={offer4}
          />
          <OurOffers
            Img={Service5}
            Heading="Competent and Mighty"
            offer={offer5}
          />
          <OurOffers
            Img={Service6}
            Heading="Satisfied Clients"
            Direction="row-reverse"
            offer={offer6}
          />
        </div>

        {/* solutions we provide  */}
        {/* <div className="solutions-wrapper">
        <Solutions />
      </div> */}

        <div className="line">
          <span></span>
        </div>

        {/* button */}
        <div className="get-in-touch-btn">
          <div>
            <SimpleButon path="/contact" btn="Get In Touch" />
          </div>
        </div>

        {/* industry */}
        {/* <div className="industry-wrapper">
        <Industry />
      </div> */}

        <div className="platforms-wrapper">
          <div className="upper-wrapper">
            <h2>Technologies & Platforms We Work With What We Actually Do</h2>
          </div>
          <Platforms />
        </div>

        {/* testimonials */}
        <div className="testimonial-wrapper">
          <h2>What Clients Say?</h2>
          <Carousel
            showThumbs={false}
            infiniteLoop
            autoPlay={true}
            showStatus={false}
            // renderIndicator={false}
            showArrows={false}
          >
            <Testimonials
              pPhoto={UserPic}
              parag={testi1}
              name="- Suleman A"
              role="IT Entrepreneur"
              company="Mobile App"
              country="Pakistan"
            />
            <Testimonials
              pPhoto={UserPic}
              parag={testi2}
              name="- James M."
              role="IT Entrepreneur"
              company="Web App"
              country="UK"
            />
            <Testimonials
              pPhoto={UserPic}
              parag={testi3}
              name="- Welton R."
              role="IT Entrepreneur"
              company="Mobile App"
              country="UK"
            />
          </Carousel>
        </div>

        <div className="logo-section">
          <LogoSection />
        </div>
      </div>
    </>
  );
}

export default Home;
