import React from "react";

function OurOffers({ Img, Heading, Direction, offer }) {
  // const [active, setActive] = useState(false);

  return (
    <div className="offers-container" style={{ flexDirection: Direction }}>
      <img src={Img} alt={Heading} />
      <div className="offer-wrapper">
        <h3>{Heading}</h3>
        <hr />
        <p>{offer}</p>
      </div>
    </div>
  );
}

export default OurOffers;
