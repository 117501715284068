import React from "react";
import { useState } from "react";

import "../allClickProjects.scss";

import { GiAbstract005 } from "react-icons/gi";

import { BiCaretRight } from "react-icons/bi";
import { SimpleButon } from "../../../../buttons/MyButon";

function ECommerceClick() {
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);
  const [active7, setActive7] = useState(false);
  const [active8, setActive8] = useState(false);

  const activeHandler = (
    a1 = true,
    a2 = false,
    a3 = false,
    a4 = false,
    a5 = false,
    a6 = false,
    a7 = false,
    a8 = false
  ) => {
    setActive1(a1);
    setActive2(a2);
    setActive3(a3);
    setActive4(a4);
    setActive5(a5);
    setActive6(a6);
    setActive7(a7);
    setActive8(a8);
  };

  return (
    <div className="inner-project-wrapper">
      <div className="heading">
        <h2>Everything You Need For e-Commerce</h2>
        <p>
          Transform occasional online buyers into dedicated patrons. Discover
          how we can assist you in creating a seamless online shopping
          experience, from search to final purchase.
        </p>
        <hr />
      </div>

      <div className="select-projects-container">
        <div className="left-side">
          {/* Feature-Rich E-commerce */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler();
            }}
            style={{ backgroundColor: active1 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Feature-Rich E-commerce</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Responsive Layout */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                false
              );
            }}
            style={{ backgroundColor: active2 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Responsive Layout</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Product Catalog */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false
              );
            }}
            style={{ backgroundColor: active3 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Product Catalog</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Order Management */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false
              );
            }}
            style={{ backgroundColor: active4 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Order Management</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Secure Checkout, Payment, and Shipping */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false
              );
            }}
            style={{ backgroundColor: active5 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Secure Checkout, Payment, and Shipping</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Third-Party Integrations */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false
              );
            }}
            style={{ backgroundColor: active6 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Third-Party Integrations</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Reviews & Ratings */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false
              );
            }}
            style={{ backgroundColor: active7 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Reviews & Ratings</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Analytics & Reporting */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true
              );
            }}
            style={{ backgroundColor: active8 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Analytics & Reporting</span>
            </div>
            <BiCaretRight />
          </div>
        </div>

        {/* content  */}
        <div className="right-side">
          {/* Feature-Rich E-commerce */}
          {active1 && (
            <div className="mydiv">
              <h3>Feature-Rich e-Commerce</h3>
              <p>
                We offer the commerce tools necessary to design, market, and
                administer a captivating and responsive website. Please have a
                look at our services.
              </p>
              <ul>
                <li>Back office Administration</li>
                <li>Catalog Management</li>
                <li>Order Management</li>
                <li>Customer Accounts</li>
                <li>Checkout, Payments, and Shipping</li>
                <li>Analytics and Reporting</li>
                <li>Marketing Promotion Tools</li>
                <li>Search Engine Optimization</li>
              </ul>
            </div>
          )}

          {/* Responsive Layout */}
          {active2 && (
            <div className="mydiv">
              <h3>Responsive Layout</h3>
              <p>
                A tailored user interface and effortless navigation ensure a
                consistent brand experience across all devices.
              </p>
              <p>
                As experts in Magneto e-Commerce, our creative team deeply
                understands the specific design requirements of e-Commerce
                platforms. Utilizing our Responsive e-commerce Design ensures
                seamless integration of your channels, providing an optimal
                brand experience across all customer touchpoints and devices.
                Our e-Commerce designs are specifically crafted to adapt
                seamlessly to any device.
              </p>
              <p>Here are some features of our e-Commerce designs:</p>
              <ul>
                <li>Fully responsive</li>
                <li>Resolution independence</li>
                <li>iPhone/iPad/Android/Windows supported</li>
                <li>Sleek UI/UX</li>
                <li>Customer Accounts</li>
                <li>Dynamic Product Displays</li>
                <li>Product Image Enhancements</li>
                <li>Fast Checkouts</li>
              </ul>
            </div>
          )}

          {/* Product Catalog */}
          {active3 && (
            <div className="mydiv">
              <h3>Product Catalog</h3>
              <p>
                Our e-Commerce solutions are designed to be robust, allowing you
                to upload an unlimited number of products to your website
                easily. The bulk edit feature makes it easy to manage your
                products with minimal effort.
              </p>
              <p>
                Having a well-constructed product catalog is crucial for the
                growth of your business. Our tailored catalogs are designed to
                be flexible to suit your needs, and they can handle multi-level
                product categorization, enable custom product layouts, and
                include the ability to add new products as your business
                expands. Furthermore, we can include a tier-based pricing system
                that allows you to offer incentives to your top customers,
                encouraging repeat business.
              </p>
              <p>Take a look at our services in this regard:</p>
              <ul>
                <li>
                  Advanced inventory management with support for back-ordered
                  items, minimum and maximum quantities
                </li>
                <li>Batch import and export of product catalogs</li>
                <li>In-admin panel batch editing of products</li>
                <li>Integration with Google Base for easy product listing</li>
                <li>
                  Support for simple and configurable products with options such
                  as size, color, etc
                </li>
                <li>Bundled and grouped product options</li>
                <li>
                  Tax rate customization based on location, customer group, and
                  product type
                </li>
                <li>
                  Attribute sets for easy and efficient creation of various
                  product types
                </li>
                <li>Advanced pricing rules and special pricing options</li>
                <li>
                  Moderation of customer reviews, including approve, edit and
                  delete options
                </li>
                <li>Low inventory alerts via RSS feed</li>
                <li>Personalized product options for customers</li>
                <li>
                  Sorting options on product category pages based on factors
                  such as price and brand.
                </li>
              </ul>
            </div>
          )}

          {/* Order Management */}
          {active4 && (
            <div className="mydiv">
              <h3>Order Management</h3>
              <ul>
                <li>
                  Comprehensive order management in the admin panel, including
                  viewing, editing, creating, and fulfilling orders
                </li>
                <li>
                  Flexibility to generate one or multiple invoices, shipments,
                  and credit memos per order to support split fulfillment
                </li>
                <li>
                  Printable invoices and packing slips for easy order tracking
                </li>
                <li>
                  Convenient call center support for creating orders over the
                  phone
                </li>
                <li>
                  Option to create new customers or select existing customers,
                  with the ability to view their shopping cart and wish list
                </li>
                <li>
                  Effortless re-ordering for customers directly from the admin
                  panel
                </li>
                <li>
                  Real-time email notifications for new orders, allowing quick
                  and efficient order tracking
                </li>
              </ul>
            </div>
          )}

          {/* Secure Checkout, Payment, and Shipping */}
          {active5 && (
            <div className="mydiv">
              <h3>Secure Checkout, Payment, and Shipping</h3>
              <p>
                Our sites have various payment gateways, including PayPal,
                Google Wallet, Authorize.net, First Data, Braintree, and Stripe.
                We also employ secure encryption for credit card and order data
                to safeguard your customers' personal information. The checkout
                process is simple and user-friendly, with a one-page online
                checkout.
              </p>
              <p>The following are our key features:</p>
              <ul>
                <li>
                  Real-time shipping rate integration with UPS®, FedEx®, and
                  USPS®
                </li>
                <li>
                  Support for a variety of payment options including Amazon
                  Payments, PayPal, Authorize.net, and Google Checkout
                </li>
                <li>
                  Ability to accept a variety of payment methods such as checks,
                  money orders, and online purchase orders
                </li>
                <li>
                  SSL security support for all online orders and sensitive
                  transactions
                </li>
                <li>
                  Online tax and shipping calculation, as well as the option to
                  provide estimates before checkout
                </li>
                <li>
                  Option for customers to create an account during the online
                  checkout process
                </li>
                <li>
                  Configurable saved cart expiration to suit your business needs
                </li>
                <li>
                  Online order tracking for customers through their account
                </li>
                <li>
                  Flexibility to offer both pre-order and per-item flat-rate
                  shipping options
                </li>
                <li>
                  Free shipping functionality for promotions and special offers
                </li>
                <li>
                  Shipping management based on weight and destination for
                  accurate cost calculation.
                </li>
              </ul>
            </div>
          )}

          {/* Third-Party Shopping Cart and ERP Integrations */}
          {active6 && (
            <div className="mydiv">
              <h3> Third-Party Shopping Cart and ERP Integrations</h3>
              <p>
                Our experts specialize in integrating your e-commerce platform
                with various third-party applications, streamlining the checkout
                process for your customers. We automate shipping rates and
                compute sales tax, making the checkout process faster and more
                efficient. Additionally, we utilize email links for abandoned
                shopping carts to recover lost sales.
              </p>
              <p>
                We also integrate with leading warehouse management systems such
                as MAS 200, Microsoft Nav, Stone Edge, and QuickBooks to enhance
                financial management, order fulfillment, and customer service.
                Lastly, we incorporate stovepipe systems to help you meet your
                business needs.
              </p>
            </div>
          )}

          {/* Reviews & Ratings */}
          {active7 && (
            <div className="mydiv">
              <h3>Customer Feedback</h3>
              <p>
                Enhance the authenticity of your e-commerce site by
                incorporating customer reviews and ratings for all of your
                products. Positive reviews and high ratings not only build trust
                in your brand but also increase conversions by creating a sense
                of credibility and reliability.
              </p>
            </div>
          )}

          {/* Analytics and Reports  */}
          {active8 && (
            <div className="mydiv">
              <h3>Analytics and Reports</h3>
              <p>
                Customized User Interface, Intuitive Navigation, and Consistent
                Brand Experience Across All Devices
              </p>

              <ul>
                <li>
                  Google Analytics integration for in-depth website analysis
                </li>
                <li>Admin report dashboard with business overview</li>
                <li>Sales reports including total sales and returns</li>
                <li>Tax reports</li>
                <li>Abandoned shopping cart reports</li>
                <li>Best-viewed products report</li>
                <li>Top-sold products report</li>
                <li>Low stock item report</li>
                <li>Onsite search terms report</li>
                <li>Product reviews report with RSS support</li>
                <li>Tags report with RSS support</li>
                <li>Coupon usage report</li>
                <li>Advanced Reporting and Analytics</li>
                <li>Improved Business Insights</li>
                <li>Customizable Reports</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ECommerceClick;
