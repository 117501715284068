import React from "react";
import { useState } from "react";

import "../allClickProjects.scss";

import { GiAbstract005 } from "react-icons/gi";

import { BiCaretRight } from "react-icons/bi";
import { SimpleButon, SimpleButonId } from "../../../../buttons/MyButon";
import DedicatedPrice from "./DedicatedPrice";

function DedicatedClick() {
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);

  const activeHandler = (
    a1 = true,
    a2 = false,
    a3 = false,
    a4 = false,
    a5 = false
  ) => {
    setActive1(a1);
    setActive2(a2);
    setActive3(a3);
    setActive4(a4);
    setActive5(a5);
  };

  return (
    <div className="inner-project-wrapper">
      <div className="heading">
        <h2>Hire Dedicated Teams</h2>
        <p>
          Our experienced and dedicated professionals are committed to helping
          you attain your vision, goals, and objectives.
        </p>
        <hr />
      </div>

      <div className="select-projects-container">
        <div className="left-side">
          {/* Expert PHP Developers  */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler();
            }}
            style={{ backgroundColor: active1 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Expert PHP Developers </span>
            </div>
            <BiCaretRight />
          </div>

          {/* Frontend Developers  */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, true, false, false, false);
            }}
            style={{ backgroundColor: active2 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Frontend Developers </span>
            </div>
            <BiCaretRight />
          </div>

          {/* Hire C# Developer  */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, true, false, false);
            }}
            style={{ backgroundColor: active3 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Hire C# Developer </span>
            </div>
            <BiCaretRight />
          </div>

          {/* Hire SEO Content Writers  */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, false, true, false);
            }}
            style={{ backgroundColor: active4 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Hire SEO Content Writers </span>
            </div>
            <BiCaretRight />
          </div>

          {/* Hire an SEO Executive  */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, false, false, true);
            }}
            style={{ backgroundColor: active5 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Hire an SEO Executive </span>
            </div>
            <BiCaretRight />
          </div>
        </div>

        {/* content  */}
        <div className="right-side">
          {/* Expert PHP Developers  */}
          {active1 && (
            <>
              <div className="mydiv">
                <h3>Hire Expert, Consistent & Dedicated PHP Developers</h3>
                <p>
                  Expert PHP developers at HexamileSoft offer years of
                  experience in handling complex PHP programming and development
                  needs. Skilled in the latest technology and frameworks, such
                  as Laravel, code nighter, Zend, and more, we provide scalable
                  solutions for robust web platforms. Our dedicated team
                  specializes in custom PHP7 web applications, enterprise cloud
                  solutions, and start-up support.
                </p>
                <p>
                  We manage our daily tasks using JIRA, an essential tool for
                  building and maintaining top-quality software. It allows us to
                  plan, track, and release projects seamlessly, with the ability
                  to manage team members. Additionally, we use Bit Bucket as our
                  Distributed Version Control System, designed for professional
                  teams to collaborate easily. Bit Bucket Server provides
                  secure, fast, and enterprise-grade controls such as granular
                  permissions and powerful management features.
                  <br />
                  Please refer to the sample budgets provided for our expert PHP
                  developers based on their expertise.
                </p>
              </div>
              <div className="price-wrapper">
                <DedicatedPrice
                  title="MID-LEVEL PHP DEVELOPER"
                  parag1="3+ YEARS OF EXPERIENCE"
                  priceTag="2200"
                  classN="my"
                  btnPath={"#d-contact"}
                />
                <DedicatedPrice
                  title="SENIOR PHP DEVELOPER"
                  parag1="5+ YEARS OF RICH EXPERIENCE"
                  priceTag="2400"
                  classN="my-center"
                  btnPath={"#d-contact"}
                />
                <DedicatedPrice
                  title="PHP GURU"
                  parag1="7+ YEARS OF VERSATILE EXPERIENCE, MANAGES TEAM"
                  priceTag="2700"
                  classN="my"
                  btnPath={"#d-contact"}
                />
              </div>
            </>
          )}

          {/* Frontend Developers  */}
          {active2 && (
            <>
              <div className="mydiv">
                <h3>Front-end - HTML, CSS, JAVASCRIPT, BOOTSTRAP</h3>
                <p>
                  Our expertise lies in developing streamlined, organized code.
                  We are proficient in cutting-edge front-end tools such as
                  HTML5, CSS3, and JQuery, allowing us to efficiently implement
                  and sustain them, ultimately saving you costs in the long run.
                </p>
                <p>
                  In today's adaptive and responsive web environment, it's
                  crucial to ensure proper coding for seamless design execution.
                  Our team features top-notch front-end developers who create
                  visually stunning HTML/CSS and JavaScript. We're proud of our
                  coding standards and hold ourselves to the highest standards.
                  You can expect organized, validated, well-documented HTML/CSS
                  and robust and adjustable JavaScript. Moreover, our developers
                  also have design skills, ensuring seamless collaboration
                  between design and development teams.
                </p>
              </div>
              <div className="price-wrapper">
                <DedicatedPrice
                  title="MID-LEVEL FrontEnd"
                  parag1="3+ YEARS OF EXPERIENCE"
                  priceTag="2200"
                  classN="my"
                  btnPath={"#d-contact"}
                />
                <DedicatedPrice
                  title="Senior FrontEnd Developer"
                  parag1="5+ YEARS OF RICH EXPERIENCE"
                  priceTag="2400"
                  classN="my-center"
                  btnPath={"#d-contact"}
                />
                <DedicatedPrice
                  title="FrontEnd Guru"
                  parag1="7+ YEARS OF EXPERIENCE"
                  priceTag="2700"
                  classN="my"
                  btnPath={"#d-contact"}
                />
              </div>
            </>
          )}

          {/* Hire C# Developer  */}
          {active3 && (
            <>
              <div className="mydiv">
                <h3>Hire a dedicated .Net Developer or Programmer.</h3>
                <p>
                  HexamileSoft offers complete, customized ASP.NET development
                  services that balance functionality and user-friendly design.
                  Our skilled .NET developers have a proven track record of
                  delivering top-notch ASP.NET applications for various
                  industries. They ensure that each solution meets your business
                  needs by utilizing cutting-edge technology and industry
                  standards.
                  <br />
                  Our seasoned professionals with expertise in the latest
                  Microsoft technologies and over five years of hands-on
                  experience specializing in MVC architecture and ASP.NET C#
                  application development, including customization of ERP, CRM,
                  SharePoint, Business Intelligence, and more.
                </p>
                <p>Other services that we offer include:</p>
                <ul>
                  <li>Enterprise app development.</li>
                  <li>Database-driven ASP.NET website development.</li>
                  <li>Web app development and maintenance.</li>
                  <li>Web portal development.</li>
                </ul>
                <p>
                  We use Jira as our online project tracking system, where you
                  can stay updated on the progress of your tasks as a registered
                  client. Your project is conveniently synced with BitBucket,
                  our code repository, so you can easily download the code and
                  verify its progress at any time. To add to it, our developers
                  are not only fluent in English but also great at working in a
                  team. Lastly, we assign each of our clients a dedicated
                  Development manager despite their team size to ensure that the
                  project stays on track and to oversee the development process.
                  The development manager ensures that the project stays aligned
                  with your goals and is completed on time.
                  <br />
                  Following are the sample budgets for our .Net developers based
                  on their expertise.
                </p>
              </div>
              <div className="price-wrapper">
                <DedicatedPrice
                  title="MID-LEVEL C# DEVELOPER"
                  parag1="3+ YEARS OF EXPERIENCE"
                  priceTag="2200"
                  classN="my"
                  btnPath={"#d-contact"}
                />
                <DedicatedPrice
                  title="SENIOR C# DEVELOPER"
                  parag1="5+ YEARS OF RICH EXPERIENCE"
                  priceTag="2400"
                  classN="my-center"
                  btnPath={"#d-contact"}
                />
                <DedicatedPrice
                  title="C# GURU"
                  parag1="7+ YEARS OF VERSATILE EXPERIENCE, MANAGES TEAM"
                  priceTag="2700"
                  classN="my"
                  btnPath={"#d-contact"}
                />
              </div>
            </>
          )}

          {/* Hire SEO Content Writers  */}
          {active4 && (
            <>
              <div className="mydiv">
                <h3>Hire SEO Content Writers</h3>
                <p>
                  At HexamileSoft, we provide a trustworthy and top-notch SEO
                  writing service aimed at captivating both audiences and search
                  engines. Our talented team of expert writers excels in
                  crafting content that is both compelling and optimized for
                  search. Our quick turnaround times and two free revisions make
                  the content creation process effortless for our clients, and
                  we take pride in delivering high-quality results.
                </p>
              </div>
              <div className="price-wrapper">
                <DedicatedPrice
                  title="MID-LEVEL Content Writer"
                  parag1="3+ YEARS OF EXPERIENCE"
                  priceTag="800"
                  classN="my"
                  btnPath={"#d-contact"}
                />
                <DedicatedPrice
                  title="SENIOR Content Writer"
                  parag1="5+ YEARS OF RICH EXPERIENCE"
                  priceTag="1000"
                  classN="my-center"
                  btnPath={"#d-contact"}
                />
                <DedicatedPrice
                  title="Content Writer GURU"
                  parag1="7+ YEARS OF VERSATILE EXPERIENCE, MANAGES TEAM"
                  priceTag="1200"
                  classN="my"
                  btnPath={"#d-contact"}
                />
              </div>
            </>
          )}

          {/* Hire an SEO Executive  */}
          {active5 && (
            <>
              <div className="mydiv">
                <h3>Hire an SEO Executive</h3>
                <p>
                  Trust our team of SEO experts to boost your website's traffic.
                  Our professionals use their technical, programming, and
                  analytical skills to optimize your website's copy and landing
                  pages for search engines. With extensive experience in keyword
                  research, we identify and maximize keyword opportunities,
                  reduce content repetition, and construct links for organic
                  website growth. Let us help you increase your website's
                  visibility and drive more traffic.
                </p>
              </div>
              <div className="price-wrapper">
                <DedicatedPrice
                  title="MID-LEVEL SEO Executive"
                  parag1="3+ YEARS OF EXPERIENCE"
                  priceTag="800"
                  classN="my"
                  btnPath={"#d-contact"}
                />
                <DedicatedPrice
                  title="SENIOR SEO Executive"
                  parag1="5+ YEARS OF RICH EXPERIENCE"
                  priceTag="1000"
                  classN="my-center"
                  btnPath={"#d-contact"}
                />
                <DedicatedPrice
                  title="SEO Executive GURU"
                  parag1="7+ YEARS OF VERSATILE EXPERIENCE, MANAGES TEAM"
                  priceTag="1200"
                  classN="my"
                  btnPath={"#d-contact"}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default DedicatedClick;
