import React, { useState } from "react";
import Questions from "./Questions";

import { useEffect } from "react";
import { createContext } from "react";

export const FAQData = createContext();

function Faq() {
  const [q1, setQ1] = useState(false);
  const [q2, setQ2] = useState(false);
  const [q3, setQ3] = useState(false);
  const [q4, setQ4] = useState(false);
  const [q5, setQ5] = useState(false);
  const [q6, setQ6] = useState(false);
  const [q7, setQ7] = useState(false);
  const [q8, setQ8] = useState(false);
  const [q9, setQ9] = useState(false);
  const [q10, setQ10] = useState(false);

  useEffect(() => {
    document.title = "Frequently Asked Questions - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "Here are some frequently asked questions and their answers for a better understanding of our organization and our work ethics.";
    document.getElementsByTagName("META")[6].content =
      "Frequently Asked Questions - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "Here are some frequently asked questions and their answers for a better understanding of our organization and our work ethics.";
    document.getElementsByTagName("META")[12].content =
      "Frequently Asked Questions - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "Here are some frequently asked questions and their answers for a better understanding of our organization and our work ethics.";
  }, []);

  return (
    <FAQData.Provider
      value={{
        q1,
        q2,
        q3,
        q4,
        q5,
        q6,
        q7,
        q8,
        q9,
        q10,
        setQ1,
        setQ2,
        setQ3,
        setQ4,
        setQ5,
        setQ6,
        setQ7,
        setQ8,
        setQ9,
        setQ10,
      }}
    >
      <div className="faq-container">
        <div className="faq-wrapper">
          <div className="title">
            <h1>Frequently Asked Questions</h1>
          </div>

          <Questions />
        </div>
      </div>
    </FAQData.Provider>
  );
}

export default Faq;
