import React from "react";
import HeadContent from "./HeadContent";

import IMovingForward from "../../../assets/whyus/Moving Forward.jpg";
import ICustomWork from "../../../assets/whyus/Customized Work.jpg";
import IUserfocused from "../../../assets/whyus/User-focused.jpg";
import IInnovativeApproaches from "../../../assets/whyus/Innovative Approaches.jpg";
import ISmartStrong from "../../../assets/whyus/Smart and Strong.jpg";
import ISatisfiedClients from "../../../assets/whyus/Satisfied Clients.jpg";

import ProcessFooter from "../ProcessFooter";
import { useEffect } from "react";
import { useContext } from "react";
import { GlobalData } from "../../../../App";

function WhyUs() {
  const paragMF1 =
    'Instead of creating for "What Was There," we develop for "What Will Be." That is the entire mentality that guides our work ethic. Today\'s clients do not approach us with a "design brief." They come to us with specific challenges that should only have long-term solutions.';
  const paragMF2 =
    "We challenge conventional thinking using a design-driven strategy to produce innovative, cutting-edge solutions for the entire digital ecosystem.";
  const paragMF3 =
    "Any suggestions? Why don't you talk about it and see what we can offer you?";

  const paragCW1 =
    "We do not make copies. A digital agency with a passion for huge concepts, minute details, and a better method (agile development) for producing top-notch work is HexamileSoft.";
  const paragCW2 =
    "Regardless of how many other pieces of work you compare ours to, you will still be able to tell ours apart because of its distinctive personality and meticulous attention to detail.";
  const paragCW3 =
    "We refer to this kind of digital customizing as entirely built around the tenant's contemporary agile development processes.";

  const paragUF1 =
    "A website is more than just a collection of gorgeous forms and adorable colors. Our belief in function before form is primarily motivated by the fact that everything is created with a purpose.";
  const paragUF2 =
    'We build websites, mobile apps, and online shops that serve their "purpose" and are nonetheless stunning by adhering to the highest web standards and tried-and-true techniques.';

  const paragIA1 =
    "A deep understanding of technology, users, and business requirements is necessary to select the appropriate technology and build it for engaging end-user interactions and experiences. We take advantage of the potential of emerging technology to turn what was previously impractical into reality.";
  const paragIA2 =
    "To push the boundaries of ideas, improve validation, and shorten time-to-market, we use our deep expertise in a wide range of software and coding technologies early.";

  const paragSaS1 =
    "Intelligent people ought to further your grand ideals. We seek to provide quality and honesty as a family of brilliant, close-knit, and motivated individuals.";
  const paragSaS2 =
    "Since 2005, we've demonstrated that smaller organizations can outperform larger ones, which frequently prioritize hierarchy over productivity, with just 50 or so decent people using the proper tools.";
  const paragSaS3 =
    "Responsible behavior is something that good designers, developers, and marketers want, need, and deserve. We're occupied creating applications for businesses that are aware that empowered teams create more successful enterprises.";

  const paragSC1 =
    "We exercise a fair amount of discretion when selecting our clients. We strictly prefer working with clients who despise normalcy as much as we do and who are as ambitious and disruptive as we are, regardless of their size or type, above dealing with everyone else.";
  const paragSC2 =
    "Because of this mentality, we work with some of the top creative firms from across the globe for international brands. As well as larger clients, we adore working with startups and business owners.";
  const paragSC3 =
    "We take great pride in having partners like Honda, Sky TG24, and AS ROMA, who are well-known throughout Europe.";

  const { setColorProcess } = useContext(GlobalData);

  useEffect(() => {
    document.title = "Why HexamileSoft - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "Our team of experienced UX designers, Software engineers, App developers, and Digital Strategists makes us eligible to cater to all your business needs.";
    document.getElementsByTagName("META")[6].content =
      "Why HexamileSoft - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "Our team of experienced UX designers, Software engineers, App developers, and Digital Strategists makes us eligible to cater to all your business needs.";
    document.getElementsByTagName("META")[12].content =
      "Why HexamileSoft - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "Our team of experienced UX designers, Software engineers, App developers, and Digital Strategists makes us eligible to cater to all your business needs.";
  }, []);

  return (
    <div className="why-us-container">
      <div className="heading-container">
        <h1>The Things That Set Us Apart</h1>
        <h3>A Straightforward Method of Software Development</h3>
        <p>
          Our team of skilled UX designers, software engineers, app developers,
          and digital strategists can meet your online business needs. Our
          digital agency places a priority on offering our clients
          individualized services.
        </p>
        <p>
          We will listen to you from the first meeting to determine your
          requirements to give your consumers the greatest experience possible.
        </p>
        <p>
          We will also offer ongoing assistance and advice. It is then delivered
          by us utilizing our knowledge and abilities.
        </p>
      </div>
      <div className="content-container">
        <HeadContent
          title={"Moving Forward"}
          parag1={paragMF1}
          parag2={paragMF2}
          parag3={paragMF3}
          img={IMovingForward}
          btnText={"Let's Start Friendly Discussion"}
          path={"/contact"}
          setColorProcess={setColorProcess}
        />
        <HeadContent
          title={"Customized Work"}
          parag1={paragCW1}
          parag2={paragCW2}
          parag3={paragCW3}
          img={ICustomWork}
          btnText={"Let's Start Friendly Discussion"}
          path={"/contact"}
          direction={"row-reverse"}
          setColorProcess={setColorProcess}
        />
        <HeadContent
          title={"User-focused"}
          parag1={paragUF1}
          parag2={paragUF2}
          parag3={""}
          img={IUserfocused}
          btnText={"Let's Start Friendly Discussion"}
          path={"/contact"}
          setColorProcess={setColorProcess}
        />
        <HeadContent
          title={"Innovative Approaches"}
          parag1={paragIA1}
          parag2={paragIA2}
          parag3={""}
          img={IInnovativeApproaches}
          btnText={"Let's Start Friendly Discussion"}
          path={"/contact"}
          direction={"row-reverse"}
          setColorProcess={setColorProcess}
        />
        <HeadContent
          title={"Smart and Strong"}
          parag1={paragSaS1}
          parag2={paragSaS2}
          parag3={paragSaS3}
          img={ISmartStrong}
          btnText={"Let's Start Friendly Discussion"}
          path={"/contact"}
          setColorProcess={setColorProcess}
        />
        <HeadContent
          title={"Satisfied Clients"}
          parag1={paragSC1}
          parag2={paragSC2}
          parag3={paragSC3}
          img={ISatisfiedClients}
          btnText={"Let's Start Friendly Discussion"}
          path={"/contact"}
          direction={"row-reverse"}
          setColorProcess={setColorProcess}
        />
      </div>

      <div className="bottom-container">
        <ProcessFooter />
      </div>
    </div>
  );
}

export default WhyUs;
