import React from "react";

import "./hireEngineer.scss";

import { GrStatusGood } from "react-icons/gr";

import Userz from "../../../assets/service/Hire a developer.png";
import { SimpleButon, SimpleButonId } from "../../../buttons/MyButon";

function HireEngineers({ engrName, engrPrice, btnPath }) {
  return (
    <div className="hire-engineer-wrapper">
      <div className="upper-wrapper">
        <h3>{engrName}</h3>
        <div className="salary">
          <img src={Userz} alt="engineer" />
          <sup>$</sup>
          <p className="money">{engrPrice}</p>
          <sub>/mo</sub>
        </div>
      </div>
      <div className="middle-wrapper">
        <ul>
          <li>
            <div className="my-icon">
              <GrStatusGood />
            </div>
            3 Years Experienced
          </li>
          <li>
            <div className="my-icon">
              <GrStatusGood />
            </div>
            100% Confidentiality Assured (Strict NDA Terms)
          </li>
          <li>
            <div className="my-icon">
              <GrStatusGood />
            </div>
            Project Management Service Available
          </li>
        </ul>
      </div>
      <div className="bottom-wrapper">
        <SimpleButonId btn={"Hire Now"} path={btnPath} />
      </div>
    </div>
  );
}

export default HireEngineers;
