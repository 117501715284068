import React from "react";
import { useState } from "react";

// import "./mobileProjectsClick.scss";

import "../allClickProjects.scss";

import { GiAbstract005 } from "react-icons/gi";

import { BiCaretRight } from "react-icons/bi";
import { SimpleButon } from "../../../../buttons/MyButon";

function MobileProjectsClick() {
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);

  const activeHandler = (
    a1 = true,
    a2 = false,
    a3 = false,
    a4 = false,
    a5 = false,
    a6 = false
  ) => {
    setActive1(a1);
    setActive2(a2);
    setActive3(a3);
    setActive4(a4);
    setActive5(a5);
    setActive6(a6);
  };

  return (
    <div className="inner-project-wrapper">
      <div className="heading">
        <h2>Robust, Scalable, & Interactive App Development</h2>
        <p>
          Leading the way in App Development, HexamileSoft is powered by a team
          of over 50 development pros.
        </p>
        <hr />
      </div>

      <div className="select-projects-container">
        <div className="left-side">
          {/* iPhone Application Development */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler();
            }}
            style={{ backgroundColor: active1 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>iPhone Application Development</span>
            </div>
            <BiCaretRight />
          </div>

          {/* iPad App Development  */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, true, false, false, false, false);
            }}
            style={{ backgroundColor: active2 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>iPad App Development</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Android App Development  */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, true, false, false, false);
            }}
            style={{ backgroundColor: active3 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Android App Development</span>
            </div>
            <BiCaretRight />
          </div>

          {/* HTML5 Mobile App Development */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, false, true, false, false);
            }}
            style={{ backgroundColor: active4 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>HTML5 Mobile App Development</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Phone Gap Development  */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, false, false, true, false);
            }}
            style={{ backgroundColor: active5 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Phone Gap Development</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Our Plus Points  */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, false, false, false, true);
            }}
            style={{ backgroundColor: active6 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Our Plus Points </span>
            </div>
            <BiCaretRight />
          </div>
        </div>

        {/* content  */}
        <div className="right-side">
          {/* iPhone Application Development */}
          {active1 && (
            <div className="mydiv">
              <h3>
                Get Your Idea to the App Store Faster with HexamileSoft's Expert
                iPhone App Developers - Conceptualize, Strategize, Design,
                Build, and Launch with Quality Guaranteed.
              </h3>
              <p>
                At HexamileSoft, our iPhone app developers bring a comprehensive
                approach to app development. They analyze business requirements,
                focus on design, prioritize user experience, and ensure seamless
                functionality and debugging. The result is a smooth submission
                and approval process for Apple's App Store.
              </p>
              <p>
                Our expert iPhone app developers bring a wealth of technical
                knowledge and experience. With skills in iOS development, iOS
                SDK frameworks, Java, J2SE/J2EE, C, C++ and Objective C, SOAP,
                JSON, REST, UIKIT, XML, XCODE IDE, Core Data, Core Location,
                Interface Builder, Instruments, Cocoa Touch, Cocoa Core
                Libraries: Core Foundation, Core Audio, Core Graphics, Core
                Animation, Localization and Location API, Database API, Safari
                Web Kit Extensions, PowerVR hardware, Physics engines, in-app
                purchases, and other tools for iPhone development,
                multi-threading on mobile and tablet devices, and more, our team
                can design and develop top-quality iPhone apps that meet your
                needs and requirements. Additionally, with their UX design and
                QA testing experience, they can deliver seamless and
                user-friendly iPhone apps that deliver results.
              </p>
            </div>
          )}

          {/* iPad App Development */}
          {active2 && (
            <div className="mydiv">
              <h3>
                Our expertise in iOS development sets us apart in delivering
                top-notch, user-friendly, and feature-rich iOS experiences.
                Choose us for the best results.
              </h3>
              <p>
                Trust HexamileSoft for expert iPad app development that results
                in high-quality, user-friendly, and feature-rich applications.
                Our comprehensive approach covers everything from analyzing
                business needs to fine-tune the design and ensuring smooth
                approval on the App Store.
              </p>
              <p>
                We are dedicated to delivering exceptional and innovative iPhone
                and iPad app solutions that drive business success and meet the
                specific needs of our clients. Our experienced iOS developers
                leverage their technical skills in Objective C, Swift, HTML5,
                and the latest iOS technologies to create high-performance,
                feature-packed, and user-friendly apps. We ensure a seamless
                development process, leading to the best design, superior
                functionality, and user experience for maximum return on
                investment through close collaboration with our clients.
              </p>
            </div>
          )}

          {/* Android App Development  */}
          {active3 && (
            <div className="mydiv">
              <h3>
                Our Android app developers bring technical expertise and
                creative flair to deliver top-notch apps for Google Play. Our
                diverse portfolio and talented team ensure custom solutions that
                fit your business needs perfectly.
              </h3>
              <p>
                HexamileSoft's Android development team excels in delivering
                custom-made, feature-rich apps for various industries, including
                business, technology, healthcare, and media & entertainment. Our
                skilled developers create standalone, client-server, web
                service-driven, or database-driven apps, ensuring they meet your
                requirements and provide the desired look, feel, and
                functionality. With expertise in cross-platform development and
                app migration, our Android experts can turn your vision into a
                reality.
              </p>
            </div>
          )}

          {/* HTML5 Mobile App Development */}
          {active4 && (
            <div className="mydiv">
              <h3>
                Our HTML5 app development team ensures the security and
                sustainability of all HTML5-based mobile app solutions.
              </h3>
              <p>
                Sprout Core, Phone Gap, AngularJS, Backbone.js, Ember.js,
                Node.js and Sencha are just a few of the HTML5-based frameworks
                that HexamileSoft has vast experience in building cutting-edge
                mobile applications and experiences. HexamileSoft's skilled team
                of HTML5 mobile site and app developers produce cross-platform
                solutions that work flawlessly on any hardware or operating
                system.
              </p>
            </div>
          )}

          {/* Phone Gap Development  */}
          {active5 && (
            <div className="mydiv">
              <h3>
                Our Phone Gap specialists are renowned for crafting
                cutting-edge, high-performance Phone Gap solutions that are both
                secure and scalable.
              </h3>
              <p>
                HexamileSoft builds apps using Phone Gap, known for fast
                development and expertise with open-source technology. Our Phone
                Gap developers can bring your mobile app idea to life, creating
                cross-platform solutions that work seamlessly on major mobile
                devices and operating systems. We employ an agile methodology to
                deliver high-quality solutions tailored to your needs. Our
                engagement model starts with a thorough evaluation to ensure
                that the right Phone Gap solution is delivered efficiently and
                effectively. Whether you need to augment your team or require a
                complete end-to-end Phone Gap solution, we can provide the
                expertise, including business analysis, custom UX/UI design,
                coding, project management, and quality assurance.
              </p>
            </div>
          )}

          {/* Our Plus Points  */}
          {active6 && (
            <div className="mydiv">
              <h3>Our Plus Points</h3>
              <ul>
                <li>Competitive Rates and Project Quotes</li>
                <li>
                  Proven Delivery- No worries about scale and complexity. We're
                  experienced.
                </li>
                <li>Expert App Developers</li>
                <li>
                  Transparent, Collaborative, and Communicative, Dedicated
                  Support
                </li>
                <li>Customer Satisfaction </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MobileProjectsClick;
