import React from "react";
import { useState } from "react";

import "../allClickProjects.scss";

import { GiAbstract005 } from "react-icons/gi";

import { BiCaretRight } from "react-icons/bi";

function QAClick() {
  const [active3, setActive3] = useState(true);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);
  const [active7, setActive7] = useState(false);

  const activeHandler = (
    a3 = true,
    a4 = false,
    a5 = false,
    a6 = false,
    a7 = false
  ) => {
    setActive3(a3);
    setActive4(a4);
    setActive5(a5);
    setActive6(a6);
    setActive7(a7);
  };

  return (
    <div className="inner-project-wrapper">
      <div className="heading">
        <h2>Our Techniques</h2>
        <p>
          We achieve this through different testing techniques depending on the
          specific needs and requirements of the product. A few of our testing
          methods are listed below.
        </p>
        <hr />
      </div>

      <div className="select-projects-container">
        <div className="left-side">
          {/* Functional Testing */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(true, false, false, false, false);
            }}
            style={{ backgroundColor: active3 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Functional Testing</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Usability Testing */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, true, false, false, false);
            }}
            style={{ backgroundColor: active4 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Usability Testing</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Compatibility Testing */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, true, false, false);
            }}
            style={{ backgroundColor: active5 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Compatibility Testing</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Performance Testing */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, false, true, false);
            }}
            style={{ backgroundColor: active6 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Performance Testing</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Security Testing */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, false, false, true);
            }}
            style={{ backgroundColor: active7 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Security Testing</span>
            </div>
            <BiCaretRight />
          </div>
        </div>

        {/* content  */}
        <div className="right-side">
          {/* Functional Testing */}
          {active3 && (
            <div className="mydiv">
              <h3>
                Testing Product Components to ensure it Functions Properly
              </h3>
              <p>
                Manufacturers can improve the overall quality of the final product, reduce the risk of failure or recall, increase customer happiness, and maintain a competitive edge in the market by testing product components. Testing is also important in ensuring product safety, reliability, and compliance with industry requirements, all of which contribute to the product's overall success and end-user pleasure.
              </p>
            </div>
          )}

          {/* Usability Testing */}
          {active4 && (
            <div className="mydiv">
              <h3>Navigation, Readability, Overall Design</h3>
              <p>
                Usability Testing evaluates the user experience and
                accessibility of a website or application to ensure it is
                user-friendly and easy to navigate. This type of Testing
                involves testing the website or application with real users to
                identify areas for improvement, including navigation,
                readability, and overall design. The goal is to ensure the
                website or application is simple and efficient for users.
              </p>
            </div>
          )}

          {/* Compatibility Testing */}
          {active5 && (
            <div className="mydiv">
              <h3>Consistent User Experience</h3>
              <p>
                Compatibility Testing verifies that a website or application
                functions correctly across different devices, browsers, and
                operating systems. It helps ensure a consistent user experience
                and prevents potential technical issues from affecting the user.
              </p>
            </div>
          )}

          {/* Performance Testing */}
          {active6 && (
            <div className="mydiv">
              <h3>Speed, Stability, and Scalability</h3>
              <p>
                Performance Testing measures a website or application's speed,
                stability, and scalability. This type of Testing is critical for
                ensuring the website or application can handle increased traffic
                and usage without negatively impacting user experience.
                Performance Testing includes evaluating load times, response
                times, and the ability of the website or application to handle
                large amounts of data and transactions. The goal is to identify
                and resolve performance issues before the website or application
                is released to the public.
              </p>
            </div>
          )}

          {/* Security Testing */}
          {active7 && (
            <div className="mydiv">
              <h3>
                Eliminating Vulnerabilities and Potential Security Threats
              </h3>
              <p>
                Security testing evaluates a software system or application to
                identify vulnerabilities and potential security threats. It
                involves testing for confidentiality, integrity, and
                availability of data, as well as Testing for unauthorized access
                and malicious activity. Security testing needs to be included in
                the software testing process to guarantee the protection of
                sensitive data.
              </p>
              <div className="c">
                These are only to name a few. Other testing methods include
                Regression Testing, Automation Testing, Mobile Testing, User
                Acceptance Testing, and Localization Testing.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QAClick;
