import React from "react";

import {
  FaFacebookSquare,
  FaWhatsappSquare,
  FaTwitterSquare,
  FaLinkedin,
} from "react-icons/fa";

import { MdPhoneInTalk, MdMail } from "react-icons/md";

function TopBar() {
  return (
    <>
      {/* topbar */}
      <div className="top-bar-container">
        <div className="main-wrapper">
          <div className="mail">
            <div className="icon">
              <MdMail />
            </div>
            <a href="mailto:hello@hexamilesoft.com">hello@hexamilesoft.com</a>
          </div>

          <div className="number">
            <div className="icon">
              <MdPhoneInTalk />
            </div>
            <div className="numbers">
              <a href="tel:+923025504050">
                <span>+92 302 5504050</span>
              </a>
            </div>
          </div>
        </div>

        <div className="social-wrapper">
          <a
            href="https://www.facebook.com/Challengepk/"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaFacebookSquare />
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=923025504050"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaWhatsappSquare />
          </a>
          <a
            href="https://twitter.com/hexamilesoft"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaTwitterSquare />
          </a>

          <a
            href="https://www.linkedin.com/company/hexamilesoft/"
            target={"_blank"}
            rel="noreferrer"
          >
            <FaLinkedin />
          </a>
        </div>
      </div>
    </>
  );
}

export default TopBar;
