import React, { useEffect, useState } from "react";
import { SimpleButon } from "../../buttons/MyButon";

import { RxCross1 } from "react-icons/rx";

import "./popupMessage.scss";

function PopUpMessage() {
  const [visible, setVisible] = useState(false);

  const setPopUpValue = () => {
    setVisible(true);
    document.body.style.overflow = "hidden";
  };

  useEffect(() => {
    let pop_status = localStorage.getItem("pop_status");
    if (!pop_status) {
      setTimeout(setPopUpValue, 3000);

      localStorage.setItem("pop_status", 1);
    }
  }, []);
  // if (!visible) return null;

  return (
    <>
      {visible && (
        <div className="message-popup">
          <div className="blur-section"></div>
          <div className="pop">
            <div className="top-pop">
              <h2>Building a Web App?</h2>
              <div
                className="cross"
                onClick={() => {
                  setVisible(false);
                  document.body.style.overflow = "auto";
                }}
              >
                <RxCross1 />
              </div>
            </div>
            <div className="inner-container">
              <p>
                If you are building an{" "}
                <strong>admin panel, CRM, CMS, internal tool, ERP</strong>, or
                other data management app - we guarantee there is no faster way
                other than Flatlogic to deliver full-stack web apps!
              </p>
              <p>
                Schedule a call with our team to leverage the experience of the{" "}
                <strong>high-rated professionals</strong> and their tools to
                deliver web apps the fastest way possible!
              </p>
              <div
                className="btn"
                onClick={() => {
                  document.body.style.overflow = "auto";
                  setVisible(false);
                }}
              >
                <SimpleButon btn={"Contact Us"} path={"/contact"} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PopUpMessage;
