import React from "react";

import "./serviveHead.scss";

function ServiceHead({ title, parag }) {
  return (
    <div className="service-head-container">
      <p>{parag}</p>
      <h1>{title}</h1>
    </div>
  );
}

export default ServiceHead;
