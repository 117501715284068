import React from "react";

import PY1 from "../../../../assets/work/mobile apps/notes app/notes.jpg";

import { RxCross1 } from "react-icons/rx";
import { useContext } from "react";
import { WorkMenuData } from "../../OurWork";

function NotesAppA() {
  const { setActive11 } = useContext(WorkMenuData);

  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActive11(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActive11(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>
          <div className="top-pop">
            <h2>Notes App</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Mobile App</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>RSA Bill</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                The Notes App is a versatile and user-friendly digital
                application designed to help individuals capture, organize, and
                manage their thoughts, ideas, and important information. It is a
                convenient replacement for traditional paper notebooks or
                notepads, offering various features and functionalities to
                enhance productivity and efficiency.
              </strong>
            </p>
            <p>
              The App allows users to capture information in real-time,
              eliminating the need to carry around physical materials or rely on
              memory. <br />
              One of the significant advantages of using a Notes App is its
              accessibility across multiple devices. Whether using a smartphone,
              tablet, or computer, you can sync your notes across all your
              devices, ensuring you can access them anytime, anywhere. This
              synchronization feature ensures that your notes are always
              up-to-date and readily available, regardless of your device. Notes
              Apps often offer various organizational features to help users
              keep their notes in order. It may include creating folders or
              categories, assigning tags or labels, and searching for specific
              notes based on keywords or dates.
            </p>
            <p>
              Notes App is a valuable tool for individuals who want to
              streamline their note-taking process, stay organized, and access
              their information effortlessly. With features like cross-device
              synchronization, powerful search capabilities, and collaboration
              functionalities, these apps have become essential companions for
              students, professionals, and anyone who values productivity and
              efficiency in managing their thoughts and ideas.
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotesAppA;
