import React from "react";
import ServiceContact from "../../contact/ServiceContact";
import TrustPilot from "../../trust/TrustPilot";

import "../../pages/servicesSpare.css";

import ServiceHead from "../../top_head/ServiceHead";
import InnerHeading from "../../headings/InnerHeading";
import UpperContent from "../../upper_content/UpperContent";
import TrustedBy from "../../trusted_by_imgs/TrustedBy";
import StartProject from "../../start_project/StartProject";
import HireEngineers from "../../hire_engineers/HireEngineers";
import EngagementModel from "../../engagement_models/EngagementModel";
import FrontEndClick from "./FrontEndClick";

// images
import FED from "../../../../assets/service/services/Frontend Development.jpg";
import { useEffect } from "react";

function FrontEnd() {
  const paragH1 =
    'We can take up any design, be it in a common format, and turn it into flawless W3C Valid HTML5/ XHTML/ CSS markup in the "unique" possible way. Take our assistance in leveraging the robust Bootstrap capabilities and establishing a mobile-friendly foundation for your website.';

  const paragCon1 =
    "We provide a 100% money-back guarantee if you are not completely satisfied with your project because we are convinced you will be. What other development business can provide that? Although HexamileSoft is a sizable company, it is also small enough to give your attention. We'll do your projects on time and to perfection. Of that, you can be certain. A project manager will be assigned to your project using our exclusive client center, and we will also be available to you anytime you need us.";

  const startP =
    "No issue! Our qualified staff can take your original desktop mockup and realign all the pieces for you to ensure that your site appears great on tablets and smartphones. ";

  useEffect(() => {
    document.title = "Frontend Development - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "We will architect and build the design provided in a simple format and deliver pixel-perfect HTML, Bootstrap, and Email templates with a money-back guarantee.";
    document.getElementsByTagName("META")[6].content =
      "Frontend Development - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "We will architect and build the design provided in a simple format and deliver pixel-perfect HTML, Bootstrap, and Email templates with a money-back guarantee.";
    document.getElementsByTagName("META")[12].content =
      "Frontend Development - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "We will architect and build the design provided in a simple format and deliver pixel-perfect HTML, Bootstrap, and Email templates with a money-back guarantee.";
  }, []);

  return (
    <div className="services-container">
      <div className="heading-container">
        <ServiceHead
          title={"The Finest Front-End Developers in the Field"}
          parag={"Guaranteed Client Satisfaction"}
        />
      </div>

      {/* InnerHeading */}
      <div className="title-heading-container">
        <div className="heading-wrapper">
          <InnerHeading
            titleH={
              "Flawless Psd to Html, Bootstrap, and Email Templates. PSD to WordPress, Joomla, Drupal, Magento, and Shopify Integration."
            }
            paragH={paragH1}
          />
        </div>
        <hr />
        <div className="top-inner-container">
          <UpperContent
            imgCon={FED}
            paragCon1={paragCon1}
            paragCon2={""}
            paragCon3={""}
            btnPath={"#fe"}
          />
        </div>
      </div>

      {/* trusted by */}
      <div className="trusted-by-container">
        <TrustedBy />
      </div>

      {/* click projects  */}
      <div className="click-projects-container">
        <FrontEndClick />
      </div>

      {/* hire engineer  */}
      <div className="hire-engineer-container">
        <HireEngineers
          engrName={"Hire FrontEnd Developer"}
          engrPrice={"2200"}
          btnPath={"#fe"}
        />
        <EngagementModel />
      </div>

      {/* start your project  */}
      <div className="start-your-project-container">
        <StartProject
          titleProj={"No Response-Forward Designs?"}
          startP={startP}
          btnPath={"#fe"}
        />
      </div>

      {/* TrustPilot */}
      <div className="trust-pilot-wrap">
        <TrustPilot />
      </div>

      {/* ServiceContact */}
      <div className="service-contact-wrapper" id="fe">
        <ServiceContact />
      </div>
    </div>
  );
}

export default FrontEnd;
