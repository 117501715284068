import React from "react";

import "../../pages/servicesSpare.css";

import TrustPilot from "../../trust/TrustPilot";
import ServiceContact from "../../contact/ServiceContact";
import ServiceHead from "../../top_head/ServiceHead";
import InnerHeading from "../../headings/InnerHeading";
import UpperContent from "../../upper_content/UpperContent";
import TrustedBy from "../../trusted_by_imgs/TrustedBy";
import StartProject from "../../start_project/StartProject";
import HireEngineers from "../../hire_engineers/HireEngineers";
import EngagementModel from "../../engagement_models/EngagementModel";
import ITCounsultancyClick from "./ITCounsultancyClick";

// images
import MyImg from "../../../../assets/service/services/IT Consultancy.jpg";
import { useEffect } from "react";

function ITCounsultancy() {
  const paragH1 =
    "At HexamileSoft, we believe in helping businesses with consultancy services without them having to worry about maintaining staff and staff accountability. We are responsible for all your technology solutions, be it on-site, off-site, or offshore services.";

  const paragCon1 =
    "Big or small, every firm benefits from constant access to its tools of the trade. That uninterrupted access is possible only when a great team assists them with all IT problems at the back end to ensure the smooth completion of tasks. With years of experience, our team of IT consultants has served countless organizations ranging from 20 to 200 people and has always been able to satisfy clients.";
  const paragCon2 =
    "Our IT consultants handle various tasks, including designing technology solutions to address issues, developing a timeline for analysis, implementation, and testing, and collaborating with internal technical teams to maintain usage consistency.";

  const startP =
    "No issue! Our qualified staff can take your original desktop mockup and realign all the pieces for you to ensure that your site appears great on tablets and smartphones. ";

  useEffect(() => {
    document.title = "IT Consultancy - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "Turn to our IT consultants to help you eliminate workflow pain points, implement new technological solutions, and consolidate app portfolios.";
    document.getElementsByTagName("META")[6].content =
      "IT Consultancy - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "Turn to our IT consultants to help you eliminate workflow pain points, implement new technological solutions, and consolidate app portfolios.";
    document.getElementsByTagName("META")[12].content =
      "IT Consultancy - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "Turn to our IT consultants to help you eliminate workflow pain points, implement new technological solutions, and consolidate app portfolios.";
  }, []);

  return (
    <div className="services-container">
      <div className="heading-container">
        <ServiceHead
          title={"Sharing Your Burden with Our Technological Solutions"}
          parag={"Are You Ready to Grow Your Business?"}
        />
      </div>

      {/* InnerHeading */}
      <div className="title-heading-container">
        <div className="heading-wrapper">
          <InnerHeading
            titleH={"Market Leaders in IT Consultancy Services"}
            paragH={paragH1}
          />
        </div>
        <hr />
        <div className="top-inner-container">
          <UpperContent
            imgCon={MyImg}
            paragCon1={paragCon1}
            paragCon2={paragCon2}
            paragCon3={""}
            btnPath={"#it"}
          />
        </div>
      </div>

      {/* trusted by */}
      <div className="trusted-by-container">
        <TrustedBy />
      </div>

      {/* click projects  */}
      <div className="click-projects-container">
        <ITCounsultancyClick />
      </div>

      {/* hire engineer  */}
      <div className="hire-engineer-container">
        <HireEngineers
          engrName={"Hire IT Consultant"}
          engrPrice={"2200"}
          btnPath={"#it"}
        />
        <EngagementModel />
      </div>

      {/* start your project  */}
      <div className="start-your-project-container">
        <StartProject
          titleProj={"No Response-Forward Designs?"}
          startP={startP}
          btnPath={"#it"}
        />
      </div>

      {/* TrustPilot */}
      <div className="trust-pilot-wrap">
        <TrustPilot />
      </div>

      {/* ServiceContact */}
      <div className="service-contact-wrapper" id="it">
        <ServiceContact />
      </div>
    </div>
  );
}

export default ITCounsultancy;
