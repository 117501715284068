import React from "react";

import Counter from "../../assets/About/counter.svg";
import Coffee from "../../assets/About/coffee.svg";
import Code from "../../assets/About/code.svg";
import SocialMedia from "../../assets/About/social-media.svg";
import TopRight from "../../assets/About/About Us.jpg";
import Owners from "./Owners";

import CountUp from "react-countup";

import Img1 from "../../assets/About/Profiles/Profile 1.png";
import Img2 from "../../assets/About/Profiles/profile 2.png";
import Img3 from "../../assets/About/Profiles/profile 3.png";
import { useEffect } from "react";

function About() {
  useEffect(() => {
    document.title = "About us - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "We are HexamileSoft, and we grow digital brands. From Startups to Automation of Business Processes and building Online Presence, we help you with everything.";
    document.getElementsByTagName("META")[6].content =
      "About us - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "We are HexamileSoft, and we grow digital brands. From Startups to Automation of Business Processes and building Online Presence, we help you with everything.";
    document.getElementsByTagName("META")[12].content =
      "About us - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "We are HexamileSoft, and we grow digital brands. From Startups to Automation of Business Processes and building Online Presence, we help you with everything.";
  }, []);

  return (
    <div className="about-container">
      <div className="top-container">
        <div className="left">
          <h1>Who We Are</h1>
          <p>
            At HexamileSoft, we rely on the power of data to help grow and
            engage an online audience.
          </p>

          <div className="counters">
            <div className="counter">
              <div className="img">
                <img src={Counter} alt="projects" />
              </div>
              <div className="number">
                <h2>
                  <CountUp start={0} end={580} duration={1} /> <span>+</span>
                </h2>

                <span>No. of project</span>
              </div>
            </div>
            <div className="counter">
              <div className="img">
                <img src={SocialMedia} alt="social media" />
              </div>
              <div className="number">
                <h2>
                  <CountUp start={0} end={356397} />
                </h2>
                <span>Social Media Followers</span>
              </div>
            </div>
            <div className="counter">
              <div className="img">
                <img src={Code} alt="code" />
              </div>
              <div className="number">
                <h2>
                  <CountUp start={1} end={1510456} />
                </h2>
                <span>Lines of Code</span>
              </div>
            </div>
            <div className="counter">
              <div className="img">
                <img src={Coffee} alt="coffee" />
              </div>
              <div className="number">
                <h2>
                  <CountUp start={0} end={1074} />
                </h2>
                <span>Cups of Coffee Monthly</span>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="img">
            <img src={TopRight} alt="about us" />
          </div>
        </div>
      </div>

      <div className="owners-container">
        <h2>CEO's Message</h2>
        <p>Welcome to Hexamilesoft!</p>
        {/* <div className="owner-inner">
          <Owners fImg={Img2} fName={"B. Matthew"} fPosition={"Co-founder"} />
          <Owners
            fImg={Img3}
            fName={"R. Abdul"}
            fPosition={"Co-founder & CEO"}
          />
          <Owners fImg={Img1} fName={"A. Amna"} fPosition={"Co-Founder"} />
        </div> */}
        <p>
          We are committed to Solution-Simple-Fast-Secure at Hexamilesoft. Since
          our 2008 founding, we have provided worldwide with excellence and
          innovation, solidifying our position as a market leader. <br />A
          dedicated group of experts that bring a range of abilities, knowledge,
          and experiences to the table drives our business forward. We support a
          culture of cooperation, innovation, and ongoing learning because it
          enables us to produce outstanding results and go above and beyond for
          our clients.
        </p>
        <h3>Our Aspiration:</h3>
        <p>
          <strong>Excellence:</strong> Excellence is something we aim for in
          everything we do. From the caliber of our goods and services to the
          satisfaction of our clients, we are dedicated to upholding the highest
          standards. Our staff continuously raises the bar to achieve
          exceptional results, working relentlessly to guarantee that we meet
          and beyond industry expectations.
        </p>
        <p>
          <strong>Innovation:</strong> Our DNA is fundamentally innovative. We
          adopt a forward-looking perspective and are constantly looking for new
          concepts, tools, and strategies to stay ahead of the curve. By
          embracing innovation, we can predict market trends, create positive
          change, and provide our customers with cutting-edge solutions that
          consider their changing demands.
        </p>
        <p>
          <strong>Customer satisfaction:</strong> The core of our business is
          our customers. We cherish their trust greatly and work hard to
          establish enduring connections based on superior service, honesty, and
          openness. To meet their specific needs and exceed their expectations,
          we carefully listen to our customers to fully comprehend their
          requirements. Our success is also their success.
        </p>
        <p>
          <strong>Ethical Conduct:</strong> We operate with the utmost integrity
          and abide by the strictest ethical guidelines. We place a high value
          on honesty, openness, and justice in all our interactions, whether
          with clients, partners, or the communities we serve. We are dedicated
          to having a beneficial effect and helping society flourish
          sustainably.
        </p>
        <p>
          <strong>Team empowerment:</strong> Our most precious asset is our
          team. We support the development of a culture that respects and
          cherishes each individual and offers them chances for development. We
          promote a cooperative and welcoming workplace where different
          viewpoints are valued, and everyone's views are respected.
        </p>
        <p>
          <strong>Social Responsibilities:</strong> We know our social
          responsibilities to the communities where we do business. We work to
          give back and change the world through various projects. We actively
          participate in charitable endeavors, environmental sustainability
          initiatives, and supporting causes that share our beliefs.
        </p>
        <p>
          Although Hexamilesoft is proud of its accomplishments, we never take
          our success for granted. We constantly challenge ourselves to advance,
          invent, and provide quality. Our commitment to our stakeholders—our
          customers, employees, and employees—remains unshakable as we go
          forward. <br />
          We appreciate you selecting Hexamilesoft. We can't wait to start this
          adventure with you and achieve success together.
        </p>{" "}
        <br />
        <p>
          Sincerely,
          <br />
          <strong className="ceo">Rubina Raheem,</strong>
          <br />
          President and CEO of Hexamilesoft
        </p>
      </div>
    </div>
  );
}

export default About;
