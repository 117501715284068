import React from "react";
import CoreDiv from "./CoreDiv";

import LOGOIMG from "../../../assets/HexamileSoft.png";
import WorkHour from "./WorkHour";
import Culture from "./Culture";
import JoinUsIcons from "../../career_pages/career/JoinUsIcons";

// icons
import I1 from "../../../assets/career/i1.png";
import I2 from "../../../assets/career/i2.png";
import I3 from "../../../assets/career/i3.png";
import I4 from "../../../assets/career/i4.png";
import I5 from "../../../assets/career/i5.png";
import I6 from "../../../assets/career/i6.png";
import I8 from "../../../assets/career/i8.png";
import IFriends from "../../../assets/career/friends.png";
import IBonus from "../../../assets/career/bonus.png";
import IPrespective from "../../../assets/career/prespective.png";
import IProfit from "../../../assets/career/profit.png";
import IPerformance from "../../../assets/career/performance.png";
import IPayment from "../../../assets/career/payment.png";
import ILoan from "../../../assets/career/loan.png";
import IDevelopment from "../../../assets/career/development.png";
import IBirthday from "../../../assets/career/birthday.png";
import IVacation from "../../../assets/career/vacation.png";
import ITrip from "../../../assets/career/trip.png";
import IWeekend from "../../../assets/career/weekend.png";

import { useEffect } from "react";

function HandBook() {
  const humble =
    "Stats and customer feedback supersede pride and prejudice. Please keep an open mind to fresh concepts, acknowledge limitations, and let the outcomes speak for themselves. When an error occurs, do not shy away from taking responsibility. Instead, approach the situation with humility, seek assistance, and take swift action to resolve the issue. Timely action is the key to resolving the problem and using it as an opportunity to learn and improve.";
  const happy =
    "Work should be as joyful as possible because happy people are productive people. Making difficult choices about which partners, clients, and suppliers to deal with while prioritizing common sense over rules are necessary to keep things that way. Enjoy what you do while using common sense over rules regarding daily conduct and interactions.";
  const gritty =
    "Having an idea is just the beginning. It is persistence in pursuing it that leads to success. Through unwavering dedication and persistence in reaching long-term goals, true success is achieved. In our increasingly competitive business landscape, the gritty and entrepreneurial mindset that sets those apart who strive for self-motivation, self-awareness, self-discipline, and self-improvement leads to great results.";
  const agile =
    'We firmly believe in the power of "little bets" at our organization. The philosophy is about taking a low-risk action to discover, develop, and test an idea. By embracing this philosophy, you can rapidly learn from your mistakes and make necessary adjustments. With this approach, a small and dedicated team can accomplish far more than a larger organization.';

  // work hour
  const workHour1 =
    "Our official work hours span around 9:00 AM - 9:00 PM. Our organization, however, does not emphasize the number of hours worked but rather the outcomes achieved. We do not have strict rules about work hours, as we believe that rules around work hours are disrespectful to our team members and show a lack of trust in their ability to get the job done.";

  const workHour2 =
    "In return, we ask for mutual respect, particularly when deadlines require longer work hours. Our approach is based on the principles of respect and common sense.";

  const vocation1 =
    "HexamileSoft is a company that values results and productivity over traditional work hours and vacation policies. Employees are encouraged to take breaks and time off as needed, but no formal tracking or policy is in place. The emphasis is on ensuring that employees can return to work recharged with new ideas.";

  const vocation2 =
    'Our "five days a week" work policy helps provide those much-needed breaks to our employees. However, if an employee needs to leave during weekdays, they must submit an official leave application containing the leave duration (exact date), reason, etc.';

  const dressCode1 =
    "Employees are the face of the company and thus advised to maintain a professional appearance with attires appropriate for their duties. They can opt for smart casual, business casual, or business attire unless the day's tasks require otherwise.";

  const dressCode2 = "Attention to grooming and neatness is mandatory.";

  // company
 const bday =
    "Our staff is our family at the office, and we know how to value our family. So, we throw birthday celebrations for our employees to cherish these family members. A cake, a delightful meal, and some laughter; what better way to celebrate a birthday?";

  const hiTea =
    "After every year, we organize a stress-buster hi-tea/dinner for our office family. The event is meant to have a chit-chat and make some memories for us to look back on someday.";

  const aTrip =
    "Although we enjoy long weekends, office breaks, birthday celebrations, and biannually hi-teas, the spirit to live life to its fullest motivates us to go on annual trips. A few days away from the sometimes stressful work, surrounded by mountains or in a different culture, help rekindle our souls as wanderers and learners.";

  useEffect(() => {
    document.title = "Handbook - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "HexaMileSoft's employee handbook defines our core values, reasons you should join us, our culture, policies, and benefits for employees.";
    document.getElementsByTagName("META")[6].content =
      "Handbook - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "HexaMileSoft's employee handbook defines our core values, reasons you should join us, our culture, policies, and benefits for employees.";
    document.getElementsByTagName("META")[12].content =
      "Handbook - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "HexaMileSoft's employee handbook defines our core values, reasons you should join us, our culture, policies, and benefits for employees.";
  }, []);

  return (
    <div className="handbook-container">
      <div className="top-logo-container">
        <div className="img">
          <img src={LOGOIMG} alt="" />
        </div>
        <h1>Employee Handbook</h1>
      </div>

      <div className="core-container">
        <div className="upper-wrapper">
          <h2>Our Core Values</h2>
          <p>Our Priority List</p>
        </div>
        <CoreDiv
          heading="Be Humble"
          mini="Listen to the data."
          parag={humble}
        />
        <CoreDiv
          heading="Enjoy What You Do"
          mini="Work hard, have fun, and make history. – Jeff Bezos."
          parag={happy}
        />
        <CoreDiv
          heading="Be Persistent"
          mini="Be persistent, not complacent."
          parag={gritty}
        />
        <CoreDiv
          heading="Employ Agility"
          mini=" Make small bets, then iterate"
          parag={agile}
        />
      </div>

      <div className="policies-container">
        <div className="inner-wrapper">
          <h2>Policies</h2>
          <p>
            A set of instructions cannot administrate fully functional humans.
            However, over time we have realized that some fundamental policies
            are the way to transparent operations. So, in case you need
            clarification, here are our non-policy rules.
          </p>
        </div>
      </div>

      <div className="absolutely-not-container">
        <div className="inner-wrapper">
          <h2>Things that are NOT OKAY</h2>
          <div className="list-items">
            <ol>
              <li>Harassment of any kind</li>
              <li>Dishonest behavior; avoiding work</li>
              <li>Gossip about coworkers and boss</li>
              <li>Not respecting other people's time</li>
              <li>Taking credit for someone else's work</li>
            </ol>
          </div>
          <p>
            We believe this goes without saying– but just in case– we said them
            anyway.
          </p>
        </div>
      </div>

      <div className="work-hours-container">
        <WorkHour title={"Work Hours"} parag1={workHour1} parag2={workHour2} />
        <WorkHour
          title={"Vacation Policy and Requesting Time Off"}
          parag1={vocation1}
          parag2={vocation2}
        />
        <WorkHour
          title={"Dress Code"}
          parag1={dressCode1}
          parag2={dressCode2}
        />
      </div>

      <div className="benefits-container">
        <h2>Benefits</h2>
        <div className="items">
          <JoinUsIcons icon={I8} title={"Best Performance Reward"} />
          <JoinUsIcons icon={IFriends} title={"Friendly Team"} />
          <JoinUsIcons icon={I3} title={"Self-Education"} />
          <JoinUsIcons icon={IBonus} title={"Eid Bonus"} />
          <JoinUsIcons icon={I5} title={"Individual Approach"} />
       
          <JoinUsIcons
            icon={IPerformance}
            title={"Performance-Based Increments"}
          />
          <JoinUsIcons icon={IProfit} title={"Yearly Increments"} />
      
          <JoinUsIcons
            icon={IDevelopment}
            title={"Learning & Development Time"}
          />
          <JoinUsIcons icon={IBirthday} title={"Birthday Celebration"} />
          
          <JoinUsIcons
            icon={IWeekend}
            title={"Five days a week (Respecting work/life balance)"}
          />
          
        </div>
      </div>

      <div className="intrapreneur-container">
        <div className="inner-wrapper">
          <div className="upper-wrapper">
            <h2>Intrapreneur Program</h2>
            <p>
              <em>
                Our Intrapreneur Program may be just the right fit for those
                budding entrepreneurs.
              </em>
            </p>
            <p>
              We know firsthand that the first time you bet on yourself and take
              the leap into entrepreneurship is the scariest. It gets a little
              easier each time, but it always takes work. It got us wondering if
              people had a safe place (or “safer”) to start a business with
              guidance and help; how many more people would start amazing
              businesses? How many of our team members have great ideas tossed
              around in their brains that they haven't been able to take the
              first step on? So the Intrapreneur Program was born.
            </p>
          </div>

          <h5>A few things we aren't trying to do:</h5>
          <div className="list-items">
            <ul>
              <li>Turn all of our employees into entrepreneurs</li>
              <li>
                Make people feel they have to participate in this program to
                “make it” at HexamileSoft
              </li>
              <li>
                Create a whole bunch of useless extra work for a lot of people
              </li>
              <li>
                Give everyone participation awards by moving forward with any
                idea presented, so everyone feels good about themselves
              </li>
            </ul>
          </div>
          <p>
            This program excites us because we hope it allows great ideas to
            take advantage of our internal resources. We also hope that
            HexamileSoft continues to attract the best talent and motivates
            every team member to continue growing.
          </p>
        </div>
      </div>
     <div className="company-container">
        <h2>Company Culture</h2>
      
        <WorkHour title={"Birthday Celebrations"} parag1={bday} parag2={""} />
        <WorkHour title={"Company Hi-Tea/Dinner"} parag1={hiTea} parag2={""} />
        
      </div>

      <div className="culture-container">
        <Culture />
      </div>
    </div>
  );
}

export default HandBook;
