import React, { useContext } from "react";
import { Link } from "react-router-dom";

import Hexamile from "../../assets/HexamileSoft.png";
import { GlobalData } from "../../../App";

function LogoMenu() {
  const {
    setColor1,
    setColor2,
    setColor3,
    setColor4,
    setColor5,
    setColorCareer,
    setColorProcess,
    hideDropDown,
  } = useContext(GlobalData);

  return (
    <>
      <div
        className="logo"
        onClick={() => {
          setColor1(true);
          setColor2(false);
          setColor3(false);
          setColor4(false);
          setColor5(false);
          setColorCareer(false);
          setColorProcess(false);
          hideDropDown();
        }}
      >
        <Link to={"/"}>
          <img src={Hexamile} alt="hexamile" />
          <p>(Solution-Simple-Fast-Secure)</p>
        </Link>
      </div>
    </>
  );
}

export default LogoMenu;
