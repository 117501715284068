import React from "react";
import { useState } from "react";

import "../allClickProjects.scss";

import { GiAbstract005 } from "react-icons/gi";

import { BiCaretRight } from "react-icons/bi";
import { SimpleButon, SimpleButonId } from "../../../../buttons/MyButon";

function WordPressClick({ btnPath }) {
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);

  const activeHandler = (a1 = true, a2 = false, a3 = false, a4 = false) => {
    setActive1(a1);
    setActive2(a2);
    setActive3(a3);
    setActive4(a4);
  };

  return (
    <div className="inner-project-wrapper">
      <div className="heading">
        <h2>HexamileSoft- Leaders in Innovative WordPress Solutions</h2>
        <p>
          Creating Exceptional Website Experiences through Stunning Design,
          Advanced Technologies, and Affordable Pricing.
        </p>
        <hr />
      </div>

      <div className="select-projects-container">
        <div className="left-side">
          {/* WordPress Development Services  */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler();
            }}
            style={{ backgroundColor: active1 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>WordPress Development Services</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Joomla Development Services */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, true, false, false);
            }}
            style={{ backgroundColor: active2 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Joomla Development Services</span>
            </div>
            <BiCaretRight />
          </div>

          {/* Drupal Development Services */}
          <div
            className="touch-it"
            onClick={() => {
              activeHandler(false, false, true, false);
            }}
            style={{ backgroundColor: active3 && "#e8e8e9" }}
          >
            <div className="clicked">
              <GiAbstract005 />
              <span>Drupal Development Services</span>
            </div>
            <BiCaretRight />
          </div>
        </div>

        {/* content  */}
        <div className="right-side">
          {/* WordPress Development Services  */}
          {active1 && (
            <div className="mydiv">
              <h3>Scalable and Interactive WordPress Development Solutions</h3>
              <p>
                Our team of WordPress developers offers a wide range of
                solutions tailored to different business types and market
                segments. From creating custom WordPress CMS to developing
                plugins, modifying themes, and providing maintenance services,
                we utilize cutting-edge technology and tools to deliver
                exceptional results.
                <br />
                At HexamileSoft, innovation is a top priority. We strive to
                deliver quality and sustainable products that attract many
                potential buyers. While adhering to industry standards and
                specifications is important, we also push boundaries and
                consistently develop something new and innovative.
              </p>

              <ul>
                <li>WordPress Website Development</li>
                <li>WordPress Theme Development</li>
                <li>WordPress PLUGIN Development</li>
                <li>PSD 2 WordPress Development</li>
                <li>WordPress Responsive Web Development</li>
                <li>WordPress Maintenance </li>
              </ul>

              <div className="project-btn">
                <SimpleButonId btn={"Hire"} path={btnPath} />
              </div>
            </div>
          )}

          {/* Joomla Development Services */}
          {active2 && (
            <div className="mydiv">
              <h3>
                Expertise in delivering robust, scalable, and interactive Joomla
                Development solutions that sets us apart as market leaders.
              </h3>
              <p>
                Our Joomla development services help bring your business online.
                Our team of highly skilled developers specializes in providing
                top-notch Joomla development services such as custom Joomla CMS
                development, component development, theme customization, and
                maintenance. You can connect with your global customer base more
                efficiently and effectively using state-of-the-art CMS tools.
              </p>
              <p>
                HexamileSoft is a well-established leader in offshore Joomla
                development. We help you enhance your online business by
                covering all market aspects. Our team of expert Joomla
                developers understands your requirements and delivers scalable,
                customized Joomla solutions tailored to meet your business's
                specific needs at the molecular level.
              </p>

              <ul>
                <li>Joomla CMS Development</li>
                <li>Joomla Theme Development</li>
                <li>Joomla Modules Development</li>
                <li>PSD 2 JOOMLA Development</li>
                <li>Joomla Responsive Web Development</li>
                <li>Joomla Maintenance Services </li>
              </ul>

              <div className="project-btn">
                <SimpleButonId btn={"Hire"} path={btnPath} />
              </div>
            </div>
          )}

          {/* Drupal Development Services */}
          {active3 && (
            <div className="mydiv">
              <h3>Interactive, Robust, and Scalable Solutions</h3>
              <p>
                HexamileSoft has over eight years of experience in creating
                highly robust, feature-rich, and modular Drupal solutions that
                provide measurable technology benefits. By thoroughly evaluating
                your requirements and deeply engaging with Drupal's core, we
                offer custom Drupal CMS development, extension development,
                theme customization, and maintenance services that cater to
                various industries.
                <br />
                We create bespoke Drupal portals and web applications that
                reflect the unique nature of your business idea. By integrating
                new tools and techniques into your framework, we create products
                that make it more conducive and rewarding for your business. We
                blend innovation and practicality to create unmatched business
                value. Our dynamic and versatile Drupal outsourcing services
                ensure breakthrough results and help you manage your web
                development costs and associated risks. Disruptive changes in
                the market will not negatively impact your performance metrics,
                as you will be well-equipped to deal with them effectively.
              </p>
              <p>
                As an agile Drupal development company, HexamileSoft has a team
                of highly skilled developers who work holistically and
                dedicatedly. We do not accept mediocrity and have a
                zero-tolerance policy for anything less than exceptional
                performance. This mindset is reflected in our workflow, and we
                offer highly flexible services customized to meet your
                business's specific needs.
              </p>
              <ul>
                <li>Drupal CMS Development</li>
                <li>Drupal Theme Development</li>
                <li>Drupal Modules Development</li>
                <li>PSD 2 Development</li>
                <li>Drupal Responsive Web Development</li>
                <li>Drupal Maintenance Services</li>
              </ul>

              <div className="project-btn">
                <SimpleButonId btn={"Hire"} path={btnPath} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WordPressClick;
