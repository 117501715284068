import React from "react";

import PY1 from "../../../../assets/work/mobile apps/olx app/online shopping.jpg";

import { RxCross1 } from "react-icons/rx";
import { useContext } from "react";
import { WorkMenuData } from "../../OurWork";

function ShoppingAppA() {
  const { setActive12 } = useContext(WorkMenuData);

  return (
    <div className="project-popup">
      <div
        className="blur-section"
        onClick={() => {
          setActive12(false);
        }}
      ></div>
      <div className="pop">
        <div className="my-content">
          <div className="inner-wrapper">
            <div
              className="cross"
              onClick={() => {
                setActive12(false);
              }}
            >
              <RxCross1 />
            </div>
          </div>
          <div className="top-pop">
            <h2>Online Shopping App</h2>
          </div>

          <div className="type-wrapper">
            <p>Services Provided on this Project</p>
            <span>Mobile App</span>
          </div>

          <div className="inner-boxes">
            <div className="box">
              <span>Client</span>
              <p>Joe William</p>
            </div>
            <div className="box box2">
              <span>Project Schedule</span>
              <p>Completed</p>
            </div>
            <div className="box box3">
              <span>Project Size</span>
              <p>NA</p>
            </div>
          </div>

          <div className="project-content">
            <p>
              <strong>
                A shopping app is a mobile application that allows users to
                browse, search for, and purchase products or services
                conveniently from their smartphones or tablets. These apps have
                gained immense popularity in recent years due to the increasing
                use of mobile devices and the convenience they offer shoppers.
              </strong>
            </p>
            <p>
              A shopping app provides a user-friendly interface where customers
              can explore various products from various categories, such as
              electronics, clothing, home goods, beauty products, groceries, and
              more. Users can view detailed product descriptions, images,
              prices, customer reviews, and ratings to make informed purchasing
              decisions.
            </p>
            <p>
              Shopping apps have revolutionized the retail industry by providing
              users a convenient and streamlined shopping experience anytime and
              anywhere. They have made it easier for businesses to reach a wider
              customer base and for consumers to access a vast array of products
              with just a few taps on their mobile devices.
            </p>
            <div className="img">
              <img src={PY1} alt="Web" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShoppingAppA;
