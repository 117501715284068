import React from "react";
import ServiceContact from "../../contact/ServiceContact";
import EngagementModel from "../../engagement_models/EngagementModel";
import InnerHeading from "../../headings/InnerHeading";
import HireEngineers from "../../hire_engineers/HireEngineers";
import StartProject from "../../start_project/StartProject";
import ServiceHead from "../../top_head/ServiceHead";
import TrustPilot from "../../trust/TrustPilot";
import TrustedBy from "../../trusted_by_imgs/TrustedBy";
import UpperContent from "../../upper_content/UpperContent";
import GameDevClick from "./GameDevClick";

// images
import GameD from "../../../../assets/service/services/Game Development.jpg";
import { useEffect } from "react";

function GameDev() {
  const paragH1 =
    "The user experience extends beyond what is seen through the glass. We create extraordinary experiences that address issues faced by real people in the real world, and what we do about digital technology is merely the tip of the iceberg.";

  const paragCon1 =
    "HexamileSoft assists businesses by transforming their ideas into gorgeous digital products. We create new goods or enhance old ones through research, strategy, and design. We design simple and enjoyable digital experiences by drawing on our understanding of human behavior.";

  const startP =
    "At HexamileSoft, we're all about startups! It's in our DNA. We love new ideas and bringing them to life, and you'll see that in our hands-on approach to your project. From concept to launch, we're your go-to partner for all things startup. Let's make your ideas a reality! ";

  useEffect(() => {
    document.title = "Game Development - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "We effectively move the games from the development stage to the completion stage thanks to our artistic and technological abilities, which include creating clickable prototypes, information layouts, storyboarding, and designs.";
    document.getElementsByTagName("META")[6].content =
      "Game Development - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "We effectively move the games from the development stage to the completion stage thanks to our artistic and technological abilities, which include creating clickable prototypes, information layouts, storyboarding, and designs.";
    document.getElementsByTagName("META")[12].content =
      "Game Development - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "We effectively move the games from the development stage to the completion stage thanks to our artistic and technological abilities, which include creating clickable prototypes, information layouts, storyboarding, and designs.";
  }, []);

  return (
    <div className="services-container">
      <div className="heading-container">
        <ServiceHead
          title={"WEB, MOBILE, AND TABLET GAMES"}
          parag={"Game Development"}
        />
      </div>

      {/* InnerHeading */}
      <div className="title-heading-container">
        <div className="heading-wrapper">
          <InnerHeading
            titleH={
              "Advanced Revenues, Higher Conversion Rates, and Customer Loyalty"
            }
            paragH={paragH1}
          />
        </div>
        <hr />
        <div className="top-inner-container">
          <UpperContent
            imgCon={GameD}
            paragCon1={paragCon1}
            paragCon2={""}
            paragCon3={""}
            btnPath={"#gd"}
          />
        </div>
      </div>

      {/* trusted by */}
      <div className="trusted-by-container">
        <TrustedBy />
      </div>

      {/* click projects  */}
      <div className="click-projects-container">
        <GameDevClick />
      </div>

      {/* hire engineer  */}
      <div className="hire-engineer-container">
        <HireEngineers
          engrName={"Hire Game Developer"}
          engrPrice={"2200"}
          btnPath={"#gd"}
        />
        <EngagementModel />
      </div>

      {/* start your project  */}
      <div className="start-your-project-container">
        <StartProject
          titleProj={"Interested in Creating Something Awesome With Us?"}
          startP={startP}
          btnPath={"#gd"}
        />
      </div>

      {/* TrustPilot */}
      <div className="trust-pilot-wrap">
        <TrustPilot />
      </div>

      {/* ServiceContact */}
      <div className="service-contact-wrapper" id="gd">
        <ServiceContact />
      </div>
    </div>
  );
}

export default GameDev;
