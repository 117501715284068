import React, { useState } from "react";
import { LearnMore, SimpleButon } from "../../buttons/MyButon";

function MyServices({ heading, logo, path, text, lLogo }) {
  const [active, setActive] = useState(false);

  const clicked = () => {
    setActive(true);
  };
  const unClicked = () => {
    setActive(false);
  };

  return (
    <div
      className="my-services-container"
      onMouseEnter={clicked}
      onMouseLeave={unClicked}
    >
      <div className="wrapper">
        <div className="heading-block">
          <img src={logo} alt={heading} />
          <h2>{heading}</h2>
        </div>
        <p>{text}</p>
      </div>

      <div
        className="btn"
        style={{
          tranistion: "all 0.2s ease-in-out",
          visibility: active ? "visible" : "hidden",
        }}
      >
        <SimpleButon path={path} btn="Learn More" />
      </div>

      <div className="light-icon">
        <img src={lLogo} alt={heading} />
      </div>

      <div className="mobile-btn">
        <LearnMore path={path} />
      </div>
    </div>
  );
}

export default MyServices;
