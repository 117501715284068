import React from "react";
import { SimpleButon } from "../../../buttons/MyButon";
import JoinUsIcons from "./JoinUsIcons";

// css
import "./careerSpare.css";

// icons
import I3 from "../../../assets/career/i3.png";
import I5 from "../../../assets/career/i5.png";
import I8 from "../../../assets/career/i8.png";
import IFriends from "../../../assets/career/friends.png";
import IBonus from "../../../assets/career/bonus.png";
import IPrespective from "../../../assets/career/prespective.png";
import IProfit from "../../../assets/career/profit.png";
import IPerformance from "../../../assets/career/performance.png";
import IPayment from "../../../assets/career/payment.png";
import ILoan from "../../../assets/career/loan.png";
import IDevelopment from "../../../assets/career/development.png";
import IBirthday from "../../../assets/career/birthday.png";
import IVacation from "../../../assets/career/vacation.png";
import ITrip from "../../../assets/career/trip.png";
import IWeekend from "../../../assets/career/weekend.png";

import Humble from "../../../assets/career/Humble.jpg";
import Agile from "../../../assets/career/Desktop.jpg";
import Persistence from "../../../assets/career/Persistence.jpg";
import ProductiveWorkplace from "../../../assets/career/Productive Workplace.jpg";

// carosel
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// carosel images
import C1 from "../../../assets/career/our/pic 1.jpg";
import C2 from "../../../assets/career/our/pic 2.jpg";
import C3 from "../../../assets/career/our/pic 3.jpg";
import C4 from "../../../assets/career/our/pic 4.jpg";
import C5 from "../../../assets/career/our/pic 5.jpg";
import C6 from "../../../assets/career/our/pic 6.jpg";
import C7 from "../../../assets/career/our/pic 7.jpg";
import { useEffect } from "react";
import { useContext } from "react";
import { GlobalData } from "../../../../App";

function Career() {
  const { setColorCareer } = useContext(GlobalData);

  useEffect(() => {
    document.title = "Core Values - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "We strongly believe in modesty, productivity, perseverance, and agility. The values are instilled in our new employees via training and rules. ";
    document.getElementsByTagName("META")[6].content =
      "Core Values - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "We strongly believe in modesty, productivity, perseverance, and agility. The values are instilled in our new employees via training and rules. ";
    document.getElementsByTagName("META")[12].content =
      "Core Values - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "We strongly believe in modesty, productivity, perseverance, and agility. The values are instilled in our new employees via training and rules. ";
  }, []);
  return (
    <div className="career-container">
      <div className="upper-container">
        <div className="title">
          <h1>Our Core Values</h1>
          <p>What defines us?</p>
        </div>

        <div className="inner-container">
          <div className="wrapper">
            <div className="img">
              <img src={Humble} alt="Modesty" />
            </div>
            <div className="content">
              <h2>Modesty</h2>
              <p>
                Stats and customer feedback supersede pride and prejudice. We
                put significant efforts into keeping an open mind to fresh
                concepts, accepting our limitations, and letting the outcomes
                speak for themselves.
              </p>
            </div>
          </div>
          <div className="wrapper">
            <div className="img">
              <img src={ProductiveWorkplace} alt="Productive Workplace" />
            </div>
            <div className="content">
              <h2>Productive Workplace</h2>
              <p>
                Work should be as joyful as possible because happy people are
                productive people. Making difficult choices about which
                partners, clients, and suppliers to deal with while prioritizing
                common sense over rules are necessary to keep things that way.
              </p>
            </div>
          </div>
          <div className="wrapper">
            <div className="img">
              <img src={Persistence} alt="Persistence" />
            </div>
            <div className="content">
              <h2>Persistence</h2>
              <p>
                Success depends on persistence rather than just having a good
                idea. We adopt an entrepreneurial mentality emphasizing
                self-motivation, self-awareness, self-discipline, and
                self-improvement.
              </p>
            </div>
          </div>
          <div className="wrapper">
            <div className="img">
              <img src={Agile} alt="agility" />
            </div>
            <div className="content">
              <h2>Agility</h2>
              <p>
                We firmly believe in the power of "little bets" at our
                organization. By embracing this philosophy, we can rapidly learn
                from our mistakes and make necessary adjustments. With this
                approach, a small and dedicated team can accomplish far more
                than a larger organization.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="middle-container">
        <h2>Reasons You Should Join Us</h2>
        <p>
          We strongly believe that "success is best when it's shared."
          <br />
          Here's what we offer to help you achieve it with us.
        </p>

        <div className="items">
          <JoinUsIcons icon={I8} title={"Best Performance Reward"} />
          <JoinUsIcons icon={IFriends} title={"Friendly Team"} />
          <JoinUsIcons icon={I3} title={"Self-Education"} />
          <JoinUsIcons icon={IBonus} title={"Eid Bonus"} />
          <JoinUsIcons icon={I5} title={"Individual Approach"} />
    
          <JoinUsIcons
            icon={IPerformance}
            title={"Performance-Based Increments"}
          />
          <JoinUsIcons icon={IProfit} title={"Yearly Increments"} />
         
          <JoinUsIcons
            icon={IDevelopment}
            title={"Learning & Development Time"}
          />
          <JoinUsIcons icon={IBirthday} title={"Birthday Celebration"} />
         
          <JoinUsIcons
            icon={IWeekend}
            title={"Five days a week (Respecting work/life balance)"}
          />
         
        </div>
      </div>

      <div className="lower-container">
        <div className="left-container">
          <h2>Our Culture</h2>
          <p>
            At HexamileSoft, we are dedicated to fostering a culture of
            professional development. We constantly search for driven
            individuals eager to advance their skills and careers.
            <br />
            <span>
              Our Team comprises gifted and talented individuals who work
              together to achieve excellence. We foster a supportive and
              respectful environment where everyone can thrive. But it is not
              all work and no play, and our Team knows how to have fun and enjoy
              their time together.
            </span>
          </p>

          <div
            className="btn"
            onClick={() => {
              setColorCareer(false);
            }}
          >
            <SimpleButon
              btn={"Check our Employee Handbook"}
              path={"/handbook"}
            />
          </div>
        </div>
        <div className="right-container">
          <div className="slide-carosel" style={{ color: "black" }}>
            <Carousel
              showThumbs={false}
              infiniteLoop
              autoPlay={true}
              showStatus={false}
              renderIndicator={false}
              showArrows={false}
            >
              <div className="img-wrapper">
                <img src={C1} alt="hexamile" />
              </div>
              <div className="img-wrapper">
                <img src={C2} alt="hexamile" />
              </div>
              <div className="img-wrapper">
                <img src={C3} alt="hexamile" />
              </div>
              <div className="img-wrapper">
                <img src={C4} alt="hexamile" />
              </div>
              <div className="img-wrapper">
                <img src={C5} alt="hexamile" />
              </div>
              <div className="img-wrapper">
                <img src={C6} alt="hexamile" />
              </div>
              <div className="img-wrapper">
                <img src={C7} alt="hexamile" />
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Career;
