import React from "react";
import { SimpleButon, SimpleButonId } from "../../../../buttons/MyButon";

import "./dedicatedPrice.scss";

function DedicatedPrice({ title, parag1, priceTag, classN, btnPath }) {
  return (
    <div className="outer-price-block">
      <div className={`price-block ${classN}`}>
        <div className="price-title">
          <h3>{title}</h3>
          <p>{parag1}</p>
        </div>
        <div className="price-p">
          <sup>$</sup>
          <strong>{priceTag}</strong>
          <sub>/mo</sub>
        </div>
        <div className="price-btn">
          <SimpleButonId btn={"Let's Talk"} path={btnPath} />
        </div>
      </div>
    </div>
  );
}

export default DedicatedPrice;
