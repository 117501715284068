import React from "react";
import ServiceContact from "../../contact/ServiceContact";
import EngagementModel from "../../engagement_models/EngagementModel";
import InnerHeading from "../../headings/InnerHeading";
import HireEngineers from "../../hire_engineers/HireEngineers";
import StartProject from "../../start_project/StartProject";
import ServiceHead from "../../top_head/ServiceHead";
import TrustPilot from "../../trust/TrustPilot";
import TrustedBy from "../../trusted_by_imgs/TrustedBy";
import UpperContent from "../../upper_content/UpperContent";
import SaaSClick from "./SaaSClick";

// images
import WebD from "../../../../assets/service/services/saas-Web Development.jpg";
import { useEffect } from "react";

function SaasWebDev() {
  const paragH1 =
    "We assist Fortune 500 companies, startups, and corporate innovators in building their MVPs, finding product-market fit, and scaling quickly. Our agile and lean development methods can turn your idea into a functional, visually appealing SaaS platform or online application in as little as 90 days, providing a robust, adaptable MVP that can attract customers and maintain a competitive edge.";

  const paragCon1 =
    "Our designers, developers, and strategists are proficient in executing complex and ambitious projects, ensuring high solution maintainability, performance, scalability, and system integration. Our approach to web app development is versatile, and we find the right technology for your product rather than limiting you to a specific framework. Our cross-functional teams work closely with you to create an engaging product that satisfies customers and drives business growth. We continue to enhance the platform with new features based on user feedback and insights even after launch. Our proficiency in technology extends beyond the norm of a typical web development company.";

  const startP =
    "At HexamileSoft, we're all about startups! It's in our DNA. We love new ideas and bringing them to life, and you'll see that in our hands-on approach to your project. From concept to launch, we're your go-to partner for all things startup. Let's make your ideas a reality! ";

  useEffect(() => {
    document.title = "Web Development - Hexamilesoft";
    document.getElementsByTagName("META")[3].content =
      "We use MVC frameworks with rich frontends to develop SaaS and cloud-based apps effectively and rapidly.";
    document.getElementsByTagName("META")[6].content =
      "Web Development - Hexamilesoft";
    document.getElementsByTagName("META")[7].content =
      "We use MVC frameworks with rich frontends to develop SaaS and cloud-based apps effectively and rapidly.";
    document.getElementsByTagName("META")[12].content =
      "Web Development - Hexamilesoft";
    document.getElementsByTagName("META")[13].content =
      "We use MVC frameworks with rich frontends to develop SaaS and cloud-based apps effectively and rapidly.";
  }, []);

  return (
    <div className="services-container">
      <div className="heading-container">
        <ServiceHead title={"SKY is NOT the LIMIT!"} parag={"Dream BIG!"} />
      </div>

      {/* InnerHeading */}
      <div className="title-heading-container">
        <div className="heading-wrapper">
          <InnerHeading
            titleH={"WE DESIGN RESULTS-DRIVING SAAS & WEB APPS"}
            paragH={paragH1}
          />
        </div>
        <hr />
        <div className="top-inner-container">
          <UpperContent
            imgCon={WebD}
            paragCon1={paragCon1}
            paragCon2={""}
            paragCon3={""}
            btnPath={"#wd"}
          />
        </div>
      </div>

      {/* trusted by */}
      <div className="trusted-by-container">
        <TrustedBy />
      </div>

      {/* click projects  */}
      <div className="click-projects-container">
        <SaaSClick btnPath={"#wd"} />
      </div>

      {/* hire engineer  */}
      <div className="hire-engineer-container">
        <HireEngineers
          engrName={"Hire Web Developer"}
          engrPrice={"2200"}
          btnPath={"#wd"}
        />
        <EngagementModel />
      </div>

      {/* start your project  */}
      <div className="start-your-project-container">
        <StartProject
          titleProj={"Interested in Creating Something Awesome With Us?"}
          startP={startP}
          btnPath={"#wd"}
        />
      </div>

      {/* TrustPilot */}
      <div className="trust-pilot-wrap">
        <TrustPilot />
      </div>

      {/* ServiceContact */}
      <div className="service-contact-wrapper" id="wd">
        <ServiceContact />
      </div>
    </div>
  );
}

export default SaasWebDev;
